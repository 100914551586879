import { cart } from 'src/rest'
import { cartActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { prepareErrors } from 'src/services'
import { userSelectors } from 'src/selectors'

import updateCartLocal from './updateCartLocal'
import loadUserCart from './loadUserCart'

const updatePartsBySN = (data, localParam) => async (dispatch, getState) => {
  const isLogged = userSelectors.getIsLogged(getState())
  const { existingSerialNumber } = data

  if (isLogged) {
    const { value, error } = await to(cart.updatePartsBySN(data))

    if (value) {
      const { isSuccess } = value.data

      if (isSuccess) {
        dispatch(cartActions.updatePartsSuccess())
        dispatch(loadUserCart(true))
      } else {
        const errorParams = prepareErrors({ data: value.data })

        dispatch(notifyThunks.serverError(errorParams))
        dispatch(cartActions.updatePartsFailure())
      }
    }

    if (error) {
      const errorParams = prepareErrors({ error })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(cartActions.updatePartsFailure())
    }
  } else {
    dispatch(updateCartLocal({ type: 'editSN', param: { ...localParam, existingSerialNumber } }))
  }
}

export default updatePartsBySN
