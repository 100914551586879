import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const CounterRaw = (props) => {
  const { children } = props
  const { t } = useTranslation()
  return (
    <div className={styles.counter}>
      <span className={styles.label}>{t('machine:info.amount')}</span>
      <div className={styles.amount}>{children}</div>
    </div>
  )
}

export const Counter = CounterRaw
export default memo(Counter)
