const getState = (state) => state.settings

export const getLanguageSettings = (state) => getState(state).LANGUAGE
export const getBaseUrlSettings = (state) => getState(state).BASE_URL

export const getDefaultLanguage = (state) => getLanguageSettings(state).default
export const getAdminLanguages = (state) => getLanguageSettings(state).admin
export const getUserLanguages = (state) => getLanguageSettings(state).user

export const getBaseUrlProdConfig = (state) => getState(state).BASE_URL['prod']
export const getBaseUrlDevConfig = (state) => getState(state).BASE_URL['dev']
export const getBaseUrlConfig = (state) => {
  if (process.env.NODE_ENV === 'production') {
    const settings = getBaseUrlProdConfig(state)

    return {
      port: settings.port,
    }
  }

  const settings = getBaseUrlDevConfig(state)

  return {
    protocol: settings.protocol,
    host: settings.host,
    port: settings.port,
  }
}
