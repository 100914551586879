import { user } from 'src/rest'
import { to } from 'src/lib'
import { userActions } from 'src/actions'

const updateUserLang = (language) => async (dispatch) => {
  const { value, error } = await to(user.updateUserLang({ language }))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(userActions.updateUserLangSuccess())
    } else {
      dispatch(userActions.updateUserLangFailure())
    }
  }

  if (error) {
    dispatch(userActions.updateUserLangFailure())
  }
}

export default updateUserLang
