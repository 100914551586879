import i18n from 'i18next'

export default (values) => {
  const errors = {}

  if (!/^\d+$/.test(values.x)) {
    errors.x = i18n.t('errors:fields.only_numbers')
  }

  if (!/^\d+$/.test(values.y)) {
    errors.y = i18n.t('errors:fields.only_numbers')
  }

  if (!/^\d+$/.test(values.internalNumber)) {
    errors.internalNumber = i18n.t('errors:fields.only_numbers')
  }

  return errors
}
