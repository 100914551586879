import { handleActions, combineActions } from 'redux-actions'

import { requestsActions } from 'src/actions'

export const initialState = false

const reducerMap = {
  [requestsActions.loadRequestStart]: () => true,
  [combineActions(requestsActions.loadRequestSuccess, requestsActions.loadRequestFailure)]: () => false,
}

export default handleActions(reducerMap, initialState)
