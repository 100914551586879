import i18n from 'i18next'
import { FORM_ERROR } from 'final-form'

import { auth } from 'src/rest'
import { to } from 'src/lib'
import { authActions } from 'src/actions'
import { errorsConstants } from 'src/constants'
import { notifyThunks } from 'src/thunks'

const createUser = (values) => async (dispatch) => {
  const nextValues = values
  nextValues.language = i18n.language
  const { value, error } = await to(auth.createUser(nextValues))

  if (value) {
    const { isSuccess, notificationStatus } = value.data

    if (isSuccess) {
      dispatch(authActions.createUserSuccess())
      dispatch(notifyThunks.createUserSuccess())
      if (notificationStatus === errorsConstants.EMAIL_WARNING) {
        dispatch(notifyThunks.emailWarning())
      }
    } else {
      dispatch(authActions.createUserFailure())
    }
  }

  if (error) {
    dispatch(authActions.createUserFailure())

    const { data } = (error && error.response) || {}

    if (data && data.errorCode === errorsConstants.DUPLICATE_EMAIL) {
      return { email: i18n.t('errors:fields.duplicate_email') }
    }

    dispatch(notifyThunks.somethingError())

    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default createUser
