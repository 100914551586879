import { admins } from 'src/rest'
import { to } from 'src/lib'
import { adminsActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'

const deleteAdmin = (adminId) => async (dispatch) => {
  const { value, error } = await to(admins.deleteAdmin(adminId))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(adminsActions.deleteAdminSuccess())
      dispatch(notifyThunks.adminDeleteSuccess())
    } else {
      dispatch(adminsActions.deleteAdminFailure())
    }
  }

  if (error) {
    dispatch(adminsActions.deleteAdminFailure())
  }
}

export default deleteAdmin
