import { createAction } from 'redux-actions'

const prefix = 'MANAGER:'

export const assignQuoteSuccess = createAction(prefix + 'ASSIGN_QUOTE_SUCCESS')
export const assignQuoteFailure = createAction(prefix + 'ASSIGN_QUOTE_FAILURE')

export const releaseRequestStart = createAction(prefix + 'RELEASE_REQUEST_START')
export const releaseRequestSuccess = createAction(prefix + 'RELEASE_REQUEST_SUCCESS')
export const releaseRequestFailure = createAction(prefix + 'RELEASE_REQUEST_FAILURE')

export const unreleaseRequestSuccess = createAction(prefix + 'UNRELEASE_REQUEST_SUCCESS')
export const unreleaseRequestFailure = createAction(prefix + 'UNRELEASE_REQUEST_FAILURE')

export const addPartSuccess = createAction(prefix + 'ADD_PART_SUCCESS')
export const addPartFailure = createAction(prefix + 'ADD_PART_FAILURE')

export const assignMachineSuccess = createAction(prefix + 'ASSIGN_MACHINE_SUCCESS')
export const assignMachineFailure = createAction(prefix + 'ASSIGN_MACHINE_FAILURE')

export const updatePartSuccess = createAction(prefix + 'UPDATE_PART_SUCCESS')
export const updatePartFailure = createAction(prefix + 'UPDATE_PART_FAILURE')

export const deletePartSuccess = createAction(prefix + 'DELETE_PART_SUCCESS')
export const deletePartFailure = createAction(prefix + 'DELETE_PART_FAILURE')

export const deleteAllPartsSuccess = createAction(prefix + 'DELETE_ALL_PART_SUCCESS')
export const deleteAllPartFailure = createAction(prefix + 'DELETE_ALL_PART_FAILURE')

export const deleteRequestSuccess = createAction(prefix + 'DELETE_REQUEST_SUCCESS')
export const deleteRequestFailure = createAction(prefix + 'DELETE_REQUEST_FAILURE')

export const deleteQuoteSuccess = createAction(prefix + 'DELETE_QUOTE_SUCCESS')
export const deleteQuoteFailure = createAction(prefix + 'DELETE_QUOTE_FAILURE')

export const getUserMachinesSuccess = createAction(prefix + 'GET_USER_MACHINE_SUCCESS')
export const getUserMachinesFailure = createAction(prefix + 'GET_USER_MACHINE_FAILURE')

export const addNewUserMachineSuccess = createAction(prefix + 'ADD_NEW_USER_MACHINE_SUCCESS')
export const addNewUserMachineFailure = createAction(prefix + 'ADD_NEW_USER_MACHINE_FAILURE')

export const editUserMachineSuccess = createAction(prefix + 'EDIT_USER_MACHINE_SUCCESS')
export const editUserMachineFailure = createAction(prefix + 'EDIT_USER_MACHINE_FAILURE')
