import { FORM_ERROR } from 'final-form'
import i18n from 'i18next'

import { components } from 'src/rest'
import { to } from 'src/lib'
import { componentsActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'

const updateComponent = (machineType, componentName, values) => async (dispatch) => {
  const { value, error } = await to(components.updateAdminComponent(machineType, componentName, values))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      dispatch(componentsActions.updateComponentSuccess())
      return data
    } else {
      dispatch(componentsActions.updateComponentFailure())
    }
  }

  if (error) {
    dispatch(componentsActions.updateComponentFailure())

    dispatch(notifyThunks.somethingError())
    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default updateComponent
