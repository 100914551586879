import qs from 'query-string'

import { instance } from '../axiosInstance'

export const loadRequests = (params) => {
  const query = qs.stringify(params, { skipNull: true, skipEmptyString: true })
  return instance({
    method: 'get',
    url: `/manager/requests${query && '?' + query}`,
  })
}

export const loadRequest = (reqId) => {
  return instance({
    method: 'get',
    url: `/manager/requests/${reqId}`,
  })
}

export const assignQuote = (reqId, data) => {
  return instance({
    method: 'put',
    url: `/manager/requests/${reqId}/quote`,
    timeout: 300000,
    data,
  })
}

export const deleteQuote = (reqId) => {
  return instance({
    method: 'delete',
    url: `/manager/requests/${reqId}/quote`,
  })
}

export const releaseRequest = (reqId) => {
  return instance({
    method: 'put',
    url: `/manager/requests/${reqId}/release`,
  })
}

export const unreleaseRequest = (reqId) => {
  return instance({
    method: 'put',
    url: `/manager/requests/${reqId}/unrelease`,
  })
}

export const addPart = (data) => {
  return instance({
    method: 'post',
    url: `/manager/requestItems`,
    data,
  })
}

export const assignMachinePart = (reqId, data) => {
  return instance({
    method: 'post',
    url: `/manager/requestItems/${reqId}`,
    data,
  })
}

export const updatePart = (reqId, data) => {
  return instance({
    method: 'put',
    url: `/manager/requestItems/${reqId}`,
    data,
  })
}

export const deletePart = (reqId) => {
  return instance({
    method: 'delete',
    url: `/manager/requestItems/${reqId}`,
  })
}

export const deleteAllPartsMachine = (data) => {
  return instance({
    method: 'post',
    url: `/deleted`,
    data,
  })
}

export const createUserMachine = (userId, data) => {
  return instance({
    method: 'post',
    url: `/manager/usermachines/${userId}`,
    data,
  })
}

export const getUserMachines = (userId) => {
  return instance({
    method: 'get',
    url: `/manager/usermachines/${userId}`,
  })
}

export const updateSNParts = (data, requestId) => {
  return instance({
    method: 'put',
    url: `/manager/RequestItems/${requestId}/serial`,
    data,
  })
}

export const deleteRequest = (reqId) => {
  return instance({
    method: 'delete',
    url: `/manager/requests/${reqId}`,
  })
}

export const addNewUserMachine = (data, reqId) => {
  return instance({
    method: 'put',
    url: `/manager/RequestItems/${reqId}/machine/create`,
    data,
  })
}

export const editUserMachine = (data, reqId) => {
  return instance({
    method: 'put',
    url: `/manager/RequestItems/${reqId}/machine/update`,
    data,
  })
}
