import { instance } from '../axiosInstance'

export const uploadImage = (data) => {
  return instance({
    method: 'post',
    url: `/files/images`,
    timeout: 300000,
    data,
  })
}
