import React, { memo, useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import BaseButton from 'src/components/BaseButton'
import FullLoader from 'src/components/FullLoader'

import Modal from '../Modal'

import { useMappedActions } from './binding'
import styles from './styles.module.scss'

const RulesModalRaw = (props) => {
  const actions = useMappedActions()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const { t, i18n } = useTranslation()
  const cacheGetDocument = useRef(actions.getDocument).current
  const { type, onClose, isNeedAccept } = props

  useEffect(() => {
    const getData = async () => {
      const data = await cacheGetDocument(type)
      if (data) setData(data)
      setIsLoading(false)
    }
    getData()
  }, [cacheGetDocument, type])

  const onClosePopup = useCallback(() => {
    onClose({ isAccepted: false })
  }, [onClose])

  const onClickAccept = useCallback(async () => {
    const isAccepted = await actions.acceptDocument(type)

    if (isAccepted) {
      onClose({ isAccepted })
    }
  }, [actions, type, onClose])

  const renderContent = useMemo(() => {
    if (data) {
      return (
        <div
          className={styles.static}
          dangerouslySetInnerHTML={{
            __html: data.content[i18n.language],
          }}
        />
      )
    }
    return null
  }, [data, i18n.language])

  return (
    <Modal title={t(`common:titles.popup_${type}`)} onClose={onClosePopup} dialogStyles={styles.dialog}>
      <div className={styles.container}>
        <div className={styles.body}>{isLoading ? <FullLoader hasText /> : renderContent}</div>
        {isNeedAccept && (
          <div className={styles.footer}>
            <BaseButton type="button" text={t('common:buttons.accept')} onClick={onClickAccept} />
          </div>
        )}
      </div>
    </Modal>
  )
}

RulesModalRaw.propTypes = {
  onClose: PropTypes.func,
  type: PropTypes.string,
}

export const RulesModal = RulesModalRaw
export default memo(RulesModal)
