import { useMapState } from 'src/hooks'

import { userSelectors } from 'src/selectors'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      isLogged: userSelectors.getIsLogged(state),
      isAdmin: userSelectors.getIsAdmin(state),
    }
  })
}
