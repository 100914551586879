import { manager } from 'src/rest'
import { to } from 'src/lib'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { prepareErrors } from 'src/services'

const assignMachinePart = ({ reqId, data }) => async (dispatch) => {
  const { value, error } = await to(manager.assignMachinePart(reqId, data))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(managerActions.assignMachineSuccess())
      dispatch(notifyThunks.assignMachineSuccess())
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(managerActions.assignMachineFailure())
      dispatch(notifyThunks.serverError(errorParams))
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })
    dispatch(managerActions.assignMachineFailure())
    dispatch(notifyThunks.serverError(errorParams))
  }
}

export default assignMachinePart
