import { docs } from 'src/rest'
import { to } from 'src/lib'
import { userActions } from 'src/actions'

const getDocument = (documentType) => async (dispatch) => {
  const { value, error } = await to(docs.getDocument(documentType))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      const { content, title } = value.data.data
      dispatch(userActions.getDocumentSuccess())
      return { content, title }
    } else {
      dispatch(userActions.getDocumentFailure())
      return null
    }
  }

  if (error) {
    dispatch(userActions.getDocumentFailure())
    return null
  }
}

export default getDocument
