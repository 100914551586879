import { promisify } from 'src/lib'
import { modalActions } from 'src/actions'
import { modalSelectors } from 'src/selectors'

const hideModal = (result) => (dispatch, getState) => {
  const type = modalSelectors.getModalType(getState())
  promisify.resolve(type, result)
  dispatch(modalActions.hideModal())
}

export default hideModal
