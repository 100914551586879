import { machine } from 'src/rest'
import { to } from 'src/lib'
import { machinesActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { prepareMachines } from 'src/services'

const loadMachines = (params) => async (dispatch) => {
  const { value, error } = await to(machine.loadMachines(params))

  if (value) {
    const { isSuccess, items, totalItems } = value.data
    if (isSuccess) {
      dispatch(machinesActions.loadMachinesSuccess())
      return {
        items: prepareMachines(items),
        total: totalItems,
      }
    } else {
      dispatch(machinesActions.loadMachinesFailure())
      return null
    }
  }

  if (error) {
    dispatch(machinesActions.loadMachinesFailure())
    dispatch(notifyThunks.somethingError())

    return null
  }
}

export default loadMachines
