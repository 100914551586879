import { request } from 'src/rest'
import { to } from 'src/lib'
import { requestsActions } from 'src/actions'
import { prepareRequests } from 'src/services'

const loadRequests = (params) => async (dispatch) => {
  const { value, error } = await to(request.loadRequests(params))

  if (value) {
    const { isSuccess, items, totalItems } = value.data

    if (isSuccess) {
      const data = {
        items: prepareRequests(items),
        total: totalItems,
      }
      dispatch(requestsActions.loadRequestsSuccess(data))
    } else {
      dispatch(requestsActions.loadRequestsFailure())
    }
  }

  if (error) {
    dispatch(requestsActions.loadRequestsFailure())
  }
}

export default loadRequests
