import i18n from 'i18next'

export default (values) => {
  const errors = {}

  if (!values.model) {
    errors.model = i18n.t('errors:fields.required_field')
  }

  if (!values.serialNumber) {
    errors.serialNumber = i18n.t('errors:fields.required_field')
  }

  return errors
}
