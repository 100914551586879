import qs from 'query-string'

import { instance } from '../axiosInstance'

export const loadAdminParts = (params) => {
  return instance({
    method: 'get',
    url: `/admin/machineparts${params ? '?' + qs.stringify(params, { skipNull: true, skipEmptyString: true }) : ''}`,
  })
}

export const loadAdminPart = (partId) => {
  return instance({
    method: 'get',
    url: `/admin/machineparts/${partId}`,
  })
}

export const updateAdminPart = (partId, data) => {
  return instance({
    method: 'put',
    url: `/admin/machineparts/${partId}`,
    data,
  })
}
