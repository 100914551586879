import { useMapState, useMapActions } from 'src/hooks'

import { modalSelectors } from 'src/selectors'
import { modalThunks } from 'src/thunks'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      modal: modalSelectors.getModal(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    hideModal: modalThunks.hideModal,
  })
}
