import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { ReactComponent as CartIcon } from 'src/assets/images/icons/cart.svg'

import styles from './styles.module.scss'

const CartRaw = ({ count }) => {
  return (
    <div className={styles.cart}>
      <NavLink to="/cart" className={styles.btn}>
        <div className={styles.icon}>
          {!!count && <div className={styles.count}>{count}</div>}
          <CartIcon />
        </div>
      </NavLink>
    </div>
  )
}

CartRaw.propTypes = {
  count: PropTypes.number,
}

export const Cart = CartRaw
export default memo(Cart)
