const prepareTapeahead = (items) => {
  return items.map((item, idx) => {
    return {
      id: 'typeahead_' + idx,
      value: item,
    }
  })
}

export default prepareTapeahead
