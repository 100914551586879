import { manager } from 'src/rest'
import { to } from 'src/lib'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { prepareErrors } from 'src/services'

const deleteQuote = (reqId) => async (dispatch) => {
  const { value, error } = await to(manager.deleteQuote(reqId))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      dispatch(managerActions.deleteQuoteSuccess())
      return data
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(managerActions.deleteQuoteFailure())
    }
  }

  if (error) {
    const errorParams = prepareErrors({ data: value.data })

    dispatch(notifyThunks.serverError(errorParams))
    dispatch(managerActions.deleteQuoteFailure())
  }
}

export default deleteQuote
