import React, { memo, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import FormLayout from 'src/forms/FormLayout'
import InputWithForm from 'src/forms/InputWithForm'
import SelectWithForm from 'src/forms/SelectWithForm'
import InputRow from 'src/forms/InputRow'

import Modal from '../Modal'

import styles from './styles.module.scss'
import validate from './validate'
import { useMappedState, useMappedActions } from './bindings'

const CUUserMachineRaw = (props) => {
  const { onClose, userId, isDisabledModel, isEditing, localParam, payload, ...values } = props
  const { t } = useTranslation()
  const { typesOptions } = useMappedState()
  const actions = useMappedActions()
  const initialValues = { model: typesOptions[0].value, ...values }

  const title = useMemo(() => {
    const prefix = isEditing ? 'popup_edit_machine' : 'popup_add_machine'
    return t(`common:titles.${prefix}`)
  }, [isEditing, t])

  const onSubmit = useCallback(
    async (values) => {
      const { designation, serialNumber } = values
      const nextPayload = { ...payload, designation, serialNumber }
      const { requestId } = localParam
      const { isSuccess, data, ...errors } = isEditing
        ? await actions.editUserMachine(nextPayload, requestId, userId)
        : await actions.addNewUserMachine(nextPayload, requestId, userId)

      if (!isEmpty(errors)) {
        return errors
      }

      onClose(data)
    },
    [actions, onClose, userId, isEditing, localParam, payload],
  )

  return (
    <Modal title={title} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.confirm')}
            >
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.model') + '*'}</span>
                {isDisabledModel ? (
                  <InputWithForm name="model" isSimple disabled />
                ) : (
                  <SelectWithForm
                    name="model"
                    options={typesOptions}
                    defaultOption={typesOptions[0] && typesOptions[0].value}
                    isSimple
                  />
                )}
              </InputRow>
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.serialNumber') + '*'}</span>
                <InputWithForm name="serialNumber" isSimple />
              </InputRow>
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.designation')}</span>
                <InputWithForm name="designation" isSimple maxLength={40} />
              </InputRow>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

CUUserMachineRaw.propTypes = {
  onClose: PropTypes.func,
  model: PropTypes.string,
  serialNumber: PropTypes.string,
  designation: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export const CUUserMachine = CUUserMachineRaw
export default memo(CUUserMachine)
