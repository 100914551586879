import { auth } from 'src/rest'
import { to } from 'src/lib'
import { authActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { errorsConstants } from 'src/constants'

const confirmUser = (uuid) => async (dispatch) => {
  const { value, error } = await to(auth.confirmUser(uuid))

  if (value) {
    const { data } = value.data
    dispatch(authActions.confirmUserSuccess())
    dispatch(notifyThunks.confirmUserSuccess())

    return data.accessToken
  }

  if (error) {
    dispatch(authActions.confirmUserFailure())

    const { data } = (error && error.response) || {}

    if (data && data.errorCode === errorsConstants.USER_NOT_FOUND) {
      dispatch(notifyThunks.confirmUserError())
    }

    if (data && data.errorCode === errorsConstants.ALREADY_VERIFIED_EMAIL) {
      dispatch(notifyThunks.confirmUserWarning())
    }

    return null
  }
}

export default confirmUser
