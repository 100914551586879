import i18n from 'i18next'
import { FORM_ERROR } from 'final-form'

import { user } from 'src/rest'
import { to } from 'src/lib'
import { userActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { userSelectors } from 'src/selectors'

const updateUser = (values) => async (dispatch, getState) => {
  const state = getState()
  const uuid = userSelectors.getUuid(state)
  const { value, error } = await to(user.updateUser(values, uuid))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(userActions.updateUserSuccess(values))
      dispatch(notifyThunks.updateUserSuccess())
    } else {
      dispatch(userActions.updateUserFailure())
    }
  }

  if (error) {
    dispatch(userActions.updateUserFailure())
    dispatch(notifyThunks.somethingError())

    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default updateUser
