import { manager } from 'src/rest'
import { to } from 'src/lib'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { prepareErrors } from 'src/services'
import { errorsConstants } from 'src/constants'

const releaseRequest = (reqId) => async (dispatch) => {
  dispatch(managerActions.releaseRequestStart())
  const { value, error } = await to(manager.releaseRequest(reqId))

  if (value) {
    const { isSuccess, notificationStatus } = value.data

    if (isSuccess) {
      dispatch(managerActions.releaseRequestSuccess())
      dispatch(notifyThunks.releaseRequestSuccess())

      if (notificationStatus === errorsConstants.EMAIL_WARNING) {
        dispatch(notifyThunks.emailWarning())
      }
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(managerActions.releaseRequestFailure())
      dispatch(notifyThunks.serverError(errorParams))
      return errorParams.errorCode
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })
    dispatch(managerActions.releaseRequestFailure())
    dispatch(notifyThunks.serverError(errorParams))
    return errorParams.errorCode
  }
}

export default releaseRequest
