import { instance } from '../axiosInstance'

export const updateUser = (data, uuid) => {
  return instance({
    method: 'put',
    url: `/users/${uuid}`,
    data,
  })
}

export const updateUserLang = (data) => {
  return instance({
    method: 'post',
    url: '/users/language',
    data,
  })
}

export const changeEmail = (data) => {
  return instance({
    method: 'post',
    url: `/emails/change/request`,
    data,
  })
}

export const confirmEmail = (data) => {
  return instance({
    method: 'post',
    url: `/emails/change`,
    data,
  })
}

export const changePassword = (data) => {
  return instance({
    method: 'post',
    url: `/passwords`,
    data,
  })
}
