import React, { memo, useState, useCallback, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseIcon } from 'src/assets/images/icons/help_close.svg'

import CustomWithForm from 'src/forms/CustomWithForm'
import InputWithForm from 'src/forms/InputWithForm'

import SelectSN from '../SelectSN'

import styles from './styles.module.scss'

const SelectSNFormRaw = (props) => {
  const { values, form, options } = props
  const { t } = useTranslation()
  const [isAddBlockShown, setIsAddBlockShown] = useState(false)

  const onChangeAddBlockVisibility = useCallback(() => {
    if (isAddBlockShown && form) {
      form.reset(values)
      form && form.change('serialNumberNew', '')
      form && form.change('designation', '')
    }

    setIsAddBlockShown(!isAddBlockShown)
  }, [isAddBlockShown, values, form])

  if (isAddBlockShown) {
    return (
      <div className={styles.block}>
        <button className={styles.close} onClick={onChangeAddBlockVisibility} type="button">
          <CloseIcon />
        </button>
        <div className={styles.row}>
          <div className={styles.label}>{t('machine:info.serialNumber')}</div>
          <div className={styles.input}>
            <InputWithForm name="serialNumberNew" isSimple />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{t('machine:info.designation')}</div>
          <div className={styles.input}>
            <InputWithForm name="designation" isSimple />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <span className={styles.label}>{t('machine:info.serialNumber')}</span>
      <CustomWithForm
        name="serialNumber"
        render={({ input }) => {
          return <SelectSN {...input} options={options} onAdd={onChangeAddBlockVisibility} hasEditing={false} />
        }}
      />
    </Fragment>
  )
}

export const SelectSNForm = SelectSNFormRaw
export default memo(SelectSNForm)
