import React, { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import startCase from 'lodash/startCase'

import Counter from 'src/components/Counter'
import SelectSNForm from 'src/components/SelectSNForm'

import FormLayout from 'src/forms/FormLayout'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'
import SelectWithForm from 'src/forms/SelectWithForm'
import TextareaWithForm from 'src/forms/TextareaWithForm'
import CounterWithForm from 'src/forms/CounterWithForm'

import Modal from '../Modal'

import validate from './validate'
import { useMappedState, useMappedActions } from './bindings'
import styles from './styles.module.scss'

const DidntFindRaw = (props) => {
  const { onClose, model, serialNumber, machineComponent } = props
  const { t } = useTranslation()
  const { machines, componentTypes } = useMappedState(model)
  const actions = useMappedActions()

  const initialValues = useMemo(() => {
    const values = { amount: 1, machineType: model }

    if (serialNumber) {
      values.serialNumber = serialNumber
    }

    if (componentTypes) {
      values.machineComponent = startCase(machineComponent) || componentTypes[0].value
    }

    return values
  }, [serialNumber, model, componentTypes, machineComponent])

  const onSubmit = useCallback(
    async (values) => {
      const error = await actions.addNotFound({ values, machineType: model })

      if (error) {
        return error
      }

      onClose()
    },
    [actions, onClose, model],
  )

  return (
    <Modal title={t('machine:didnt_find')} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine, form, values }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.add_to_cart')}
            >
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.model') + '*'}</span>
                <InputWithForm name="machineType" disabled isSimple />
              </InputRow>
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.component') + '*'}</span>
                <SelectWithForm
                  name="machineComponent"
                  options={componentTypes}
                  defaultOption={componentTypes[0] && componentTypes[0].value}
                  isSimple
                />
              </InputRow>
              <InputRow className={styles.inputRow}>
                <SelectSNForm form={form} values={values} options={machines} />
              </InputRow>
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.description') + '*'}</span>
                <TextareaWithForm name="description" isSimple />
              </InputRow>
              <InputRow>
                <Counter>
                  <CounterWithForm name="amount" />
                </Counter>
              </InputRow>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

export const DidntFind = DidntFindRaw
export default memo(DidntFind)
