import { instance } from '../axiosInstance'

export const getDocument = (documentType) => {
  return instance({
    method: 'get',
    url: `/documents/${documentType}`,
  })
}

export const acceptDocument = (documentType) => {
  return instance({
    method: 'post',
    url: `/documents/${documentType}`,
  })
}
