import React, { memo, useCallback } from 'react'

import { modalConstants } from 'src/constants'

import Lang from './components/Lang'
import Cart from './components/Cart'
import Profile from './components/Profile'
import Panel from './components/Panel'
import Login from './components/Login'
import Logo from './components/Logo'

import { useMappedState, useMappedActions } from './bindings'
import styles from './styles.module.scss'

const HeaderRaw = () => {
  const { isLogged, isLoggingIn, isAdmin, cartTotalItems, languages } = useMappedState()
  const actions = useMappedActions()
  const isMultiLang = languages && languages.length > 1

  const onClickLang = useCallback(
    (lang) => {
      actions.setLanguage(lang)

      if (isLogged) {
        actions.updateUserLang(lang)
      }
    },
    [actions, isLogged],
  )

  const onClickLogin = useCallback(() => {
    if (isLogged) {
      actions.logout()
    } else {
      actions.showModal(modalConstants.LOGIN_MODAL).then((value) => {
        if (value) {
          const { type } = value
          if (type === 'open_reg') {
            actions.showModal(modalConstants.REG_MODAL)
          }

          if (type === 'reset_pass') {
            actions.showModal(modalConstants.RESET_PASS_MODAL)
          }
        }
      })
    }
  }, [isLogged, actions])

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <Logo />
        </div>
        <div className={styles.right}>
          {isAdmin && <Panel />}
          <Lang onClickLang={onClickLang} isMultiLang={isMultiLang} languages={languages} />
          <Cart count={cartTotalItems} />
          {isLogged && <Profile />}
          <Login onClickLogin={onClickLogin} isLogged={isLogged} isLoggingIn={isLoggingIn} />
        </div>
      </div>
    </header>
  )
}

export const Header = HeaderRaw
export default memo(Header)
