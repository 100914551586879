import React, { memo, useCallback, useState } from 'react'

import { ReactComponent as UploadIcon } from 'src/assets/images/icons/upload.svg'
import { ReactComponent as DelIcon } from 'src/assets/images/icons/del-image.svg'

import FullLoader from 'src/components/FullLoader'

import styles from './styles.module.scss'

const MAX_FILE_SIZE = 10

const ImageRaw = (props) => {
  const { onUpload, onBigSize, onDelImg, imageUrl } = props
  const [isUploading, setIsUploading] = useState(false)

  const onUploadImg = useCallback(
    async (e) => {
      const file = e.target.files[0]
      const fileSize = file.size / (1024 * 1024)
      setIsUploading(true)

      if (fileSize > MAX_FILE_SIZE) {
        onBigSize()
      } else {
        const formData = new FormData()
        formData.append('file', file)
        await onUpload(formData)
        setIsUploading(false)
      }
    },
    [onUpload, onBigSize],
  )

  return (
    <div className={styles.upload}>
      {isUploading && <FullLoader />}
      {!!imageUrl ? (
        <div className={styles.img}>
          <button className={styles.imgDel} onClick={onDelImg} type="button">
            <DelIcon />
          </button>
          <img src={imageUrl} alt="part" />
        </div>
      ) : (
        <label className={styles.stub}>
          <input type="file" onChange={onUploadImg} accept=".png, .jpg, .jpeg" hidden />
          <UploadIcon />
          <div className={styles.stubTitle}>Upload image</div>
          <div className={styles.stubDesc}>jpg, png (max size 10MB)</div>
        </label>
      )}
    </div>
  )
}

export const Image = ImageRaw
export default memo(Image)
