import React, { memo, useMemo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { preparePartsOptions } from 'src/services'
import FullLoader from 'src/components/FullLoader'
import Counter from 'src/components/Counter'
import BaseButton from 'src/components/BaseButton'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'
import SelectWithForm from 'src/forms/SelectWithForm'
import CounterWithForm from 'src/forms/CounterWithForm'
import TextareaWithForm from 'src/forms/TextareaWithForm'

import Modal from '../Modal'

import validate from './validate'
import validateOther from './validateOther'
import { useMappedState, useMappedActions } from './bindings'
import styles from './styles.module.scss'

const AddUserPartModalRaw = (props) => {
  const { onClose, model, serialNumber, userMachineId, isAssign, requestPartId, amount } = props
  const { t, i18n } = useTranslation()
  const { componentTypes, parts, requestId } = useMappedState(model)
  const lang = i18n.language

  const partsOptions = preparePartsOptions(parts, lang)
  const actions = useMappedActions()

  const initialValues = useMemo(() => {
    const values = { machineType: model, serialNumber, amount: amount || 1 }

    return values
  }, [model, serialNumber, amount])

  const onClickSubmit = useCallback(() => {
    document.getElementById('part-form').dispatchEvent(new Event('submit', { cancelable: true }))
  }, [])

  const onSubmit = useCallback(
    async (values) => {
      const { partId, amount, hbPartNumber, managerComments, machineType, serialNumber } = values
      if (isAssign) {
        const payload = {
          managerComments,
          hbPartNumber,
          machineType,
          serialNumber,
        }

        if (partId) {
          payload.machinePartId = partId
          payload.amount = amount
        }

        const errors = await actions.assignMachinePart({ reqId: requestPartId, data: payload })

        if (errors) {
          return errors
        }
      } else {
        const payload = {
          requestId,
          partId,
          userMachineId: userMachineId,
          amount,
          hbPartNumber,
          managerComments,
          machineType,
          serialNumber,
        }

        const errors = await actions.addPart({ values: payload })

        if (errors) {
          return errors
        }
      }

      onClose(true)
    },
    [actions, onClose, requestId, isAssign, requestPartId, userMachineId],
  )
  const title = useMemo(() => {
    return isAssign ? t(`common:titles.popup_assign_part`) : t(`common:titles.popup_add_part`)
  }, [isAssign, t])

  return (
    <Modal title={title} onClose={onClose} dialogStyles={styles.dialog}>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.form}>
            <Form
              initialValues={initialValues}
              validate={isAssign ? validateOther : validate}
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, values }) => {
                const { machineComponent } = values

                return (
                  <form onSubmit={handleSubmit} id="part-form" noValidate>
                    {submitting && <FullLoader hasText />}
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.model') + '*'}</span>
                      <InputWithForm name="machineType" disabled isSimple />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.serialNumber')}</span>
                      <InputWithForm name="serialNumber" disabled isSimple />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.component') + `${isAssign ? '' : '*'}`}</span>

                      <SelectWithForm
                        name="machineComponent"
                        options={componentTypes}
                        placeholder={t('machine:select_component')}
                        isSimple
                      />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.id_name') + `${isAssign ? '' : '*'}`}</span>
                      <SelectWithForm
                        name="partId"
                        options={partsOptions[machineComponent] || []}
                        placeholder={t('machine:select_id')}
                        isSimple
                        hasSearch
                      />
                    </InputRow>
                    <InputRow className={styles.inputRow} isLarge>
                      <span className={styles.label}>{t('machine:info.h&b')}</span>
                      <InputWithForm name="hbPartNumber" isSimple />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.comment')}</span>
                      <TextareaWithForm name="managerComments" isSimple maxLength={255} />
                    </InputRow>
                    <InputRow>
                      <Counter>
                        <CounterWithForm name="amount" />
                      </Counter>
                    </InputRow>
                  </form>
                )
              }}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <BaseButton type="button" text={t('common:buttons.save')} onClick={onClickSubmit} />
        </div>
      </div>
    </Modal>
  )
}

export const AddUserPartModal = AddUserPartModalRaw
export default memo(AddUserPartModal)
