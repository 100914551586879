import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Select from '../Select'

import styles from './styles.module.scss'

const SelectWithFormRaw = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        const hasError = (meta.error || meta.submitError) && meta.touched
        return (
          <div className={styles.field}>
            <Select {...input} {...props} hasError={hasError} />
            {hasError && <div className={styles.errorText}>{meta.error || meta.submitError}</div>}
          </div>
        )
      }}
    </Field>
  )
}

SelectWithFormRaw.propTypes = {
  name: PropTypes.string.isRequired,
}

const SelectWithForm = SelectWithFormRaw
export default memo(SelectWithForm)
