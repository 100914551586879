import { useMapState, useMapActions } from 'src/hooks'
import { userThunks, authThunks } from 'src/thunks'
import { userSelectors } from 'src/selectors'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      email: userSelectors.getEmail(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    changeEmail: userThunks.changeEmail,
    logout: authThunks.logout,
  })
}
