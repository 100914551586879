import { combineReducers } from '@reduxjs/toolkit'

import items from './items'
import total from './total'

const reducer = combineReducers({
  items,
  total,
})

export default reducer
