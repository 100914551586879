import _omit from 'lodash/omit'

import { commonConstants } from 'src/constants'

const getState = (state) => state.user

export const getIsLogged = (state) => getState(state).isLogged
export const getIsLoggingIn = (state) => getState(state).isLoggingIn
export const getInfo = (state) => getState(state).info
export const getUuid = (state) => getInfo(state).id
export const getEmail = (state) => getInfo(state).email
export const getRole = (state) => getInfo(state).role
export const getPersonalInfo = (state) => _omit(getInfo(state), ['language', 'email', 'role', 'id'])
export const getIsAdmin = (state) => getRole(state) === commonConstants.ADMIN
