import { models } from 'src/rest'
import { to } from 'src/lib'
import { modelsActions } from 'src/actions'
import { prepareModels } from 'src/services'

const loadModels = () => async (dispatch) => {
  dispatch(modelsActions.loadModelsStart())

  const { value, error } = await to(models.getMachineModels())

  if (value) {
    const { data } = value.data

    dispatch(modelsActions.loadModelsSuccess(prepareModels(data)))
  }

  if (error) {
    dispatch(modelsActions.loadModelsFailure())
  }
}

export default loadModels
