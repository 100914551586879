import { handleActions } from 'redux-actions'

import { modelsActions } from 'src/actions'

export const initialState = []

const reducerMap = {
  [modelsActions.loadModelsSuccess]: (state, { payload }) => payload.components,
  [modelsActions.loadModelsFailure]: () => initialState,
}

export default handleActions(reducerMap, initialState)
