import i18n from 'i18next'
import { FORM_ERROR } from 'final-form'

import { machine } from 'src/rest'
import { to } from 'src/lib'
import { machinesActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { errorsConstants } from 'src/constants'
import { userSelectors } from 'src/selectors'

import editMachineLocal from './editMachineLocal'

const editMachine = (values, id, oldSerialNumber) => async (dispatch, getState) => {
  const state = getState()
  const isLogged = userSelectors.getIsLogged(state)

  if (isLogged) {
    const { value, error } = await to(machine.editMachine(values, id))

    if (value) {
      const { isSuccess } = value.data
      if (isSuccess) {
        dispatch(machinesActions.editMachineSuccess())
        return {
          isSuccess,
          data: {
            ...values,
            id,
          },
        }
      } else {
        dispatch(machinesActions.editMachineFailure())
      }
    }

    if (error) {
      dispatch(machinesActions.editMachineFailure())

      const { data } = (error && error.response) || {}

      if (data && data.errorCode === errorsConstants.DUPLICATE_SERIAL_NUMBER) {
        return { serialNumber: i18n.t('errors:fields.duplicate_serial_number') }
      }

      dispatch(notifyThunks.somethingError())
      return { [FORM_ERROR]: i18n.t('errors:server.common') }
    }
  } else {
    return await dispatch(editMachineLocal(values, id, oldSerialNumber))
  }
}

export default editMachine
