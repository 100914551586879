import React, { memo, Suspense, useCallback } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useMappedState } from './bindings'

const RouteWithSubRoutesRaw = (route) => {
  const { isLogged, isAdmin } = useMappedState()

  const renderContent = useCallback(
    (props) => {
      if (route.redirect) {
        return <Redirect to={route.redirect} />
      }

      if (route.private) {
        if (route.admin) {
          if (isAdmin && route.component) {
            return <route.component {...props} {...route.routeProps} routes={route.routes} />
          }

          return <Redirect to="/home" />
        } else {
          if (isLogged && route.component) {
            return <route.component {...props} {...route.routeProps} routes={route.routes} />
          }

          return <Redirect to="/home" />
        }
      }

      return route.component && <route.component {...props} {...route.routeProps} routes={route.routes} />
    },
    [route, isLogged, isAdmin],
  )

  return (
    <Suspense fallback={route.fallback}>
      <Route path={route.path} render={renderContent} />
    </Suspense>
  )
}

export const RouteWithSubRoutes = RouteWithSubRoutesRaw
export default memo(RouteWithSubRoutes)
