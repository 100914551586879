import { handleActions, combineActions } from 'redux-actions'

import { cartActions, authActions } from 'src/actions'

export const initialState = {}

const reducerMap = {
  [cartActions.loadCartSuccess]: (state, { payload }) => {
    return payload.parts
  },
  [combineActions(cartActions.cartSubmitSuccess, authActions.logoutSuccess, cartActions.resetCart)]: () => initialState,
}

export default handleActions(reducerMap, initialState)
