const findMachine = (elem, params) => {
  const { serialNumber, machineType } = params

  return elem.machineType === machineType && elem.serialNumber === serialNumber
}

const findPart = (part, partId) => {
  if (partId) {
    return part.id === partId
  }

  return false
}

const editSNLocal = (cart, param) => {
  const { machineType, oldSerialNumber, existingSerialNumber, designation } = param
  const indexMachine = cart.findIndex((elem) => findMachine(elem, { serialNumber: oldSerialNumber, machineType }))
  const indexCurrentMachine = cart.findIndex((elem) =>
    findMachine(elem, { serialNumber: existingSerialNumber, machineType }),
  )

  if (indexCurrentMachine >= 0) {
    if (existingSerialNumber === oldSerialNumber) {
      if (designation) {
        cart[indexCurrentMachine].designation = designation
      }

      return cart
    }

    const nextItems = cart[indexMachine].items.reduce((acc, part) => {
      const { id, userComments = '', amount } = part

      const indexPart = acc.findIndex((x) => findPart(x, id, userComments))

      if (indexPart >= 0) {
        let nextComment = ''
        if (!!userComments) {
          if (!!acc[indexPart].userComments) {
            nextComment = userComments + ' | ' + acc[indexPart].userComments
          } else {
            nextComment = userComments
          }
        } else {
          if (!!acc[indexPart].userComments) {
            nextComment = acc[indexPart].userComments
          } else {
            nextComment = ''
          }
        }

        acc[indexPart].amount = acc[indexPart].amount + amount
        acc[indexPart].userComments = nextComment
        acc[indexPart].machineSerialNumber = cart[indexCurrentMachine].serialNumber
      } else {
        acc.push({ ...part, machineSerialNumber: existingSerialNumber })
      }

      return acc
    }, cart[indexCurrentMachine].items)

    cart[indexCurrentMachine].items = nextItems

    cart.splice(indexMachine, 1)
  } else {
    if (indexMachine >= 0) {
      cart[indexMachine].serialNumber = existingSerialNumber
      if (designation) {
        cart[indexMachine].designation = designation
      }
      cart[indexMachine].items = cart[indexMachine].items.map((part) => {
        part.machineSerialNumber = existingSerialNumber

        return part
      })
    }
  }

  return cart
}

export default editSNLocal
