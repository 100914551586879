const prepareMachineTables = (data) => {
  let totalItems = 0

  let parts = data.reduce((accum, machine) => {
    const { machineType = 'OTHER', serialNumber = 'NOT_FOUND_SN', serialNumberLatestUpdate, items } = machine

    totalItems = totalItems + items.length
    if (accum[machineType]) {
      accum = {
        ...accum,
        [machineType]: {
          ...accum[machineType],
          [serialNumber]: {
            items,
            hint: serialNumberLatestUpdate,
          },
        },
      }
    } else {
      accum = {
        ...accum,
        [machineType]: {
          [serialNumber]: {
            items,
            hint: serialNumberLatestUpdate,
          },
        },
      }
    }

    return accum
  }, {})

  return { totalItems, parts }
}

export default prepareMachineTables
