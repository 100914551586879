import i18n from 'i18next'

const prepareErrors = ({ data, error }) => {
  const errorData = {
    type: 'error',
    title: i18n.t(`notify:title_error`),
    desc: i18n.t(`errors:server.common`),
    errorCode: '',
    errorData: null,
  }

  if (data) {
    const { errorCode, errorData } = data

    if (errorCode) {
      errorData.title = i18n.t(`notify:title_${errorData.type}`)
      errorData.desc = i18n.t(`errors:server.${errorCode}`)
      errorData.errorCode = errorCode
      errorData.errorData = errorData
    }
  }

  if (error) {
    const { data } = (error && error.response) || {}

    if (data && data.errorCode) {
      errorData.title = i18n.t(`notify:title_${errorData.type}`)
      errorData.desc = i18n.t(`errors:server.${data.errorCode}`)
      errorData.errorCode = data.errorCode
      errorData.errorData = data.errorData
    }
  }

  return errorData
}

export default prepareErrors
