import qs from 'query-string'

import { instance } from '../axiosInstance'

export const loadAdmins = (params) => {
  return instance({
    method: 'get',
    url: `/admin/admins${params ? '?' + qs.stringify(params, { skipNull: true, skipEmptyString: true }) : ''}`,
  })
}

export const createAdmin = (data) => {
  return instance({
    method: 'post',
    url: `/admin/admins`,
    data,
  })
}

export const editAdmin = (data, adminId) => {
  return instance({
    method: 'put',
    url: `/admin/admins/${adminId}`,
    data,
  })
}

export const deleteAdmin = (adminId) => {
  return instance({
    method: 'delete',
    url: `/admin/admins/${adminId}`,
  })
}
