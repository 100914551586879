/* global Map */
const RESOLVERS = new Map()

/**
 * @param { Promise } promise
 * @param { * } [promiseToken]
 * @return { Promise }
 */
const to = (promise, promiseToken) => {
  return new Promise((resolve) => {
    saveToken(promiseToken, resolve)
    promise
      .then((value) => {
        resolve(getResult(null, value, false))
        clearToken(promiseToken)
      })
      .catch((error) => {
        const failure = error || new Error()
        resolve(getResult(failure, undefined, false))
        clearToken(promiseToken)
      })
  })
}

/**
 * @param { * } [promiseToken]
 * @return { Boolean }
 */
const isInProgress = (promiseToken) => {
  return RESOLVERS.has(promiseToken)
}

/**
 * @param { * } [promiseToken]
 * @return { Boolean }
 */
const tryCancelPromise = (promiseToken) => {
  const resolve = RESOLVERS.get(promiseToken)
  if (resolve) {
    resolve(getResult(null, undefined, true))
    clearToken(promiseToken)
    warnInDev(promiseToken)
    return true
  }
  return false
}

/**
 * @param { * } [promiseToken]
 * @param { * } [value]
 * @return { Boolean }
 */
const tryResolvePromise = (promiseToken, value) => {
  const resolve = RESOLVERS.get(promiseToken)
  if (resolve) {
    resolve(getResult(null, value, false))
    clearToken(promiseToken)
    return true
  }
  return false
}

/**
 * @param { * } [promiseToken]
 * @param { string|Object } [error]
 * @return { Boolean }
 */
const tryRejectPromise = (promiseToken, error) => {
  const resolve = RESOLVERS.get(promiseToken)
  if (resolve) {
    resolve(getResult(error, undefined, false))
    clearToken(promiseToken)
    return true
  }
  return false
}

const saveToken = (promiseToken, resolve) => {
  if (promiseToken !== undefined) {
    tryCancelPromise(promiseToken)
    RESOLVERS.set(promiseToken, resolve)
  }
}

const clearToken = (promiseToken) => {
  RESOLVERS.delete(promiseToken)
}

const getResult = (error, value, isCanceled) => {
  return { error, value, isCanceled }
}

const warnInDev = (promiseToken) => {
  if (process.env.NODE_ENV === 'development') {
    const tokenName = typeof promiseToken === 'function' ? promiseToken.name : promiseToken
    console.warn(`🚫 Promise with token [${tokenName}] is canceled`)
  }
}

to.isInProgress = isInProgress
to.cancelPromise = tryCancelPromise
to.resolvePromise = tryResolvePromise
to.rejectPromise = tryRejectPromise

export default to
