import { FORM_ERROR } from 'final-form'
import i18n from 'i18next'

import { parts } from 'src/rest'
import { to } from 'src/lib'
import { partsActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'

const updatePart = (partId, values) => async (dispatch) => {
  const { value, error } = await to(parts.updateAdminPart(partId, values))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      dispatch(partsActions.updatePartSuccess())
      return data
    } else {
      dispatch(partsActions.updatePartFailure())
    }
  }

  if (error) {
    dispatch(partsActions.updatePartFailure())

    dispatch(notifyThunks.somethingError())
    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default updatePart
