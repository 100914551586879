import { common } from 'src/rest'
import { to } from 'src/lib'
import { commonActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { errorsConstants } from 'src/constants'

const contactUs = (data) => async (dispatch) => {
  const { value, error } = await to(common.contactUs(data))

  if (value) {
    const { notificationStatus } = value.data
    dispatch(commonActions.contactUsSuccess())

    if (notificationStatus === errorsConstants.EMAIL_WARNING) {
      dispatch(notifyThunks.emailWarning())
    }
    return true
  }

  if (error) {
    dispatch(commonActions.contactUsFailure())
    return false
  }
}

export default contactUs
