import React, { memo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import FormLayout from 'src/forms/FormLayout'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'

import Modal from '../Modal'

import { useMappedState, useMappedActions } from './bindings'
import validate from './validate'
import styles from './styles.module.scss'

const PersonalInfoModalRaw = (props) => {
  const { onClose } = props
  const { t } = useTranslation()
  const { personalInfo } = useMappedState()
  const actions = useMappedActions()

  const onSubmit = useCallback(
    async (values) => {
      const errors = await actions.updateUser(values)

      if (errors) return errors

      onClose()
    },
    [actions, onClose],
  )

  return (
    <Modal title={t('common:titles.popup_profile')} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        initialValues={personalInfo}
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.save_changes')}
            >
              <div className={styles.row}>
                <div className={styles.col}>
                  <h3 className={styles.title}>{t('profile:info.company_title')}</h3>
                  <InputRow className={styles.inputRow}>
                    <span className={styles.label}>{t('profile:info.company_name') + '*'}</span>
                    <InputWithForm name="companyName" isSimple />
                  </InputRow>
                  <InputRow className={styles.inputRow}>
                    <span className={styles.label}>{t('profile:info.company_address')}</span>
                    <InputWithForm name="companyAddress" isSimple />
                  </InputRow>
                  <InputRow className={styles.inputRow}>
                    <span className={styles.label}>{t('profile:info.company_phone')}</span>
                    <InputWithForm type="number" name="companyPhone" isSimple />
                  </InputRow>
                </div>
                <div className={styles.col}>
                  <h3 className={styles.title}>{t('profile:info.contact_title')}</h3>
                  <InputRow className={styles.inputRow}>
                    <span className={styles.label}>{t('profile:info.first_name') + '*'}</span>
                    <InputWithForm name="firstName" isSimple />
                  </InputRow>
                  <InputRow className={styles.inputRow}>
                    <span className={styles.label}>{t('profile:info.last_name') + '*'}</span>
                    <InputWithForm name="lastName" isSimple />
                  </InputRow>
                  <InputRow className={styles.inputRow}>
                    <span className={styles.label}>{t('profile:info.phone') + '*'}</span>
                    <InputWithForm type="number" name="phone" isSimple />
                  </InputRow>
                </div>
              </div>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

export const PersonalInfoModal = PersonalInfoModalRaw
export default memo(PersonalInfoModal)
