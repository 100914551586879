import { handleActions } from 'redux-actions'

import { requestsActions, managerActions } from 'src/actions'

export const initialState = []

const reducerMap = {
  [managerActions.getUserMachinesSuccess]: (state, { payload }) => payload,
  [requestsActions.cleanRequest]: () => initialState,
}

export default handleActions(reducerMap, initialState)
