import { instance } from '../axiosInstance'

export const addNotFound = (data) => {
  return instance({
    method: 'post',
    url: `/cart/notfound`,
    data,
  })
}

export const getUserCart = () => {
  return instance({
    method: 'get',
    url: `/cart`,
  })
}

export const addPart = (data) => {
  return instance({
    method: 'post',
    url: `/cart`,
    data,
  })
}

export const syncCart = (data) => {
  return instance({
    method: 'post',
    url: `/cart/sync`,
    data,
  })
}

export const replaceCart = (data) => {
  return instance({
    method: 'post',
    url: `/cart/replace`,
    data,
  })
}

export const updatePart = (requestPartId, data) => {
  return instance({
    method: 'put',
    url: `/cart/${requestPartId}`,
    data,
  })
}

export const deletePart = (requestPartId) => {
  return instance({
    method: 'delete',
    url: `/cart/${requestPartId}`,
  })
}

export const updatePartsBySN = (data) => {
  return instance({
    method: 'put',
    url: `/cart/parts/serial`,
    data,
  })
}

export const cartSubmit = (data) => {
  return instance({
    method: 'post',
    url: `/cart/submit`,
    data,
  })
}
