import axios from 'axios'

import { manager } from 'src/rest'
import { to } from 'src/lib'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { prepareErrors } from 'src/services'

const assignQuote = (reqId, data) => async (dispatch) => {
  const { value, error } = await to(manager.assignQuote(reqId, data))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      dispatch(managerActions.assignQuoteSuccess())
      dispatch(notifyThunks.assignQuoteSuccess())
      return data
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(managerActions.assignQuoteFailure())
    }
  }

  if (error) {
    if (!axios.isCancel(error)) {
      dispatch(notifyThunks.slowInternetError())
    }
    dispatch(managerActions.assignQuoteFailure())
  }
}

export default assignQuote
