import i18n from 'i18next'

import { auth } from 'src/rest'
import { to } from 'src/lib'
import { authActions } from 'src/actions'

const login = (accessToken) => async (dispatch) => {
  if (accessToken) {
    dispatch(authActions.loginStart(accessToken))
  }

  const { value, error } = await to(auth.userInfo())

  if (value) {
    const { data } = value.data

    if (data.language !== i18n.language) {
      i18n.changeLanguage(data.language)
    }

    dispatch(authActions.loginSuccess(data))
  }

  if (error) {
    dispatch(authActions.loginFailure())
  }
}

export default login
