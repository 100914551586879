import { combineReducers } from '@reduxjs/toolkit'

import items from './items'
import total from './total'
import sort from './sort'

const reducer = combineReducers({
  items,
  total,
  sort,
})

export default reducer
