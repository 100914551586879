import React, { memo, useCallback } from 'react'

import Notify from '../Notify'

import { useMappedState, useMappedActions } from './bindings'
import styles from './styles.module.scss'

const NotifyManagerRaw = () => {
  const { notifications } = useMappedState()
  const actions = useMappedActions()

  const onHide = useCallback(
    (id) => {
      actions.hideNotify({ id })
    },
    [actions],
  )

  return (
    <div className={styles.notifications}>
      {notifications.map((item) => (
        <Notify key={item.id} {...item} onHide={onHide} />
      ))}
    </div>
  )
}

export const NotifyManager = NotifyManagerRaw
export default memo(NotifyManager)
