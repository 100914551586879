import { parts } from 'src/rest'
import { to } from 'src/lib'
import { partsActions } from 'src/actions'

const loadPart = (partId) => async (dispatch) => {
  const { value, error } = await to(parts.loadAdminPart(partId))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      dispatch(partsActions.loadPartSuccess())
      return data
    } else {
      dispatch(partsActions.loadPartFailure())
    }
  }

  if (error) {
    dispatch(partsActions.loadPartFailure())
  }
}

export default loadPart
