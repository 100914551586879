import { storage } from 'src/lib'
import { storageConstants } from 'src/constants'
import { appActions } from 'src/actions'

import * as authThunks from '../auth'
import * as commonThunks from '../common'
import * as modelsThunks from '../models'
import * as machinesThunks from '../machines'
import * as cartThunks from '../cart'

const appInit = () => async (dispatch) => {
  dispatch(appActions.appInitStart())

  await dispatch(commonThunks.loadSettings())

  const [accessToken] = await Promise.all([
    storage.getItem(storageConstants.ACCESS_TOKEN),
    dispatch(commonThunks.setLanguage()),
    dispatch(modelsThunks.loadModels()),
    dispatch(cartThunks.loadUserCart()),
  ])

  if (accessToken) {
    await dispatch(authThunks.login(accessToken))
  } else {
    dispatch(machinesThunks.loadAllMachinesLocal())
  }
}

export default appInit
