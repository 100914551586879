import { machine } from 'src/rest'
import { to } from 'src/lib'
import { machinesActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { userSelectors } from 'src/selectors'

import loadAllMachinesLocal from './loadAllMachinesLocal'

const loadAllMachines = () => async (dispatch, getState) => {
  const state = getState()
  const isLogged = userSelectors.getIsLogged(state)

  if (isLogged) {
    const params = {
      itemsPerPage: 1000,
      page: 0,
      sortBy: 'addedOn',
      sortDescending: true,
    }

    const { value, error } = await to(machine.loadMachines(params))

    if (value) {
      const { isSuccess } = value.data
      if (isSuccess) {
        dispatch(machinesActions.loadAllMachinesSuccess(value.data))
      } else {
        dispatch(machinesActions.loadAllMachinesFailure())
      }
    }

    if (error) {
      dispatch(machinesActions.loadAllMachinesFailure())
      dispatch(notifyThunks.somethingError())
    }
  } else {
    dispatch(loadAllMachinesLocal())
  }
}

export default loadAllMachines
