import { prepareCartSyncData } from 'src/services'
import { cart } from 'src/rest'
import { notifyThunks, machinesThunks } from 'src/thunks'
import { cartActions } from 'src/actions'
import { removeLocalCart } from 'src/storage'
import { to } from 'src/lib'

import loadUserCart from './loadUserCart'

const syncCart = (localCart, isReplace = false) => async (dispatch) => {
  dispatch(cartActions.syncCartStart({ isRefresh: false }))

  const payload = prepareCartSyncData(localCart)
  const { value, error } = isReplace ? await to(cart.replaceCart(payload)) : await to(cart.syncCart(payload))

  if (value) {
    const { isSuccess } = value.data
    if (isSuccess) {
      dispatch(cartActions.resetCart())
      dispatch(cartActions.syncCartSuccess())
      dispatch(machinesThunks.loadAllMachines())
      removeLocalCart()
    } else {
      dispatch(cartActions.resetCart())
      dispatch(cartActions.syncCartFailure())
      dispatch(notifyThunks.somethingError())
    }
    dispatch(loadUserCart(false))
  }

  if (error) {
    dispatch(cartActions.syncCartFailure())
    dispatch(notifyThunks.somethingError())
  }
}

export default syncCart
