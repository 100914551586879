import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import styles from './styles.module.scss'

const LoaderRaw = (props) => {
  const { hasText, width, height, skin } = props
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <div className={cn(styles.wheel, styles[skin])} style={{ width: width + 'px', height: height + 'px' }} />
      {hasText && <div className={cn(styles.text, styles[`${skin}Text`])}>{t('common:loading') + '...'}</div>}
    </div>
  )
}

LoaderRaw.propTypes = {
  hasText: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  skin: PropTypes.string,
}

LoaderRaw.defaultProps = {
  width: 20,
  height: 20,
  skin: 'dark',
}

const Loader = LoaderRaw
export default memo(Loader)
