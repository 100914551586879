import React, { memo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FormLayout from 'src/forms/FormLayout'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'

import Modal from '../Modal'

import { useMappedActions } from './binding'
import validate from './validate'
import styles from './styles.module.scss'

const ChangePasswordModalRaw = (props) => {
  const { onClose } = props
  const { t } = useTranslation()
  const actions = useMappedActions()

  const onSubmit = useCallback(
    async (values) => {
      const errors = await actions.changePassword(values)

      if (errors) {
        return errors
      }

      onClose()
    },
    [actions, onClose],
  )

  return (
    <Modal title={t('common:titles.popup_change_password')} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.save_changes')}
            >
              <InputRow className={styles.inputRow} isLarge>
                <span className={styles.label}>{t('profile:info.old_password') + '*'}</span>
                <InputWithForm type="password" name="currentPassword" isSimple />
              </InputRow>
              <InputRow className={styles.inputRow} isLarge>
                <span className={styles.label}>{t('profile:info.password') + '*'}</span>
                <InputWithForm type="password" name="newPassword" isSimple />
              </InputRow>
              <InputRow className={styles.inputRow} isLarge>
                <span className={styles.label}>{t('profile:info.confirm_password') + '*'}</span>
                <InputWithForm type="password" name="confirmPassword" isSimple />
              </InputRow>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

ChangePasswordModalRaw.propTypes = {
  onClose: PropTypes.func,
}

export const ChangePasswordModal = ChangePasswordModalRaw
export default memo(ChangePasswordModal)
