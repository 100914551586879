import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useTranslation, Trans } from 'react-i18next'

import FormLayout from 'src/forms/FormLayout'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'
import CheckboxWithForm from 'src/forms/CheckboxWithForm'

import Modal from '../Modal'

import { useMappedActions } from './bindings'
import validate from './validate'
import styles from './styles.module.scss'

const RegModalRaw = (props) => {
  const { onClose } = props
  const actions = useMappedActions()
  const { t } = useTranslation()

  const onSubmit = useCallback(
    async (values) => {
      const errors = await actions.createUser(values)

      if (errors) return errors

      onClose()
    },
    [actions, onClose],
  )

  return (
    <Modal title={t('common:titles.popup_reg')} onClose={onClose} dialogStyles={styles.dialog} isClosable={false}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine, values }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              invalid={!values.acceptDocuments}
              btnText={t('common:buttons.register')}
            >
              <div className={styles.container}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.title}>{t('profile:info.company_title')}</div>
                    <InputRow isLarge>
                      <InputWithForm label={t('profile:info.company_name') + '*'} name="companyName" />
                    </InputRow>
                    <InputRow isLarge>
                      <InputWithForm label={t('profile:info.company_address')} name="companyAddress" />
                    </InputRow>
                    <InputRow isLarge>
                      <InputWithForm type="number" label={t('profile:info.company_phone')} name="companyPhone" />
                    </InputRow>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.title}>{t('profile:info.contact_title')}</div>
                    <InputRow isLarge>
                      <InputWithForm label={t('profile:info.first_name') + '*'} name="firstName" />
                    </InputRow>
                    <InputRow isLarge>
                      <InputWithForm label={t('profile:info.last_name') + '*'} name="lastName" />
                    </InputRow>
                    <InputRow isLarge>
                      <InputWithForm type="number" label={t('profile:info.phone') + '*'} name="phone" />
                    </InputRow>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.title}>{t('profile:info.login_title')}</div>
                    <InputRow isLarge>
                      <InputWithForm label={t('profile:info.email') + '*'} name="email" />
                    </InputRow>
                    <InputRow isLarge>
                      <CheckboxWithForm
                        label={
                          <Trans i18nKey="profile:info.acceptDocuments">
                            I accept
                            <NavLink to="/terms-and-conditions" target="_blank">
                              1
                            </NavLink>
                            and
                            <NavLink to="/privacy-policy" target="_blank">
                              2
                            </NavLink>
                          </Trans>
                        }
                        name="acceptDocuments"
                        id="conditions"
                      />
                    </InputRow>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.title}></div>
                    <InputRow className={styles.inputPass} isLarge>
                      <InputWithForm label={t('profile:info.password') + '*'} type="password" name="password" />
                    </InputRow>
                    <InputRow isLarge>
                      <InputWithForm
                        label={t('profile:info.confirm_password') + '*'}
                        type="password"
                        name="confirmPassword"
                      />
                    </InputRow>
                  </div>
                </div>
              </div>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

RegModalRaw.propTypes = {
  onClose: PropTypes.func,
}

export const RegModal = RegModalRaw
export default memo(RegModal)
