import React, { memo } from 'react'

import styles from './styles.module.scss'

const TypographyRaw = (props) => {
  const { children } = props
  return <div className={styles.static}>{children}</div>
}

export const Typography = TypographyRaw
export default memo(Typography)
