import { cart } from 'src/rest'
import { cartActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { userSelectors } from 'src/selectors'
import { prepareErrors } from 'src/services'

import updateCartLocal from './updateCartLocal'

const updatePart = (requestPartId, data, localParam) => async (dispatch, getState) => {
  const isLogged = userSelectors.getIsLogged(getState())

  if (isLogged) {
    const { value, error } = await to(cart.updatePart(requestPartId, data))

    if (value) {
      const { isSuccess } = value.data

      if (isSuccess) {
        dispatch(cartActions.updatePartSuccess())
      } else {
        const errorParams = prepareErrors({ data: value.data })

        dispatch(notifyThunks.serverError(errorParams))
        dispatch(cartActions.updatePartFailure())
      }
    }

    if (error) {
      const errorParams = prepareErrors({ error })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(cartActions.updatePartFailure())
    }
  } else {
    dispatch(updateCartLocal({ type: 'editInfo', param: { ...localParam, partId: requestPartId, ...data } }))
  }
}

export default updatePart
