import { manager } from 'src/rest'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { prepareErrors } from 'src/services'

const deletePart = (reqId) => async (dispatch) => {
  const { value, error } = await to(manager.deletePart(reqId))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(managerActions.deletePartSuccess())
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(managerActions.deletePartFailure())
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })

    dispatch(notifyThunks.serverError(errorParams))
    dispatch(managerActions.deletePartFailure())
  }
}

export default deletePart
