import { configure } from 'src/rest'
import { getBaseUrl } from 'src/services'

export default () => {
  configure({
    baseURL: getBaseUrl(),
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
