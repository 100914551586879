import React, { useState, useRef, useCallback, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { usePrevious } from 'src/hooks'

import styles from './styles.module.scss'

const CounterRaw = (props) => {
  const { label, style, id, name, disabled, maxLength, hasError, onChange, min, max, value, ...inputProps } = props

  const [countValue, setCount] = useState(value)
  const inputRef = useRef(null)
  const prevValue = usePrevious(value)

  useEffect(() => {
    if (prevValue !== value) {
      setCount(value)
      onChange(value)
    }
  }, [prevValue, value, onChange])

  const onInputChange = useCallback(
    (event) => {
      const value = event.target.value
      onChange && onChange(value)
    },
    [onChange],
  )

  const decrement = useCallback(() => {
    if (countValue <= min) {
      return
    }

    setCount(countValue - 1)
    onChange && onChange(countValue - 1)
  }, [onChange, countValue, min])

  const increment = useCallback(() => {
    if (countValue >= max) {
      return
    }

    setCount(countValue + 1)
    onChange && onChange(countValue + 1)
  }, [onChange, countValue, max])

  return (
    <div className={styles.inputWrap}>
      <button
        type="button"
        onClick={decrement}
        className={cn(styles.btn, style, {
          [styles.disabled]: countValue <= 1,
          [styles.disabled]: countValue <= min,
        })}
      >
        -
      </button>
      <input
        {...inputProps}
        value={value}
        ref={inputRef}
        disabled={disabled}
        name={name}
        type="number"
        id={id || name}
        maxLength={maxLength}
        readOnly={'readonly'}
        onChange={onInputChange}
        className={cn(styles.input, style, {
          [styles.inputError]: hasError,
        })}
      />
      <button
        type="button"
        onClick={increment}
        className={cn(styles.btn, style, {
          [styles.disabled]: countValue >= max,
        })}
      >
        +
      </button>
    </div>
  )
}

CounterRaw.defaultProps = {
  hasError: false,
  disabled: false,
  max: Infinity,
  min: 1,
}

CounterRaw.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
}

const Counter = CounterRaw
export default memo(Counter)
