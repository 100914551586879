import { createAction } from 'redux-actions'

const prefix = 'COMPONENTS:'

export const loadComponentsSuccess = createAction(prefix + 'LOAD_COMPONENTS_SUCCESS')
export const loadComponentsFailure = createAction(prefix + 'LOAD_COMPONENTS_FAILURE')

export const loadComponentSuccess = createAction(prefix + 'LOAD_COMPONENT_SUCCESS')
export const loadComponentFailure = createAction(prefix + 'LOAD_COMPONENT_FAILURE')

export const updateComponentSuccess = createAction(prefix + 'UPDATE_COMPONENT_SUCCESS')
export const updateComponentFailure = createAction(prefix + 'UPDATE_COMPONENT_FAILURE')
