import { auth } from 'src/rest'
import { authActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { prepareErrors } from 'src/services'

const recoveryPassword = (data) => async (dispatch, getState) => {
  const { value, error } = await to(auth.recoveryPassword(data))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(authActions.recoveryPasswordSuccess())
      dispatch(notifyThunks.recoveryPasswordSuccess())
      return true
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(authActions.recoveryPasswordFailure())
      return false
    }
  }
  if (error) {
    const errorParams = prepareErrors({ error })

    dispatch(notifyThunks.serverError(errorParams))
    dispatch(authActions.recoveryPasswordFailure())
    return false
  }
}

export default recoveryPassword
