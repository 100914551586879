import { handleActions } from 'redux-actions'

import { machinesActions, authActions } from 'src/actions'

export const initialState = 0

const reducerMap = {
  [machinesActions.loadAllMachinesSuccess]: (state, { payload }) => payload.totalItems,
  [authActions.logoutSuccess]: () => initialState,
}

export default handleActions(reducerMap, initialState)
