import { useMapState, useMapActions } from 'src/hooks'
import { modelsSelectors, requestSelectors } from 'src/selectors'
import { managerThunks } from 'src/thunks'

export const useMappedState = (type) => {
  return useMapState((state) => {
    return {
      componentTypes: modelsSelectors.getComponentTypesOptions(state, type),
      parts: modelsSelectors.getParts(state, type),
      requestId: requestSelectors.getRequestId(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    addPart: managerThunks.addPart,
    assignMachinePart: managerThunks.assignMachinePart,
  })
}
