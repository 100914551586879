import React, { memo } from 'react'
import { Switch } from 'react-router-dom'

import Route from './components/RouteWithSubRoutes'

const RouterRaw = (props) => {
  const { routes, routeProps } = props
  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} {...route} routeProps={routeProps} />
      ))}
    </Switch>
  )
}

export const Router = RouterRaw
export default memo(Router)
