const PARAMS = {
  port: '',
  protocol: '',
  host: '',
}

export const configureBaseUrl = (params) => {
  const { port, protocol, host } = params

  if (port) PARAMS.port = port
  if (protocol) PARAMS.protocol = protocol
  if (host) PARAMS.host = host
}

export const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    const { hostname, protocol } = window.location

    return `${protocol}//${hostname}:${PARAMS.port}`
  }

  return `${PARAMS.protocol}://${PARAMS.host}:${PARAMS.port}`
}

export default getBaseUrl
