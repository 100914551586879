import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './components/App'

import { configureRest, configureI18n } from './configures'
import { configureBaseUrl } from './services'
import { appThunks, commonThunks } from './thunks'
import { settingsSelectors } from './selectors'
import store from './store'
import './index.css'

const render = () => {
  store
    .dispatch(commonThunks.loadSettings())
    .then(() => {
      const state = store.getState()
      const baseUrlConfig = settingsSelectors.getBaseUrlConfig(state)

      configureBaseUrl(baseUrlConfig)
      configureRest()

      store
        .dispatch(appThunks.appInit())
        .then(() => {
          ReactDOM.render(
            <React.StrictMode>
              <Provider store={store}>
                <Suspense fallback={null}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </Suspense>
              </Provider>
            </React.StrictMode>,
            document.getElementById('root'),
          )
        })
        .catch((err) => {
          console.log('Something went wrong!', err)
        })
    })
    .catch((err) => console.log('Load SETTINGS error!', err))
}

configureI18n(render)
