import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { ReactComponent as SettingsIcon } from 'src/assets/images/icons/settings.svg'

import styles from './styles.module.scss'

const PanelRaw = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.profile}>
      <NavLink className={styles.btn} to="/admin">
        <div className={styles.inner}>
          <span className={styles.icon}>
            <SettingsIcon />
          </span>
          <span className={styles.text}>{t('profile:admin')}</span>
        </div>
      </NavLink>
    </div>
  )
}

export const Panel = PanelRaw
export default memo(Panel)
