const prepareMachines = (items) => {
  return items.reduce((acc, curr) => {
    if (curr) {
      const { model } = curr

      if (acc[model]) {
        acc[model] = [...acc[model], curr]
      } else {
        acc[model] = [curr]
      }
    }

    return acc
  }, {})
}

export default prepareMachines
