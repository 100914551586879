import { docs } from 'src/rest'
import { to } from 'src/lib'
import { userActions } from 'src/actions'

const acceptDocument = (documentType) => async (dispatch) => {
  const { value, error } = await to(docs.acceptDocument(documentType))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(userActions.acceptDocumentSuccess())
      return true
    } else {
      dispatch(userActions.acceptDocumentFailure())
      return false
    }
  }

  if (error) {
    dispatch(userActions.acceptDocumentFailure())
    return false
  }
}

export default acceptDocument
