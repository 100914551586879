import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Checkbox from '../Checkbox'

import styles from './styles.module.scss'

const CheckboxWithFormRaw = ({ name, ...props }) => {
  return (
    <Field name={name} type="checkbox">
      {({ input, meta }) => {
        const hasError = (meta.error || meta.submitError) && meta.touched
        return (
          <div className={styles.field}>
            <Checkbox {...input} {...props} hasError={hasError} />
          </div>
        )
      }}
    </Field>
  )
}

CheckboxWithFormRaw.propTypes = {
  name: PropTypes.string.isRequired,
}

const CheckboxWithForm = CheckboxWithFormRaw
export default memo(CheckboxWithForm)
