import React, { memo, useCallback, useLayoutEffect, useRef } from 'react'
import _debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search.svg'
import { ReactComponent as FilterIcon } from 'src/assets/images/icons/filter.svg'
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close-filter.svg'

import styles from './styles.module.scss'

const SearchRaw = (props) => {
  const { onChange, value = '', onClickFilter, isFilterShowed = false } = props
  const { t } = useTranslation()
  const defaultValue = useRef(value).current
  const cachedOnChange = useRef(onChange).current
  const hasFilter = Boolean(onClickFilter)

  const debounceValue = useCallback(_debounce((value) => onChange && onChange(value), 300))
  const onChangeInput = useCallback((e) => debounceValue(e.target.value), [debounceValue])

  useLayoutEffect(() => {
    if (defaultValue) {
      cachedOnChange && cachedOnChange(defaultValue)
    }
  }, [defaultValue, cachedOnChange])

  return (
    <div className={styles.search}>
      <div
        className={cn(styles.icon, {
          [styles.iconLeft]: hasFilter,
        })}
      >
        <SearchIcon />
      </div>
      {hasFilter && (
        <button className={styles.filter} onClick={onClickFilter} type="button">
          {isFilterShowed ? <CloseIcon /> : <FilterIcon />}
        </button>
      )}
      <input
        className={cn(styles.input, {
          [styles.inputFilter]: hasFilter,
        })}
        type="text"
        maxLength={255}
        onChange={onChangeInput}
        placeholder={t('common:search')}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export const Search = SearchRaw
export default memo(Search)
