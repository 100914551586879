import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { useOnClickOutside, usePrevious } from 'src/hooks'
import Search from 'src/components/Search'

import styles from './styles.module.scss'

const SelectRaw = (props) => {
  const { options, value, defaultValue = '', placeholder, onChange, hasSearch = false, hasError } = props
  const { t } = useTranslation()

  const dropdownRef = useRef(null)
  const [selectValue, setSelectValue] = useState(value || defaultValue)
  const [searchValue, setSearchValue] = useState('')
  const [isOpened, setIsOpened] = useState(false)
  const prevValue = usePrevious(selectValue)

  useOnClickOutside(dropdownRef, () => {
    setSearchValue('')
    setIsOpened(false)
  })

  const onSelectValue = useCallback(
    (value) => {
      setSelectValue(value)
      onChange(value)
    },
    [onChange],
  )

  const onClickOption = useCallback(
    (value) => {
      onSelectValue(value)
      setIsOpened(false)
      setSearchValue('')
    },
    [onSelectValue],
  )

  const onClickHeader = useCallback(() => {
    setIsOpened(!isOpened)
  }, [isOpened])

  const renderDropDownValue = useMemo(() => {
    if (selectValue) {
      const option = options.find((option) => option.value === selectValue)

      return <div className={styles.headerText}>{option && option.label}</div>
    }

    return <div className={styles.headerPlaceholder}>{placeholder || t('common:select_value')}</div>
  }, [selectValue, options, placeholder, t])

  const renderDropDownHeader = useMemo(() => {
    return (
      <div
        className={cn(styles.header, {
          [styles.headerError]: hasError,
        })}
        onClick={onClickHeader}
      >
        {renderDropDownValue}
        <span
          className={cn(styles.arrow, {
            [styles.opened]: isOpened,
          })}
        />
      </div>
    )
  }, [isOpened, onClickHeader, renderDropDownValue, hasError])

  const onChangeSearch = useCallback(
    (value) => {
      setSearchValue(value)
    },
    [setSearchValue],
  )

  const renderDropDownBox = useMemo(() => {
    const filteredOptions = options.filter((elem) => {
      if (elem && elem.label) {
        return `${elem.label}`.toUpperCase().includes(searchValue.toUpperCase())
      }
      return true
    })

    const currOptions = hasSearch ? filteredOptions : options

    return (
      <div className={styles.box}>
        {hasSearch && (
          <div className={styles.search}>
            <Search onChange={onChangeSearch} value={''} />
          </div>
        )}
        <div
          className={cn(styles.options, {
            [styles.optionsSearch]: hasSearch,
          })}
        >
          {currOptions.map((option) => {
            const { label, value } = option
            const isSelected = value === selectValue

            return (
              <button
                key={`dropdown_${value}`}
                className={cn(styles.option, {
                  [styles.optionSelected]: isSelected,
                })}
                onClick={() => onClickOption(value)}
              >
                {label}
              </button>
            )
          })}
        </div>
      </div>
    )
  }, [options, selectValue, onClickOption, hasSearch, onChangeSearch, searchValue])

  const renderDropDown = useMemo(() => {
    return (
      <div ref={dropdownRef} className={styles.dropdown}>
        {renderDropDownHeader}
        {isOpened && renderDropDownBox}
      </div>
    )
  }, [renderDropDownHeader, renderDropDownBox, isOpened])

  useEffect(() => {
    if (!defaultValue && prevValue && !value) setSelectValue('')
  }, [value, prevValue, defaultValue])

  if (options) {
    return renderDropDown
  }

  return null
}

SelectRaw.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export const Select = SelectRaw
export default memo(Select)
