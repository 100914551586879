import { useMapState, useMapActions } from 'src/hooks'
import { componentsThunks, modelsThunks } from 'src/thunks'

export const useMappedState = () => {
  return useMapState((state) => {
    return {}
  })
}

export const useMappedActions = () => {
  return useMapActions({
    updateComponent: componentsThunks.updateComponent,
    loadModels: modelsThunks.loadModels,
  })
}
