import { createAction } from 'redux-actions'

const prefix = 'ADMINS:'

export const loadAdminsSuccess = createAction(prefix + 'LOAD_SUCCESS')
export const loadAdminsFailure = createAction(prefix + 'LOAD_FAILURE')

export const createAdminSuccess = createAction(prefix + 'CREATE_SUCCESS')
export const createAdminFailure = createAction(prefix + 'CREATE_FAILURE')

export const editAdminSuccess = createAction(prefix + 'EDIT_SUCCESS')
export const editAdminFailure = createAction(prefix + 'EDIT_FAILURE')

export const deleteAdminSuccess = createAction(prefix + 'DELETE_SUCCESS')
export const deleteAdminFailure = createAction(prefix + 'DELETE_FAILURE')
