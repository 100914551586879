export const LOGIN_MODAL = 'LOGIN_MODAL'
export const REG_MODAL = 'REG_MODAL'
export const PERSONAL_INFO_MODAL = 'PERSONAL_INFO_MODAL'
export const RESET_PASS_MODAL = 'RESET_PASS_MODAL'
export const RECOVERY_PASS_MODAL = 'RECOVERY_PASS_MODAL'
export const RULES_MODAL = 'RULES_MODAL'
export const CHANGE_EMAIL_MODAL = 'CHANGE_EMAIL_MODAL'
export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL'
export const CU_MACHINE_MODAL = 'CU_MACHINE_MODAL'
export const ADD_USER_MACHINE_MODAL = 'ADD_USER_MACHINE_MODAL'
export const ADD_USER_PART_MODAL = 'ADD_USER_PART_MODAL'
export const CONFIRM_MODAL = 'CONFIRM_MODAL'
export const PART_MODAL = 'PART_MODAL'
export const DIDNT_FIND = 'DIDNT_FIND'
export const EDIT_PART_MODAL = 'EDIT_PART_MODAL'
export const EDIT_COMPONENT_MODAL = 'EDIT_COMPONENT_MODAL'
export const CU_ADMIN_MODAL = 'CU_ADMIN_MODAL'
export const CU_USER_MACHINE = 'CU_USER_MACHINE'
