import { handleActions, combineActions } from 'redux-actions'

import { managerActions } from 'src/actions'

export const initialState = false

const reducerMap = {
  [managerActions.releaseRequestStart]: () => true,
  [combineActions(managerActions.releaseRequestSuccess, managerActions.releaseRequestFailure)]: () => false,
}

export default handleActions(reducerMap, initialState)
