import { instance } from 'src/rest'
import { modalConstants } from 'src/constants'
import { authThunks, modalThunks, notifyThunks } from 'src/thunks'

const userCondition = (errorParams, originalRequest) => async (dispatch) => {
  const { errorData } = errorParams
  if (errorData && Array.isArray(errorData)) {
    const [first, second] = errorData

    const { isAccepted } = await dispatch(
      modalThunks.showModal(modalConstants.RULES_MODAL, { type: first, isNeedAccept: true }),
    )

    if (isAccepted) {
      if (second) {
        const { isAccepted } = await dispatch(
          modalThunks.showModal(modalConstants.RULES_MODAL, { type: second, isNeedAccept: true }),
        )

        if (!isAccepted) {
          dispatch(authThunks.logout())
          dispatch(notifyThunks.unacceptedDocumentsWarning())
          return Promise.reject(errorParams)
        } else {
          return instance(originalRequest)
        }
      } else {
        return instance(originalRequest)
      }
    } else {
      dispatch(authThunks.logout())
      dispatch(notifyThunks.unacceptedDocumentsWarning())
      return Promise.reject(errorParams)
    }
  }

  return Promise.reject(errorParams)
}

export default userCondition
