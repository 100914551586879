import React, { memo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import BaseButton from 'src/components/BaseButton'
import FullLoader from 'src/components/FullLoader'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'

import Modal from '../Modal'

import validate from './validate'
import styles from './styles.module.scss'
import { useMappedActions } from './binding'

const LoginModalRaw = (props) => {
  const { onClose } = props
  const actions = useMappedActions()
  const { t } = useTranslation()

  const onSubmit = useCallback(
    async (values) => {
      const accessToken = await actions.authUser(values)

      if (accessToken) {
        actions.login(accessToken)
        onClose()
      }
    },
    [actions, onClose],
  )

  return (
    <Modal title={t('common:titles.popup_login')} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              {submitting && <FullLoader hasText />}
              <div>
                <InputRow>
                  <InputWithForm label={t('profile:info.email') + '*'} name="email" />
                </InputRow>
                <InputRow>
                  <InputWithForm label={t('profile:info.password') + '*'} type="password" name="password" />
                </InputRow>
              </div>
              <div className={styles.forgot}>
                <button className={styles.forgotBtn} type="button" onClick={() => onClose({ type: 'reset_pass' })}>
                  {t('common:forgot_pass')}
                </button>
              </div>
              <BaseButton type="submit" text={t('common:buttons.sign_in')} disabled={submitting || pristine} isFull />
              <div className={styles.account}>{t('common:dont_have_acc')}</div>
              <BaseButton
                type="button"
                text={t('common:buttons.register')}
                skin="border"
                onClick={() => onClose({ type: 'open_reg' })}
                isFull
              />
            </form>
          )
        }}
      />
    </Modal>
  )
}

LoginModalRaw.propTypes = {
  onClose: PropTypes.func,
}

export const LoginModal = LoginModalRaw
export default memo(LoginModal)
