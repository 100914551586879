import { gerLocalCart, updateCart } from 'src/storage'
import { deleteLocalPart, editCommentLocal, editSNLocal } from 'src/services'

import loadUserCartLocal from './loadUserCartLocal'

const updateCartLocal = ({ type, param = {} }) => async (dispatch) => {
  const cart = await gerLocalCart()

  if (cart) {
    switch (type) {
      case 'delete': {
        const nextCart = deleteLocalPart(cart, param)
        await updateCart(nextCart)
        break
      }
      case 'editInfo': {
        const nextCart = editCommentLocal(cart, param)
        await updateCart(nextCart)
        break
      }
      case 'editSN': {
        const nextCart = editSNLocal(cart, param)
        await updateCart(nextCart)
        break
      }
      default: {
        break
      }
    }
  }

  dispatch(loadUserCartLocal())
}

export default updateCartLocal
