import { useMapState, useMapActions } from 'src/hooks'
import { partsThunks, filesThunks, notifyThunks } from 'src/thunks'

export const useMappedState = () => {
  return useMapState((state) => {
    return {}
  })
}

export const useMappedActions = () => {
  return useMapActions({
    loadPart: partsThunks.loadPart,
    updatePart: partsThunks.updatePart,
    uploadImage: filesThunks.uploadImage,
    uploadImageIsBig: notifyThunks.uploadImageIsBig,
  })
}
