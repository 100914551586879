import { useMapState, useMapActions } from 'src/hooks'
import { modelsSelectors } from 'src/selectors'
import { managerThunks } from 'src/thunks'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      typesOptions: modelsSelectors.getTypesOptions(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    editUserMachine: managerThunks.editUserMachine,
    addNewUserMachine: managerThunks.addNewUserMachine,
  })
}
