import React, { memo } from 'react'

import Loader from 'src/components/Loader'

import styles from './styles.module.scss'

const FullLoaderRaw = (props) => {
  return (
    <div className={styles.loader}>
      <Loader {...props} />
    </div>
  )
}

export const FullLoader = FullLoaderRaw
export default memo(FullLoader)
