import { handleActions } from 'redux-actions'

import { requestsActions } from 'src/actions'

export const initialState = null

const reducerMap = {
  [requestsActions.loadRequestSuccess]: (state, { payload }) => payload.number,
  [requestsActions.cleanRequest]: () => initialState,
}

export default handleActions(reducerMap, initialState)
