import React, { memo, Fragment, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ReactComponent as UserIcon } from 'src/assets/images/icons/user.svg'
import { ReactComponent as LogoutIcon } from 'src/assets/images/icons/logout.svg'

import Loader from 'src/components/Loader'

import styles from './styles.module.scss'

const LoginRaw = (props) => {
  const { onClickLogin, isLogged, isLoggingIn } = props
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    onClickLogin && onClickLogin()
  }, [onClickLogin])

  const renderContent = useMemo(() => {
    if (isLogged) {
      return (
        <span className={styles.iconLogout}>
          <LogoutIcon />
        </span>
      )
    }

    return (
      <Fragment>
        <span className={styles.iconLogin}>
          <UserIcon />
        </span>
        <span className={styles.text}>{t('profile:login')}</span>
      </Fragment>
    )
  }, [isLogged, t])

  return (
    <div className={styles.profile}>
      {isLoggingIn ? (
        <div className={styles.loader}>
          <div className={styles.loaderText}>{t('common:logging') + '...'}</div>
          <Loader skin="light" width={18} height={18} />
        </div>
      ) : (
        <button className={styles.btn} onClick={onClick} type="button">
          <div className={styles.inner}>{renderContent}</div>
        </button>
      )}
    </div>
  )
}

LoginRaw.propTypes = {
  onClickLogin: PropTypes.func,
  isLogged: PropTypes.bool,
  isLoggingIn: PropTypes.bool,
}

export const Login = LoginRaw
export default memo(Login)
