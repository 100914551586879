import { useMapState, useMapActions } from 'src/hooks'
import { userSelectors } from 'src/selectors'
import { userThunks } from 'src/thunks'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      personalInfo: userSelectors.getPersonalInfo(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    updateUser: userThunks.updateUser,
  })
}
