import { useMapState, useMapActions } from 'src/hooks'
import { modelsSelectors, requestSelectors } from 'src/selectors'
import { managerThunks } from 'src/thunks'

export const useMappedState = (type) => {
  return useMapState((state) => {
    return {
      models: modelsSelectors.getModels(state),
      typesOptions: modelsSelectors.getTypesOptions(state),
      requestId: requestSelectors.getRequestId(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    addPart: managerThunks.addPart,
  })
}
