import i18n from 'i18next'

import { regexpConstants } from 'src/constants'

export default (values) => {
  const errors = {}

  if (!values.name) {
    errors.name = i18n.t('errors:fields.required_field')
  }

  if (!values.email) {
    errors.email = i18n.t('errors:fields.required_field')
  } else if (!regexpConstants.EMAIL.test(values.email)) {
    errors.email = i18n.t('errors:fields.invalid_email')
  }

  return errors
}
