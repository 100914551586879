import _omit from 'lodash/omit'

import { machine } from 'src/rest'
import { to } from 'src/lib'
import { machinesActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { compareMachines } from 'src/services'
import { removeAllMachines } from 'src/storage'

import createMachine from './createMachine'
import loadAllMachines from './loadAllMachines'

const syncMachines = (localMachines) => async (dispatch) => {
  const params = {
    itemsPerPage: 1000,
    page: 0,
    sortBy: 'addedOn',
    sortDescending: true,
  }

  const { value, error } = await to(machine.loadMachines(params))

  if (value) {
    const { isSuccess } = value.data
    if (isSuccess) {
      const newMachines = compareMachines(value.data.items, localMachines)
      const requests = []

      newMachines.forEach((machine) => {
        requests.push(dispatch(createMachine(_omit(machine, ['id', 'time']))))
      })

      await Promise.all(requests)

      dispatch(loadAllMachines())
      removeAllMachines()
    } else {
      dispatch(machinesActions.loadAllMachinesFailure())
    }
  }

  if (error) {
    dispatch(machinesActions.loadAllMachinesFailure())
    dispatch(notifyThunks.somethingError())
  }
}

export default syncMachines
