import { createAction } from 'redux-actions'

const prefix = 'CART:'

export const loadCartStart = createAction(prefix + 'LOAD_CART_START')
export const loadCartSuccess = createAction(prefix + 'LOAD_CART_SUCCESS')
export const loadCartFailure = createAction(prefix + 'LOAD_CART_FAILURE')

export const addPartSuccess = createAction(prefix + 'ADD_PART_SUCCESS')
export const addPartFailure = createAction(prefix + 'ADD_PART_FAILURE')

export const updatePartSuccess = createAction(prefix + 'UPDATE_PART_SUCCESS')
export const updatePartFailure = createAction(prefix + 'UPDATE_PART_FAILURE')

export const deletePartSuccess = createAction(prefix + 'DELETE_PART_SUCCESS')
export const deletePartFailure = createAction(prefix + 'DELETE_PART_FAILURE')

export const cartSubmitSuccess = createAction(prefix + 'CART_SUBMIT_SUCCESS')
export const cartSubmitFailure = createAction(prefix + 'CART_SUBMIT_FAILURE')

export const updatePartsSuccess = createAction(prefix + 'UPDATE_PARTS_SUCCESS')
export const updatePartsFailure = createAction(prefix + 'UPDATE_PARTS_FAILURE')

export const syncCartStart = createAction(prefix + 'SYNC_CART_START')
export const syncCartSuccess = createAction(prefix + 'SYNC_CART_SUCCESS')
export const syncCartFailure = createAction(prefix + 'SYNC_CART_FAILURE')

export const resetCart = createAction(prefix + 'RESET_CART')
