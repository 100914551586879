import React, { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'

import LangTabs from 'src/indyComponents/LangTabs'

import FormLayout from 'src/forms/FormLayout'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'

import Modal from '../Modal'

import { useMappedActions } from './bindings'
import styles from './styles.module.scss'

const EditComponentModalRaw = (props) => {
  const { onClose, machineType, component, translations } = props
  const { t } = useTranslation()
  const actions = useMappedActions()
  const [tabsData, setTabsData] = useState({ translations })

  const onChangeTabData = useCallback(
    (field, lang, value) => {
      setTabsData({ ...tabsData, [field]: { ...tabsData[field], [lang]: value } })
    },
    [tabsData],
  )

  const onSubmit = useCallback(async () => {
    const nextValues = { ...tabsData }
    const error = await actions.updateComponent(machineType, component, nextValues)
    if (error) {
      return error
    }
    onClose(true)
  }, [actions, onClose, machineType, component, tabsData])

  return (
    <Modal title={t('common:titles.popup_edit_component')} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        initialValues={{
          machineType,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <FormLayout onSubmit={handleSubmit} submitting={submitting} btnText={t('common:buttons.save_changes')}>
              <InputRow>
                <span className={styles.label}>{t('component:info.class')}</span>
                <InputWithForm name="machineType" isSimple disabled />
              </InputRow>

              <LangTabs
                data={tabsData}
                filedField="translations"
                translation="component"
                onChangeTabData={onChangeTabData}
              />
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

export const EditComponentModal = EditComponentModalRaw
export default memo(EditComponentModal)
