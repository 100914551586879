import qs from 'query-string'

import { instance } from '../axiosInstance'

export const loadMachines = (params) => {
  return instance({
    method: 'get',
    url: `/usermachines${params ? '?' + qs.stringify(params, { skipNull: true }) : ''}`,
  })
}

export const createMachine = (data) => {
  return instance({
    method: 'post',
    url: `/usermachines`,
    data,
  })
}

export const editMachine = (data, id) => {
  return instance({
    method: 'put',
    url: `/usermachines/${id}`,
    data,
  })
}

export const delMachine = (id) => {
  return instance({
    method: 'delete',
    url: `/usermachines/${id}`,
  })
}
