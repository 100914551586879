import React, { memo, useMemo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { getBaseUrl } from 'src/services'

import FullLoader from 'src/components/FullLoader'
import Counter from 'src/components/Counter'
import BaseButton from 'src/components/BaseButton'
import SelectSNForm from 'src/components/SelectSNForm'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'
import TextareaWithForm from 'src/forms/TextareaWithForm'
import CounterWithForm from 'src/forms/CounterWithForm'

import Modal from '../Modal'

import { useMappedState, useMappedActions } from './bindings'
import styles from './styles.module.scss'

const PartModalRaw = (props) => {
  const { onClose, model, serialNumber, ...part } = props
  const { t, i18n } = useTranslation()
  const { machines } = useMappedState(model)
  const actions = useMappedActions()
  const lang = i18n.language

  const imageUrl = useMemo(() => (part.picture ? `${getBaseUrl()}/${part.picture}` : ''), [part])

  const initialValues = useMemo(() => {
    const values = { machineType: model, amount: 1 }

    if (serialNumber) {
      values.serialNumber = serialNumber
    }

    return values
  }, [model, serialNumber])

  const { name, description, title } = useMemo(() => {
    return {
      name: part.name[lang],
      description: part.description[lang],
      title: `${part.internalNumber} - ${part.name[lang]}`,
    }
  }, [part, lang])

  const onClickSubmit = useCallback(() => {
    document.getElementById('part-form').dispatchEvent(new Event('submit', { cancelable: true }))
  }, [])

  const onSubmit = useCallback(
    async (values) => {
      const errors = await actions.addPart({ values, part, machineType: model })

      if (errors) {
        return errors
      }

      onClose()
    },
    [actions, part, onClose, model],
  )

  return (
    <Modal title={title} onClose={onClose} dialogStyles={styles.dialog}>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.form}>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, form, values }) => {
                return (
                  <form onSubmit={handleSubmit} id="part-form" noValidate>
                    {submitting && <FullLoader hasText />}
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.model') + '*'}</span>
                      <InputWithForm name="machineType" disabled isSimple />
                    </InputRow>
                    <InputRow>
                      <SelectSNForm form={form} values={values} options={machines} />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.comment')}</span>
                      <TextareaWithForm name="userComments" isSimple maxLength={255} />
                    </InputRow>
                    <InputRow>
                      <Counter>
                        <CounterWithForm name="amount" />
                      </Counter>
                    </InputRow>
                  </form>
                )
              }}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.img}>{imageUrl && <img src={imageUrl} alt={name} />}</div>
            {description && <div className={styles.desc}>{description}</div>}
          </div>
        </div>
        <div className={styles.footer}>
          <BaseButton type="button" text={t('common:buttons.add_to_cart')} onClick={onClickSubmit} />
        </div>
      </div>
    </Modal>
  )
}

export const PartModal = PartModalRaw
export default memo(PartModal)
