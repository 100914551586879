import i18n from 'i18next'

export default (values) => {
  const errors = {}

  if (!values.machineType) {
    errors.machineType = i18n.t('errors:fields.required_field')
  }

  if (!values.serialNumber) {
    errors.serialNumber = i18n.t('errors:fields.required_field')
  }

  if (!values.machineComponent) {
    errors.machineComponent = i18n.t('errors:fields.required_field')
  }

  if (!values.partId) {
    errors.partId = i18n.t('errors:fields.required_field')
  }

  if (!values.amount && values.amount > 0) {
    errors.amount = i18n.t('errors:fields.required_field')
  }

  return errors
}
