import React, { memo } from 'react'

import { useMappedState, useMappedActions } from './bindings'

import modalConfig from '../config'

const ModalManagerRaw = () => {
  const { modal } = useMappedState()
  const actions = useMappedActions()

  if (modal.type) {
    const ModalComponent = modalConfig[modal.type]

    return <ModalComponent {...modal.props} onClose={actions.hideModal} />
  }

  return null
}

export const ModalManager = ModalManagerRaw
export default memo(ModalManager)
