const getState = (state) => state.machines

export const getItems = (state) => getState(state).items
export const getTotal = (state) => getState(state).total

export const getItemsByType = (state, type) => {
  const items = getItems(state)
  return items.reduce((acc, curr) => {
    if (type.toLowerCase() === curr.model.toLowerCase()) {
      acc.push(curr)
    }

    return acc
  }, [])
}

export const getAllGroupedSNByModel = (state) => {
  const items = getItems(state)
  return items.reduce((acc, curr) => {
    const { model } = curr

    if (acc[model]) {
      acc[model].push(curr)
    } else {
      acc[model] = [curr]
    }

    return acc
  }, {})
}

export const getMachineBySN = (state, serialNumber) => {
  const items = getItems(state)
  return items.find((item) => item.serialNumber === serialNumber)
}
