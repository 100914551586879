import i18n from 'i18next'

import { cart } from 'src/rest'
import { cartActions } from 'src/actions'
import { notifyThunks, modalThunks } from 'src/thunks'
import { userSelectors } from 'src/selectors'
import { to } from 'src/lib'
import { prepareErrors, prepareMachineTables } from 'src/services'
import { gerLocalCart, removeLocalCart, removeAllMachines } from 'src/storage'
import { modalConstants } from 'src/constants'

import loadUserCartLocal from './loadUserCartLocal'
import syncCart from './syncCart'

const loadUserCart = (isRefresh = false, isSync = false) => async (dispatch, getState) => {
  dispatch(cartActions.loadCartStart({ isRefresh }))

  const isLogged = userSelectors.getIsLogged(getState())
  if (isLogged) {
    const { value, error } = await to(cart.getUserCart())

    if (value) {
      const { isSuccess } = value.data

      if (isSuccess) {
        const { data } = value.data

        if (isSync) {
          const localCart = await gerLocalCart()
          if (localCart) {
            if (data && !data.length) {
              dispatch(syncCart(localCart))
              removeLocalCart()
              removeAllMachines()
              dispatch(loadUserCart(true))
            } else {
              dispatch(
                modalThunks.showModal(modalConstants.CONFIRM_MODAL, {
                  title: i18n.t('common:titles.popup_sync_cart'),
                  description: i18n.t('common:description.sync_cart'),
                }),
              ).then(({ isAccepted }) => {
                dispatch(syncCart(localCart, !isAccepted))
              })
            }
          } else {
            dispatch(cartActions.loadCartSuccess(prepareMachineTables(data)))
          }
        } else {
          dispatch(cartActions.loadCartSuccess(prepareMachineTables(data)))
        }
      } else {
        const errorParams = prepareErrors({ data: value.data })

        dispatch(notifyThunks.serverError(errorParams))
        dispatch(cartActions.loadCartFailure())
      }
    }

    if (error) {
      const errorParams = prepareErrors({ error })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(cartActions.loadCartFailure())
    }
  } else {
    dispatch(loadUserCartLocal())
  }
}

export default loadUserCart
