import { createAction } from 'redux-actions'

const prefix = 'USER:'

export const updateUserSuccess = createAction(prefix + 'UPDATE_USER_SUCCESS')
export const updateUserFailure = createAction(prefix + 'UPDATE_USER_FAILURE')

export const updateUserLangSuccess = createAction(prefix + 'UPDATE_USER_LANG_SUCCESS')
export const updateUserLangFailure = createAction(prefix + 'UPDATE_USER_LANG_FAILURE')

export const getDocumentSuccess = createAction(prefix + 'GET_DOCUMENT_SUCCESS')
export const getDocumentFailure = createAction(prefix + 'GET_DOCUMENT_FAILURE')

export const acceptDocumentSuccess = createAction(prefix + 'ACCEPT_DOCUMENT_SUCCESS')
export const acceptDocumentFailure = createAction(prefix + 'ACCEPT_DOCUMENT_FAILURE')

export const changeEmailSuccess = createAction(prefix + 'CHANGE_EMAIL_SUCCESS')
export const changeEmailFailure = createAction(prefix + 'CHANGE_EMAIL_FAILURE')

export const confirmEmailSuccess = createAction(prefix + 'CONFIRM_EMAIL_SUCCESS')
export const confirmEmailFailure = createAction(prefix + 'CONFIRM_EMAIL_FAILURE')

export const changePasswordSuccess = createAction(prefix + 'CHANGE_PASSWORD_SUCCESS')
export const changePasswordFailure = createAction(prefix + 'CHANGE_PASSWORD_FAILURE')
