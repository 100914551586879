import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const i18nInit = (cb) => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init(
      {
        lng: 'en',
        fallbackLng: 'en',
        debug: false,
        ns: [
          'common',
          'profile',
          'notify',
          'errors',
          'footer',
          'machine',
          'cart',
          'request',
          'filter',
          'part',
          'component',
          'admins',
        ],
        defaultNS: [
          'common',
          'profile',
          'notify',
          'errors',
          'footer',
          'machine',
          'cart',
          'request',
          'filter',
          'part',
          'component',
          'admins',
        ],
        interpolation: {
          escapeValue: false,
        },
        react: {
          wait: true,
        },
      },
      cb,
    )
}
export default i18nInit
