import { admins } from 'src/rest'
import { to } from 'src/lib'
import { adminsActions } from 'src/actions'
import { prepareAdmins } from 'src/services'

const loadAdmins = (params) => async (dispatch) => {
  const { value, error } = await to(admins.loadAdmins(params))

  if (value) {
    const { isSuccess, items, totalItems } = value.data

    if (isSuccess) {
      dispatch(adminsActions.loadAdminsSuccess())
      return {
        items: prepareAdmins(items),
        total: totalItems,
      }
    } else {
      dispatch(adminsActions.loadAdminsFailure())
    }
  }

  if (error) {
    dispatch(adminsActions.loadAdminsFailure())
  }
}

export default loadAdmins
