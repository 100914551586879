import React, { memo, useEffect, useCallback, useRef } from 'react'
import cn from 'classnames'

import { ReactComponent as CrossIcon } from 'src/assets/images/icons/notify_cross.svg'
import { ReactComponent as SuccessIcon } from 'src/assets/images/icons/notify_success.svg'
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/notify_error.svg'
import { ReactComponent as WarningIcon } from 'src/assets/images/icons/notify_warning.svg'

import { commonConstants } from 'src/constants'

import styles from './styles.module.scss'

const Icons = {
  success: SuccessIcon,
  error: ErrorIcon,
  warning: WarningIcon,
}

const NotifyRaw = (props) => {
  const { title, desc, type, id, onHide } = props
  const Icon = Icons[type]
  const timer = useRef(null)

  const onClickCross = useCallback(() => {
    onHide && onHide(id)
  }, [onHide, id])

  useEffect(() => {
    timer.current = setTimeout(() => {
      onHide && onHide(id)
    }, commonConstants.NOTIFY_TIMEOUT)

    return () => clearTimeout(timer.current)
  }, [id, onHide])

  return (
    <div
      className={cn(styles.notify, {
        [styles[type]]: type,
      })}
    >
      <button className={styles.btn} onClick={onClickCross} type="button">
        <CrossIcon />
      </button>
      <div className={styles.icon}>
        <Icon />
      </div>
      {title && <div className={styles.title}>{title}</div>}
      {desc && <div className={styles.desc}>{desc}</div>}
    </div>
  )
}

NotifyRaw.defaultProps = {
  title: '',
  desc: '',
  type: 'success',
}

export const Notify = NotifyRaw
export default memo(Notify)
