import { handleActions } from 'redux-actions'

import { modelsActions } from 'src/actions'

export const initialState = ['F-CLASS', 'H-CLASS', 'S-CLASS', 'T-CLASS']

const reducerMap = {
  [modelsActions.loadModelsSuccess]: (state, { payload }) => payload.types,
}

export default handleActions(reducerMap, initialState)
