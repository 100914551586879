import { combineReducers } from '@reduxjs/toolkit'

import type from './type'
import props from './props'

const reducer = combineReducers({
  type,
  props,
})

export default reducer
