import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Counter from '../Counter'

import styles from './styles.module.scss'

const CounterWithFormRaw = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        const hasError = (meta.error || meta.submitError) && meta.touched
        return (
          <div className={styles.field}>
            <Counter {...input} {...props} hasError={hasError} />
            {hasError && <div className={styles.errorText}>{meta.error || meta.submitError}</div>}
          </div>
        )
      }}
    </Field>
  )
}

CounterWithFormRaw.propTypes = {
  name: PropTypes.string.isRequired,
}

const CounterWithForm = CounterWithFormRaw
export default memo(CounterWithForm)
