import { lazy } from 'react'

const routes = [
  {
    path: '/',
    redirect: '/home',
    exact: true,
  },
  {
    path: '/home',
    component: lazy(() => import('src/modules/Models/pages/Home')),
    fallback: null,
    exact: true,
  },
  {
    path: '/confirm-email',
    component: lazy(() => import('src/modules/Auth/pages/ConfirmUser')),
    fallback: null,
    exact: true,
  },
  {
    path: '/change-email',
    component: lazy(() => import('src/modules/Profile/pages/ChangeEmail')),
    fallback: null,
    exact: true,
  },
  {
    path: '/reset-password',
    component: lazy(() => import('src/modules/Auth/pages/RecoveryPassword')),
    fallback: null,
    exact: true,
  },
  {
    path: '/terms-and-conditions',
    component: lazy(() => import('src/modules/Profile/pages/Terms')),
    fallback: null,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: lazy(() => import('src/modules/Profile/pages/Privacy')),
    fallback: null,
    exact: true,
  },
  {
    path: '/profile',
    component: lazy(() => import('src/modules/Profile/pages/Profile')),
    fallback: null,
    private: true,
    routes: [
      {
        path: '/profile',
        redirect: '/profile/info',
        exact: true,
      },
      {
        path: '/profile/info',
        component: lazy(() => import('src/modules/Profile/pages/Info')),
        fallback: null,
        exact: true,
      },
      {
        path: '/profile/machines',
        component: lazy(() => import('src/modules/Profile/pages/Machines')),
        fallback: null,
        exact: true,
      },
      {
        path: '/profile/requests',
        component: lazy(() => import('src/modules/Profile/pages/Requests')),
        fallback: null,
        exact: true,
      },
      {
        path: '/profile/requests/:reqId',
        component: lazy(() => import('src/modules/Profile/pages/Request')),
        fallback: null,
        exact: true,
      },
    ],
  },
  {
    path: '/admin',
    component: lazy(() => import('src/modules/Admin/pages/Admin')),
    fallback: null,
    private: true,
    admin: true,
    routes: [
      {
        path: '/admin',
        redirect: '/admin/requests',
        exact: true,
      },
      {
        path: '/admin/requests',
        component: lazy(() => import('src/modules/Admin/pages/Requests')),
        fallback: null,
        exact: true,
      },
      {
        path: '/admin/requests/:reqId',
        component: lazy(() => import('src/modules/Admin/pages/Request')),
        fallback: null,
        exact: true,
      },
      {
        path: '/admin/parts',
        component: lazy(() => import('src/modules/Admin/pages/Parts')),
        fallback: null,
        exact: true,
      },
      {
        path: '/admin/components',
        component: lazy(() => import('src/modules/Admin/pages/Components')),
        fallback: null,
        exact: true,
      },
      {
        path: '/admin/admins',
        component: lazy(() => import('src/modules/Admin/pages/Admins')),
        fallback: null,
        exact: true,
      },
    ],
  },
  {
    path: '/model/:type',
    component: lazy(() => import('src/modules/Models/pages/Model')),
    fallback: null,
    private: false,
  },
  {
    path: '/cart',
    component: lazy(() => import('src/modules/Cart/pages/Layout')),
    fallback: null,
    private: false,
  },
  {
    path: '/contact-us',
    component: lazy(() => import('src/modules/Profile/pages/ContactUs')),
    fallback: null,
    private: false,
  },
  {
    path: '*',
    redirect: '/home',
  },
]

export default routes
