import i18n from 'i18next'
import { FORM_ERROR } from 'final-form'

import { manager } from 'src/rest'
import { to } from 'src/lib'
import { machinesActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { errorsConstants } from 'src/constants'

const createUserMachine = (userId, values) => async (dispatch, getState) => {
  const { value, error } = await to(manager.createUserMachine(userId, values))

  if (value) {
    const { isSuccess } = value.data
    if (isSuccess) {
      dispatch(machinesActions.createMachineSuccess())
      return value.data
    } else {
      dispatch(machinesActions.createMachineFailure())
    }
  }

  if (error) {
    dispatch(machinesActions.createMachineFailure())
    const { data } = (error && error.response) || {}

    if (data && data.errorCode === errorsConstants.DUPLICATE_SERIAL_NUMBER) {
      return { serialNumber: i18n.t('errors:fields.duplicate_serial_number') }
    }

    dispatch(notifyThunks.somethingError())
    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default createUserMachine
