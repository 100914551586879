import { combineReducers } from '@reduxjs/toolkit'

import companies from './companies'
import numbers from './numbers'

const reducer = combineReducers({
  companies,
  numbers,
})

export default reducer
