const preparePartsOptions = (parts, lang) => {
  const groupedParts = Object.keys(parts).reduce((acc, partId) => {
    const { id, internalNumber, name, machineComponent } = parts[partId]
    const partName = name[lang]
    const part = { value: id, label: `${internalNumber} - ${partName}`, order: +internalNumber }

    if (acc[machineComponent]) {
      acc[machineComponent].push(part)
    } else {
      acc[machineComponent] = [part]
    }

    return acc
  }, {})

  Object.keys(groupedParts).forEach((group) => {
    groupedParts[group].sort((a, b) => a.order - b.order)
  })

  return groupedParts
}

export default preparePartsOptions
