import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.module.scss'

const InputRowRaw = (props) => {
  const { children, className, isLarge } = props
  return (
    <div
      className={cn(styles.row, className, {
        [styles.large]: isLarge,
      })}
    >
      {children}
    </div>
  )
}

InputRowRaw.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export const InputRow = InputRowRaw
export default memo(InputRow)
