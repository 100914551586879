import { cart } from 'src/rest'
import { cartActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { prepareErrors } from 'src/services'
import { userSelectors } from 'src/selectors'

import updateCartLocal from './updateCartLocal'

const deletePart = (requestPartId, localParam) => async (dispatch, getState) => {
  const isLogged = userSelectors.getIsLogged(getState())

  if (isLogged) {
    const { value, error } = await to(cart.deletePart(requestPartId))

    if (value) {
      const { isSuccess } = value.data

      if (isSuccess) {
        dispatch(cartActions.deletePartSuccess())
      } else {
        const errorParams = prepareErrors({ data: value.data })

        dispatch(notifyThunks.serverError(errorParams))
        dispatch(cartActions.deletePartFailure())
      }
    }

    if (error) {
      const errorParams = prepareErrors({ error })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(cartActions.deletePartFailure())
    }
  } else {
    dispatch(updateCartLocal({ type: 'delete', param: { ...localParam, partId: requestPartId } }))
  }
}

export default deletePart
