const getState = (state) => state.request

export const getParts = (state) => getState(state).parts
export const getTotalItems = (state) => getState(state).total
export const getNumber = (state) => getState(state).number
export const getStatus = (state) => getState(state).status
export const getStateData = (state) => getState(state).state
export const getFile = (state) => getState(state).quotationFile
export const getIsLoading = (state) => getState(state).isLoading
export const getUserInfo = (state) => getState(state).userInfo
export const getRequestId = (state) => getState(state).requestId
export const getIsReleaseLoading = (state) => getState(state).isReleaseLoading
export const getUserMachines = (state) => getState(state).userMachines
