import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ReactComponent as CheckedIcon } from 'src/assets/images/icons/checked.svg'

import styles from './styles.module.scss'

const CheckboxRaw = (props) => {
  const { label, style, id, hasError, ...inputProps } = props

  return (
    <div className={cn(styles.inputWrap)}>
      <input
        {...inputProps}
        id={id}
        className={cn(styles.input, style, {
          [styles.inputError]: hasError,
        })}
      />
      {label && (
        <label htmlFor={id} className={styles.label}>
          <CheckedIcon />
          {label}
        </label>
      )}
    </div>
  )
}

CheckboxRaw.defaultProps = {
  hasError: false,
  disabled: false,
}

CheckboxRaw.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  id: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.string,
}

const Checkbox = CheckboxRaw
export default memo(Checkbox)
