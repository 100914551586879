import React, { memo, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FormLayout from 'src/forms/FormLayout'
import InputWithForm from 'src/forms/InputWithForm'
import InputRow from 'src/forms/InputRow'

import Modal from '../Modal'

import { useMappedActions } from './binding'
import validate from './validate'
import styles from './styles.module.scss'

const CUAdminModalRaw = (props) => {
  const { onClose, id, ...values } = props
  const { t } = useTranslation()
  const actions = useMappedActions()

  const title = useMemo(() => {
    const prefix = id ? 'popup_edit_admin' : 'popup_add_admin'
    return t(`common:titles.${prefix}`)
  }, [id, t])

  const onSubmit = useCallback(
    async (values) => {
      const errors = id ? await actions.editAdmin(values, id) : await actions.createAdmin(values)

      if (errors) {
        return errors
      }

      onClose(true)
    },
    [actions, onClose, id],
  )

  return (
    <Modal title={title} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={values}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.confirm')}
            >
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('admins:info.name') + '*'}</span>
                <InputWithForm name="name" isSimple />
              </InputRow>
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('admins:info.email') + '*'}</span>
                <InputWithForm name="email" isSimple disabled={Boolean(id)} />
              </InputRow>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

CUAdminModalRaw.propTypes = {
  onClose: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export const CUAdminModal = CUAdminModalRaw
export default memo(CUAdminModal)
