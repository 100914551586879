import format from 'date-fns/format'

const createDateAsUTC = (date) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  )
}

export const prepareDateToTz = (date, pattern) => {
  return format(createDateAsUTC(date), pattern)
}

export default prepareDateToTz
