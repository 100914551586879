import { handleActions } from 'redux-actions'

import { settingsActions } from 'src/actions'

const defaultState = {
  BASE_URL: {
    prod: {
      port: '',
    },
    dev: {
      protocol: '',
      host: '',
      port: '',
    },
  },
  LANGUAGE: {
    default: 'en',
    admin: [],
    user: [],
  },
}

const reducersMap = {
  [settingsActions.loadSettingsSuccess]: (state, action) => action.payload,
}

export default handleActions(reducersMap, defaultState)
