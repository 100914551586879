import { manager } from 'src/rest'
import { to } from 'src/lib'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { prepareErrors, prepareMachinesByClass } from 'src/services'

const getUserMachines = (userId) => async (dispatch, getState) => {
  const { value, error } = await to(manager.getUserMachines(userId))

  if (value) {
    const { isSuccess } = value.data
    if (isSuccess) {
      const { items } = value.data

      dispatch(managerActions.getUserMachinesSuccess(prepareMachinesByClass(items)))
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(managerActions.getUserMachinesFailure())
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })

    dispatch(notifyThunks.serverError(errorParams))
    dispatch(managerActions.getUserMachinesFailure())
  }
}

export default getUserMachines
