const CACHE = {}

const createPromise = () => {
  const result = {}
  result.promise = new Promise((resolve, reject) => {
    result.resolve = resolve
    result.reject = reject
  })
  return result
}

const wrap = (name, createIfExists = false) => {
  if (!CACHE[name] || createIfExists) {
    CACHE[name] = createPromise()
  }
  return CACHE[name].promise
}

const has = (name) => {
  return Boolean(CACHE[name])
}

const resolve = (name, data) => {
  const cached = CACHE[name]
  if (cached) {
    cached.resolve(data)
    delete CACHE[name]
  }
}

const reject = (name, reason) => {
  const cached = CACHE[name]
  if (cached) {
    cached.reject(reason)
    delete CACHE[name]
  }
}

export default {
  wrap,
  has,
  resolve,
  reject,
}
