import i18n from 'i18next'
import { regexpConstants } from 'src/constants'

export default (values) => {
  const errors = {}

  if (!values.password) {
    errors.password = i18n.t('errors:fields.required_field')
  } else if (!regexpConstants.PASSWORD.test(values.password)) {
    errors.password = i18n.t('errors:fields.weak_password')
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = i18n.t('errors:fields.required_field')
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = i18n.t('errors:fields.confirm_password')
  }

  return errors
}
