import { handleActions, combineActions } from 'redux-actions'

import { authActions } from 'src/actions'

export const initialState = false

const reducerMap = {
  [combineActions(authActions.loginSuccess)]: () => true,
  [combineActions(authActions.logoutSuccess)]: () => false,
}

export default handleActions(reducerMap, initialState)
