import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Textarea from '../Textarea'

import styles from './styles.module.scss'

const TextareaWithFormRaw = ({ name, ...props }) => {
  return (
    <Field name={name} format={(value = '') => value && value.toString().trim()} formatOnBlur>
      {({ input, meta }) => {
        const hasError = (meta.error || meta.submitError) && meta.touched

        return (
          <div className={styles.field}>
            <Textarea {...input} {...props} hasError={hasError} />
            {hasError && <div className={styles.errorText}>{meta.error || meta.submitError}</div>}
          </div>
        )
      }}
    </Field>
  )
}

TextareaWithFormRaw.propTypes = {
  name: PropTypes.string.isRequired,
}

const TextareaWithForm = TextareaWithFormRaw
export default memo(TextareaWithForm)
