import qs from 'query-string'

import { instance } from '../axiosInstance'

export const loadRequests = (params) => {
  const query = qs.stringify(params, { skipNull: true, skipEmptyString: true })
  return instance({
    method: 'get',
    url: `/requests${query && '?' + query}`,
  })
}

export const loadRequest = (reqId) => {
  return instance({
    method: 'get',
    url: `/requests/${reqId}`,
  })
}
