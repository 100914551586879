import { parts } from 'src/rest'
import { to } from 'src/lib'
import { partsActions } from 'src/actions'
import { prepareParts } from 'src/services'

const loadParts = (params) => async (dispatch) => {
  const { value, error } = await to(parts.loadAdminParts(params))

  if (value) {
    const { isSuccess, items, totalItems } = value.data

    if (isSuccess) {
      const data = {
        items: prepareParts(items),
        total: totalItems,
      }
      dispatch(partsActions.loadPartsSuccess())
      return data
    } else {
      dispatch(partsActions.loadPartsFailure())
    }
  }

  if (error) {
    dispatch(partsActions.loadPartsFailure())
  }
}

export default loadParts
