import { storage } from 'src/lib'
import { storageConstants } from 'src/constants'

const gerLocalCart = async () => {
  const data = await storage.getItem(storageConstants.GUEST_CART)

  if (data) {
    return JSON.parse(data)
  }
}

export default gerLocalCart
