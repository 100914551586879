import i18n from 'i18next'
import { FORM_ERROR } from 'final-form'

import { cart } from 'src/rest'
import { cartActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { prepareErrors } from 'src/services'
import { errorsConstants } from 'src/constants'

const cartSubmit = (data) => async (dispatch) => {
  const { value, error } = await to(cart.cartSubmit(data))

  if (value) {
    const { isSuccess, notificationStatus } = value.data

    if (isSuccess) {
      dispatch(cartActions.cartSubmitSuccess(value.data.data))
      if (notificationStatus === errorsConstants.EMAIL_WARNING) {
        dispatch(notifyThunks.emailWarning())
      }
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(cartActions.cartSubmitFailure())
      return { [FORM_ERROR]: i18n.t('errors:server.common') }
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })

    dispatch(notifyThunks.serverError(errorParams))
    dispatch(cartActions.cartSubmitFailure())
    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default cartSubmit
