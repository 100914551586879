import { manager } from 'src/rest'
import { to } from 'src/lib'
import { requestsActions } from 'src/actions'
import { prepareMachineTables } from 'src/services'

const loadManagerRequest = (reqId, isRefresh = false) => async (dispatch) => {
  dispatch(requestsActions.loadRequestStart({ isRefresh }))

  const { value, error } = await to(manager.loadRequest(reqId))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      const {
        items,
        companyAddress,
        companyPhoneNumber,
        companyName,
        email,
        firstName,
        userId,
        lastName,
        phoneNumber,
        ...otherData
      } = data

      const userInfo = {
        companyAddress,
        companyName,
        companyPhoneNumber,
        email,
        firstName,
        userId,
        lastName,
        phone: phoneNumber,
      }
      const nextData = {
        userInfo,
        requestId: reqId,
        quotationFile: '',
        ...otherData,
        ...prepareMachineTables(items),
      }
      dispatch(requestsActions.loadRequestSuccess(nextData))
    } else {
      dispatch(requestsActions.loadRequestFailure())
    }
  }

  if (error) {
    dispatch(requestsActions.loadRequestFailure())
  }
}

export default loadManagerRequest
