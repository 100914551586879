import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'

import { getBaseUrl } from 'src/services'

import FullLoader from 'src/components/FullLoader'
import LangTabs from 'src/indyComponents/LangTabs'

import FormLayout from 'src/forms/FormLayout'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'

import Modal from '../Modal'

import Image from './components/Image'

import validate from './validate'
import { useMappedActions } from './bindings'
import styles from './styles.module.scss'

const EditPartModalRaw = (props) => {
  const { onClose, id } = props
  const { t } = useTranslation()
  const actions = useMappedActions()
  const [initialValues, setInitialValues] = useState({})
  const [tabsData, setTabsData] = useState({ name: {}, description: {} })
  const [imageUrl, setImageUrl] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const baseUrl = useMemo(() => getBaseUrl(), [])

  const onDelImg = useCallback(() => {
    setImageUrl('')
  }, [])

  const onUpload = useCallback(
    async (data) => {
      const url = await actions.uploadImage(data)
      setImageUrl(`${baseUrl}/${url}`)
    },
    [actions, baseUrl],
  )

  const onChangeTabData = useCallback(
    (field, lang, value) => {
      setTabsData({ ...tabsData, [field]: { ...tabsData[field], [lang]: value } })
    },
    [tabsData],
  )

  const onSubmit = useCallback(
    async (values) => {
      const nextImageUrlr = imageUrl.replace(baseUrl + '/', '')
      const nextValues = { ...values, ...tabsData, picture: nextImageUrlr }
      const error = await actions.updatePart(id, nextValues)
      if (error) {
        return error
      }
      onClose(true)
    },
    [actions, onClose, id, tabsData, imageUrl, baseUrl],
  )

  useEffect(() => {
    const initPart = async () => {
      const data = await actions.loadPart(id)
      if (data) {
        setInitialValues(data)
        setImageUrl(`${baseUrl}/${data.picture}`)
        setTabsData({ name: data.name, description: data.description })
      }
      setIsLoading(false)
    }

    initPart()
  }, [actions, id, baseUrl])

  return (
    <Modal title={t('common:titles.popup_edit_part')} onClose={onClose} dialogStyles={styles.dialog}>
      <div className={styles.wrap}>
        {isLoading && <FullLoader />}
        <Image onUpload={onUpload} onBigSize={actions.uploadImageIsBig} onDelImg={onDelImg} imageUrl={imageUrl} />
        <div className={styles.form}>
          <Form
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => {
              return (
                <FormLayout onSubmit={handleSubmit} submitting={submitting} btnText={t('common:buttons.save_changes')}>
                  <div className={styles.top}>
                    <InputRow className={styles.row}>
                      <div className={styles.col}>
                        <span className={styles.label}>{t('part:info.machineModel')}</span>
                        <InputWithForm name="machineModel" isSimple disabled />
                      </div>
                      <div className={styles.col}>
                        <span className={styles.label}>{t('part:info.machineComponent')}</span>
                        <InputWithForm name="machineComponent" isSimple disabled />
                      </div>
                    </InputRow>

                    <InputRow className={styles.row}>
                      <div className={styles.col}>
                        <span className={styles.label}>X</span>
                        <InputWithForm name="x" isSimple />
                      </div>
                      <div className={styles.col}>
                        <span className={styles.label}>Y</span>
                        <InputWithForm name="y" isSimple />
                      </div>
                    </InputRow>

                    <InputRow>
                      <span className={styles.label}>{t('part:info.internalNumber')}</span>
                      <InputWithForm name="internalNumber" isSimple />
                    </InputRow>
                  </div>

                  <LangTabs data={tabsData} filedField="name" translation="part" onChangeTabData={onChangeTabData} />
                </FormLayout>
              )
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export const EditPartModal = EditPartModalRaw
export default memo(EditPartModal)
