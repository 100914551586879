const findMachine = (elem, params) => {
  const { serialNumber = '', machineType } = params

  return elem.machineType === machineType && elem.serialNumber === serialNumber
}

const findPart = (part, partId) => {
  if (partId) {
    return part.id === partId
  }

  return false
}

const editCommentLocal = (cart, param) => {
  const { machineType, partId, serialNumber = '', userComments, amount, oldUserComments } = param

  const indexMachine = cart.findIndex((elem) => findMachine(elem, { serialNumber, machineType }))

  if (indexMachine >= 0) {
    const { items } = cart[indexMachine]
    const indexPart = items.findIndex((x) => findPart(x, partId, oldUserComments))

    if (indexPart >= 0) {
      if (typeof userComments === 'string') {
        cart[indexMachine].items[indexPart].userComments = userComments
      }
      if (amount) {
        cart[indexMachine].items[indexPart].amount = amount
      }
    }
  }

  return cart
}

export default editCommentLocal
