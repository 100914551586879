import i18n from 'i18next'
import { FORM_ERROR } from 'final-form'

import { admins } from 'src/rest'
import { to } from 'src/lib'
import { adminsActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { errorsConstants } from 'src/constants'

const createAdmin = (params) => async (dispatch) => {
  const { value, error } = await to(admins.createAdmin(params))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(adminsActions.createAdminSuccess())
    } else {
      dispatch(adminsActions.createAdminFailure())
    }
  }

  if (error) {
    dispatch(adminsActions.createAdminFailure())

    const { data } = (error && error.response) || {}

    if (data && data.errorCode === errorsConstants.DUPLICATE_EMAIL) {
      return { email: i18n.t('errors:fields.duplicate_email') }
    }

    dispatch(notifyThunks.somethingError())
    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default createAdmin
