import React, { memo, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ReactComponent as EditIcon } from 'src/assets/images/icons/edit.svg'

import { useOnClickOutside } from 'src/hooks'
import BaseButton from 'src/components/BaseButton'

import styles from './styles.module.scss'

const SelectSNRaw = (props) => {
  const {
    options,
    value = null,
    hasEditing = true,
    onChange,
    onAdd,
    onEdit,
    hasAddMachine = true,
    hasPrefix = false,
  } = props
  const [isOpened, setIsOpened] = useState(false)
  const [selected, setSelected] = useState(value)
  const dropdownRef = useRef(null)
  const { t } = useTranslation()

  useOnClickOutside(dropdownRef, () => setIsOpened(false))

  const onClickHeader = useCallback(() => {
    setIsOpened(!isOpened)
  }, [isOpened])

  const onClickSerialNumber = useCallback(
    (value) => () => {
      onChange && onChange(value)
      setSelected(value)
      setIsOpened(false)
    },
    [onChange],
  )

  const onClickEdit = useCallback(
    (value) => (e) => {
      e.stopPropagation()
      setIsOpened(false)
      onEdit && onEdit(value)
    },
    [onEdit],
  )

  const onClickAdd = useCallback(() => {
    setIsOpened(false)
    onChange && onChange('')
    onAdd && onAdd()
  }, [onAdd, onChange])

  const renderHeaderText = useMemo(() => {
    if (selected) {
      if (hasPrefix) {
        return `${t('common:s/n')} ${selected}`
      }

      return selected
    }

    return t('machine:select_serial_number')
  }, [selected, hasPrefix, t])

  const renderHeader = useMemo(() => {
    return (
      <div className={styles.header} onClick={onClickHeader}>
        <div className={styles.headerText}>{renderHeaderText}</div>
        <div className={styles.headerArrow} />
      </div>
    )
  }, [onClickHeader, renderHeaderText])

  const renderDropOption = useMemo(() => {
    return options.map((option) => {
      return (
        <div
          className={cn(styles.dropItem, {
            [styles.dropItemActive]: selected === option.serialNumber,
          })}
          key={option.id}
          onClick={onClickSerialNumber(option.serialNumber)}
        >
          <span
            className={cn(styles.dropText, {
              [styles.dropTextFull]: true,
            })}
          >
            {option.serialNumber}
          </span>
          {/* {hasDescr && option.designation && <span className={styles.dropDesc}>{option.designation}</span>} */}
          {hasEditing && (
            <button className={styles.dropBtn} type="button" onClick={onClickEdit(option)}>
              <EditIcon />
            </button>
          )}
        </div>
      )
    })
  }, [options, selected, hasEditing, onClickSerialNumber, onClickEdit])

  const renderDrop = useMemo(() => {
    return (
      <div className={styles.drop}>
        {Boolean(options.length) && <div className={styles.dropList}>{renderDropOption}</div>}
        {hasAddMachine && (
          <BaseButton
            text={t('common:buttons.add_serial_number')}
            skin="border"
            isFull
            onClick={onClickAdd}
            type="button"
          />
        )}
      </div>
    )
  }, [options, renderDropOption, onClickAdd, t, hasAddMachine])

  useEffect(() => {
    if (selected !== value) {
      setSelected(value)
    }
  }, [selected, value])

  return (
    <div className={styles.select} ref={dropdownRef}>
      {renderHeader}
      {isOpened && renderDrop}
    </div>
  )
}

export const SelectSN = SelectSNRaw
export default memo(SelectSN)
