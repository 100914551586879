import { useMapState, useMapActions } from 'src/hooks'
import { commonThunks, modalThunks, authThunks, userThunks } from 'src/thunks'
import { userSelectors, cartSelectors, settingsSelectors } from 'src/selectors'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      isLoggingIn: userSelectors.getIsLoggingIn(state),
      isLogged: userSelectors.getIsLogged(state),
      isAdmin: userSelectors.getIsAdmin(state),
      cartTotalItems: cartSelectors.getCartTotalItems(state),
      languages: settingsSelectors.getUserLanguages(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    setLanguage: commonThunks.setLanguage,
    showModal: modalThunks.showModal,
    updateUserLang: userThunks.updateUserLang,
    logout: authThunks.logout,
  })
}
