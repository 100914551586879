import { modalConstants } from 'src/constants'

import LoginModal from './LoginModal'
import RegModal from './RegModal'
import PersonalInfoModal from './PersonalInfoModal'
import ResetPassModal from './ResetPassModal'
import RecoveryPassModal from './RecoveryPassModal'
import RulesModal from './RulesModal'
import ChangeEmailModal from './ChangeEmailModal'
import ChangePasswordModal from './ChangePasswordModal'
import CUMachineModal from './CUMachineModal'
import AddUserMachineModal from './AddUserMachineModal'
import AddUserPartModal from './AddUserPartModal'
import ConfirmModal from './ConfirmModal'
import PartModal from './PartModal'
import EditPartModal from './EditPartModal'
import EditComponentModal from './EditComponentModal'
import CUAdminModal from './CUAdminModal'
import DidntFind from './DidntFind'
import CUUserMachine from './CUUserMachine'

const modalConfig = {
  [modalConstants.LOGIN_MODAL]: LoginModal,
  [modalConstants.REG_MODAL]: RegModal,
  [modalConstants.PERSONAL_INFO_MODAL]: PersonalInfoModal,
  [modalConstants.RESET_PASS_MODAL]: ResetPassModal,
  [modalConstants.RECOVERY_PASS_MODAL]: RecoveryPassModal,
  [modalConstants.RULES_MODAL]: RulesModal,
  [modalConstants.CHANGE_EMAIL_MODAL]: ChangeEmailModal,
  [modalConstants.CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
  [modalConstants.CU_MACHINE_MODAL]: CUMachineModal,
  [modalConstants.ADD_USER_MACHINE_MODAL]: AddUserMachineModal,
  [modalConstants.ADD_USER_PART_MODAL]: AddUserPartModal,
  [modalConstants.CONFIRM_MODAL]: ConfirmModal,
  [modalConstants.PART_MODAL]: PartModal,
  [modalConstants.EDIT_PART_MODAL]: EditPartModal,
  [modalConstants.EDIT_COMPONENT_MODAL]: EditComponentModal,
  [modalConstants.CU_ADMIN_MODAL]: CUAdminModal,
  [modalConstants.DIDNT_FIND]: DidntFind,
  [modalConstants.CU_USER_MACHINE]: CUUserMachine,
}

export default modalConfig
