import i18n from 'i18next'

import { machinesSelectors } from 'src/selectors'
import { machinesActions } from 'src/actions'
import { setMachine } from 'src/storage'

const createMachineLocal = (values) => async (dispatch, getState) => {
  const state = getState()
  const machines = machinesSelectors.getItems(state)
  const time = Date.now()
  const nextValues = { ...values, id: time, time }
  const hasMachine = machines.find(
    (machine) => machine.serialNumber === nextValues.serialNumber && machine.model === nextValues.model,
  )

  if (hasMachine) {
    dispatch(machinesActions.createMachineFailure())
    return { serialNumber: i18n.t('errors:fields.duplicate_serial_number') }
  } else {
    await setMachine(machines, nextValues)
    dispatch(machinesActions.createMachineSuccess())
    return { isSuccess: true, data: nextValues }
  }
}

export default createMachineLocal
