import { handleActions, combineActions } from 'redux-actions'

import { requestsActions } from 'src/actions'

export const initialState = {}

const reducerMap = {
  [combineActions(requestsActions.setRequestsSort, requestsActions.setRequestsSearch)]: (state, { payload }) => {
    return { ...state, ...payload }
  },
  [requestsActions.cleanRequests]: () => initialState,
}

export default handleActions(reducerMap, initialState)
