import React, { memo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FormLayout from 'src/forms/FormLayout'
import InputWithForm from 'src/forms/InputWithForm'
import InputRow from 'src/forms/InputRow'

import Modal from '../Modal'

import { useMappedActions } from './binding'
import validate from './validate'
import styles from './styles.module.scss'

const ResetPassModalRaw = (props) => {
  const { onClose } = props
  const actions = useMappedActions()
  const { t } = useTranslation()

  const onSubmit = useCallback(
    async (values) => {
      const isSuccess = await actions.resetPassword(values)

      if (isSuccess) {
        onClose()
      }
    },
    [actions, onClose],
  )

  return (
    <Modal title={t('common:titles.popup_reset_pass')} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.confirm')}
            >
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('profile:info.email') + '*'}</span>
                <InputWithForm name="email" isSimple />
              </InputRow>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

ResetPassModalRaw.propTypes = {
  onClose: PropTypes.func,
}

export const ResetPassModal = ResetPassModalRaw
export default memo(ResetPassModal)
