import { request } from 'src/rest'
import { to } from 'src/lib'
import { requestsActions } from 'src/actions'
import { prepareMachineTables } from 'src/services'

const loadRequest = (reqId, isRefresh = false) => async (dispatch) => {
  dispatch(requestsActions.loadRequestStart({ isRefresh }))

  const { value, error } = await to(request.loadRequest(reqId))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      const { items, ...otherData } = data
      const nextData = {
        quotationFile: '',
        ...otherData,
        ...prepareMachineTables(items),
      }
      dispatch(requestsActions.loadRequestSuccess(nextData))
    } else {
      dispatch(requestsActions.loadRequestFailure())
    }
  }

  if (error) {
    dispatch(requestsActions.loadRequestFailure())
  }
}

export default loadRequest
