import { handleActions } from 'redux-actions'

import { requestsActions } from 'src/actions'

export const initialState = 'initial'

const reducerMap = {
  [requestsActions.loadRequestStart]: (state, { payload }) => {
    const { isRefresh } = payload

    if (isRefresh && state !== initialState) {
      return 'updating'
    } else {
      return 'loading'
    }
  },
  [requestsActions.loadRequestSuccess]: () => {
    return 'success'
  },
  [requestsActions.loadRequestFailure]: () => {
    return 'error'
  },
  [requestsActions.cleanRequest]: () => {
    return initialState
  },
}

export default handleActions(reducerMap, initialState)
