import { createAction } from 'redux-actions'

const prefix = 'MACHINES:'

export const loadMachinesSuccess = createAction(prefix + 'LOAD_SUCCESS')
export const loadMachinesFailure = createAction(prefix + 'LOAD_FAILURE')

export const loadAllMachinesSuccess = createAction(prefix + 'LOAD_ALL_SUCCESS')
export const loadAllMachinesFailure = createAction(prefix + 'LOAD_ALL_FAILURE')

export const createMachineSuccess = createAction(prefix + 'CREATE_SUCCESS')
export const createMachineFailure = createAction(prefix + 'CREATE_FAILURE')

export const editMachineSuccess = createAction(prefix + 'EDIT_SUCCESS')
export const editMachineFailure = createAction(prefix + 'EDIT_FAILURE')

export const delMachineSuccess = createAction(prefix + 'DEL_SUCCESS')
export const delMachineFailure = createAction(prefix + 'DEL_FAILURE')
