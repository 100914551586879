import i18n from 'i18next'

import { regexpConstants } from 'src/constants'

export default (values) => {
  const errors = {}

  if (!values.companyName) {
    errors.companyName = i18n.t('errors:fields.required_field')
  } else if (values.companyName.length < 3) {
    errors.companyName = i18n.t('errors:fields.min_length_field', { count: 3 })
  }

  if (!values.firstName) {
    errors.firstName = i18n.t('errors:fields.required_field')
  } else if (values.firstName.length < 3) {
    errors.firstName = i18n.t('errors:fields.min_length_field', { count: 3 })
  }

  if (!values.lastName) {
    errors.lastName = i18n.t('errors:fields.required_field')
  } else if (values.lastName.length < 3) {
    errors.lastName = i18n.t('errors:fields.min_length_field', { count: 3 })
  }

  if (!values.phone) {
    errors.phone = i18n.t('errors:fields.required_field')
  } else if (values.phone.length < 3) {
    errors.phone = i18n.t('errors:fields.min_length_field', { count: 3 })
  }

  if (!values.email) {
    errors.email = i18n.t('errors:fields.required_field')
  } else if (!regexpConstants.EMAIL.test(values.email)) {
    errors.email = i18n.t('errors:fields.invalid_email')
  }

  if (!values.acceptDocuments) {
    errors.acceptDocuments = i18n.t('errors:fields.required_field')
  }

  if (!values.password) {
    errors.password = i18n.t('errors:fields.required_field')
  } else if (!regexpConstants.PASSWORD.test(values.password)) {
    errors.password = i18n.t('errors:fields.weak_password')
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = i18n.t('errors:fields.required_field')
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = i18n.t('errors:fields.confirm_password')
  }

  return errors
}
