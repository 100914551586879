import { instance } from '../axiosInstance'

export const loadCompanies = () => {
  return instance({
    method: 'get',
    url: `/manager/typeahead/company/name`,
  })
}

export const loadNumbers = () => {
  return instance({
    method: 'get',
    url: `/manager/typeahead/request/numbers`,
  })
}
