import React, { memo } from 'react'
import PropTypes from 'prop-types'

import BaseButton from 'src/components/BaseButton'
import FullLoader from 'src/components/FullLoader'

import styles from './styles.module.scss'

const FormLayoutRaw = (props) => {
  const { children, onSubmit, submitting, pristine, invalid, btnText, isFull } = props
  return (
    <form onSubmit={onSubmit} noValidate>
      {submitting && <FullLoader hasText />}
      {children}
      {!!btnText && (
        <div className={styles.footer}>
          <BaseButton type="submit" text={btnText} disabled={submitting || pristine || invalid} isFull={isFull} />
        </div>
      )}
    </form>
  )
}

FormLayoutRaw.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  children: PropTypes.node,
  renderContent: PropTypes.func,
  renderFooter: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  isFull: PropTypes.bool,
}

export const FormLayout = FormLayoutRaw
export default memo(FormLayout)
