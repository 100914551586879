const prepareAdmins = (items) => {
  return items.reduce((acc, curr) => {
    if (curr) {
      const { id, name, email } = curr

      acc[id] = {
        name,
        email,
      }
    }

    return acc
  }, {})
}

export default prepareAdmins
