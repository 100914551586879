const setItem = (key, value) => localStorage.setItem(key, value)
const getItem = async (key) => await localStorage.getItem(key)
const removeItem = (key) => localStorage.removeItem(key)
const clear = () => localStorage.clear()

export default {
  setItem,
  getItem,
  removeItem,
  clear,
}
