import { typeahead } from 'src/rest'
import { to } from 'src/lib'
import { typeaheadActions } from 'src/actions'
import { prepareTapeahead } from 'src/services'

const loadNumbers = () => async (dispatch) => {
  const { value, error } = await to(typeahead.loadNumbers())

  if (value) {
    const { data } = value
    dispatch(typeaheadActions.loadNumbersSuccess(prepareTapeahead(data)))
  }

  if (error) {
    dispatch(typeaheadActions.loadNumbersFailure())
  }
}

export default loadNumbers
