import { instance } from 'src/rest'
import { storage } from 'src/lib'
import { authActions } from 'src/actions'
import { storageConstants } from 'src/constants'

const logout = () => (dispatch) => {
  delete instance.defaults.headers['Authorization']
  storage.removeItem(storageConstants.ACCESS_TOKEN)
  dispatch(authActions.logoutSuccess())
}

export default logout
