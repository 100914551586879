import i18n from 'i18next'
import { FORM_ERROR } from 'final-form'

import { manager } from 'src/rest'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { prepareErrors } from 'src/services'
import { errorsConstants } from 'src/constants'

import loadManagerRequest from './loadManagerRequest'

const updatePartsBySN = (data, localParam) => async (dispatch) => {
  const { requestId } = localParam
  const { value, error } = await to(manager.updateSNParts(data, requestId))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(managerActions.updatePartSuccess())
      dispatch(loadManagerRequest(requestId, true))
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(managerActions.updatePartFailure())

      if (errorParams.errorCode === errorsConstants.DUPLICATE_SERIAL_NUMBER) {
        return { serialNumber: i18n.t('errors:fields.duplicate_serial_number') }
      }

      return { [FORM_ERROR]: i18n.t('errors:server.common') }
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })

    dispatch(notifyThunks.serverError(errorParams))
    dispatch(managerActions.updatePartFailure())

    if (errorParams.errorCode === errorsConstants.DUPLICATE_SERIAL_NUMBER) {
      return { serialNumber: i18n.t('errors:fields.duplicate_serial_number') }
    }

    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default updatePartsBySN
