const filterItems = (part, partId, userComments) => {
  if (partId) {
    return part.id !== partId
  }

  if (userComments) {
    return part.userComments !== userComments
  }

  return false
}

const deleteLocalPart = (cart, param) => {
  const { machineType, partId, serialNumber = '', userComments } = param
  const nextCart = cart.map((machine) => {
    if (machine.machineType === machineType && machine.serialNumber === serialNumber) {
      machine.items = machine.items.filter((part) => filterItems(part, partId, userComments))
    }
    if (!machine.items.length) {
      return null
    }

    return machine
  })

  const filtredMachine = nextCart.filter((x) => x)

  return filtredMachine
}

export default deleteLocalPart
