import qs from 'query-string'

import { instance } from '../axiosInstance'

export const loadAdminComponents = (params) => {
  return instance({
    method: 'get',
    url: `/admin/machinecomponents${
      params ? '?' + qs.stringify(params, { skipNull: true, skipEmptyString: true }) : ''
    }`,
  })
}

export const loadAdminComponent = (componentId) => {
  return instance({
    method: 'get',
    url: `/admin/machinecomponents/${componentId}`,
  })
}

export const updateAdminComponent = (machineType, componentName, data) => {
  return instance({
    method: 'put',
    url: `/admin/machinecomponents/machine/${machineType}/component/${componentName}`,
    data,
  })
}
