import { settingsActions } from 'src/actions'

const loadSettings = () => async (dispatch) => {
  try {
    const response = await fetch('/settings.json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const data = await response.json()
    dispatch(settingsActions.loadSettingsSuccess(data))
  } catch (errro) {
    dispatch(settingsActions.loadSettingsFailure())
  }
}

export default loadSettings
