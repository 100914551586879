import React, { memo, useRef, useState, useCallback } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from 'src/hooks'

import styles from './styles.module.scss'

const LangRaw = ({ onClickLang, isMultiLang, languages }) => {
  const [isActive, setIsActive] = useState(false)
  const { t, i18n } = useTranslation()
  const appLang = i18n.language
  const lang = languages.filter((item) => item !== appLang)

  const ref = useRef()
  useOnClickOutside(ref, () => setIsActive(false))

  const onClick = useCallback(() => {
    if (isMultiLang) {
      setIsActive(!isActive)
    }
  }, [isActive, isMultiLang])

  const onChangeLang = useCallback(
    (item) => {
      onClickLang && onClickLang(item)
      setIsActive(false)
    },
    [onClickLang],
  )

  const renderItem = (item) => {
    return (
      <li className={styles.item} key={item}>
        <button className={styles.btn} onClick={() => onChangeLang(item)} type="button">
          <div className={styles.inner}>
            <span className={styles.text}>{t(`common:languages.${item}`)}</span>
          </div>
        </button>
      </li>
    )
  }

  return (
    <div className={cn(styles.lang, { [styles.langActive]: isActive, [styles.langMultiple]: isMultiLang })} ref={ref}>
      <div className={styles.head}>
        <button
          className={cn(styles.btn, {
            [styles.btnCommon]: !isMultiLang,
          })}
          onClick={onClick}
        >
          <div className={styles.inner}>
            <span className={styles.text}>{t(`common:languages.${appLang}`).substring(0, 3)}</span>
            {isMultiLang && <span className={styles.arrow}></span>}
          </div>
        </button>
      </div>
      {isActive && <ul className={styles.body}>{lang.map(renderItem)}</ul>}
    </div>
  )
}

LangRaw.propTypes = {
  onClickLang: PropTypes.func,
  isMultiLang: PropTypes.bool,
  languages: PropTypes.array,
}

export const Lang = LangRaw
export default memo(Lang)
