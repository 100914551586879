const findMachine = (elem, params) => {
  const { serialNumber, machineType } = params

  return elem.machineType === machineType && elem.serialNumber === serialNumber
}

const findPart = (part, partId) => {
  if (partId) {
    return part.id === partId
  }

  return false
}

const prepareLocalCart = (cart, part, partId) => {
  const { serialNumber, machineType } = part
  const indexMachine = cart.findIndex((elem) => findMachine(elem, { serialNumber, machineType }))

  if (indexMachine >= 0) {
    const { items } = cart[indexMachine]
    const indexPart = items.findIndex((x) => findPart(x, partId))

    if (indexPart >= 0) {
      const { amount, userComments = '' } = cart[indexMachine].items[indexPart]
      const nextPart = part.items[0]
      let nextComment = ''

      if (!!userComments) {
        if (!!nextPart.userComments) {
          nextComment = userComments + ' | ' + nextPart.userComments
        } else {
          nextComment = userComments
        }
      } else {
        if (!!nextPart.userComments) {
          nextComment = nextPart.userComments
        } else {
          nextComment = ''
        }
      }

      nextPart.amount += amount
      nextPart.userComments = nextComment

      cart[indexMachine].items[indexPart] = nextPart
    } else {
      cart[indexMachine].items.push(...part.items)
    }

    return cart
  } else {
    return [...cart, part]
  }
}

export default prepareLocalCart
