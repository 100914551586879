import axios from 'axios'

let instance

const configure = (params) => {
  instance = axios.create({
    baseURL: params.baseURL || '',
    timeout: params.timeout || 0,
    headers: params.headers || [],
  })
}

export { configure, instance }
