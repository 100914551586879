import { user } from 'src/rest'
import { to } from 'src/lib'
import { userActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { errorsConstants } from 'src/constants'

const confirmEmail = (token) => async (dispatch) => {
  const { value, error } = await to(user.confirmEmail({ token }))

  if (value) {
    const { data } = value.data
    dispatch(userActions.confirmEmailSuccess())
    dispatch(notifyThunks.confirmEmailSuccess())

    return data.accessToken
  }

  if (error) {
    dispatch(userActions.confirmEmailFailure())

    const { data } = (error && error.response) || {}

    if (data && data.errorCode === errorsConstants.TOKEN_EXPIRED) {
      dispatch(notifyThunks.confirmEmailError())
    }

    if (data && data.errorCode === errorsConstants.DUPLICATE_EMAIL) {
      dispatch(notifyThunks.confirmEmailWarning())
    }

    return null
  }
}

export default confirmEmail
