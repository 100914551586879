import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.module.scss'

const BaseButtonRaw = (props) => {
  const { text, skin, size, disabled, isFull, isLink, ...btnProps } = props

  return React.createElement(
    isLink ? 'a' : 'button',
    {
      ...btnProps,
      disabled,
      className: cn(styles.btn, styles[skin], styles[size], {
        [styles.btnDisabled]: disabled,
        [styles.full]: isFull,
      }),
    },
    text,
  )
}

BaseButtonRaw.defaultProps = {
  skin: 'green',
  size: 'medium',
}

BaseButtonRaw.propTypes = {
  skin: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isFull: PropTypes.bool,
  onClick: PropTypes.func,
}

export const BaseButton = BaseButtonRaw
export default memo(BaseButton)
