import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import styles from './styles.module.scss'

const FooterRaw = () => {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.about}>
          <div className={styles.title}>{t('footer:about')}</div>
          <div className={styles.content}>
            <div className={styles.desc}>{t('footer:about_desc')}</div>
          </div>
        </div>
        <div className={styles.contact}>
          <div className={styles.title}>{t('footer:contact_us')}</div>

          <div className={styles.content}>
            <address className={styles.address}>
              Haver & Boecker Niagara <br />
              225 Ontario St. St. Catharines, ON, L2R 7B6
              <br />
              <a href="tel:+19056884733">+1-905-688-4733</a>
              <br />
              <a href="tel:+18003255993">+1-800-325-5993</a>
              <br />
              <a href="tel:+19056882644">+1-905-688-2644</a>
              <br />
              <NavLink className={styles.link} to="/contact-us">
                {t('footer:email_us')}
              </NavLink>
            </address>
          </div>
        </div>
        <div className={styles.rules}>
          <div className={styles.terms}>
            <NavLink className={styles.title} to="/terms-and-conditions">
              {t('footer:terms')}
            </NavLink>
          </div>
          <div className={styles.privacy}>
            <NavLink className={styles.title} to="/privacy-policy">
              {t('footer:privacy')}
            </NavLink>
          </div>
        </div>
      </div>

      <div className={styles.copy}>{t('footer:copy')}</div>
    </footer>
  )
}

FooterRaw.propTypes = {}

export const Footer = FooterRaw
export default memo(Footer)
