import { auth } from 'src/rest'
import { authActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { to } from 'src/lib'
import { prepareErrors } from 'src/services'

const authUser = (credentials) => async (dispatch) => {
  const { value, error } = await to(auth.auth(credentials))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      const { accessToken } = data
      dispatch(authActions.authSuccess())
      return accessToken
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(authActions.authFailure())
      return null
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })

    dispatch(notifyThunks.serverError(errorParams))
    dispatch(authActions.authFailure())

    return null
  }
}

export default authUser
