import { configureStore } from '@reduxjs/toolkit'

import reducer from './reducers'
import middleware from './middleware'

const store = configureStore({
  reducer,
  middleware,
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => {
    const newRootReducer = require('./reducers').default
    store.replaceReducer(newRootReducer)
  })
}

export default store
