import { handleActions, combineActions } from 'redux-actions'

import { cartActions } from 'src/actions'

export const initialState = 'initial'

const reducerMap = {
  [combineActions(cartActions.loadCartStart, cartActions.syncCartStart)]: (state, { payload }) => {
    const { isRefresh } = payload

    if (isRefresh && state !== initialState) {
      return 'updating'
    } else {
      return 'loading'
    }
  },
  [combineActions(cartActions.loadCartSuccess, cartActions.syncCartSuccess)]: () => {
    return 'success'
  },
  [combineActions(cartActions.loadCartFailure, cartActions.syncCartFailure)]: () => {
    return 'error'
  },
  [cartActions.resetCart]: () => {
    return initialState
  },
}

export default handleActions(reducerMap, initialState)
