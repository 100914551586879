import { handleActions } from 'redux-actions'

import { notifyActions } from 'src/actions'

export const initialState = []

const reducerMap = {
  [notifyActions.showNotify]: (state, { payload }) => {
    return [...state, payload]
  },
  [notifyActions.hideNotify]: (state, { payload }) => {
    return state.filter((item) => item.id !== payload.id)
  },
}

export default handleActions(reducerMap, initialState)
