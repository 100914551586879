import i18n from 'i18next'

import showNotify from './showNotify'

export const somethingError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:something_error.title'),
      desc: i18n.t('notify:something_error.desc'),
    }),
  )
}

export const createUserSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:create_user_success.title'),
      desc: i18n.t('notify:create_user_success.desc'),
    }),
  )
}

export const confirmUserSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:confirm_user_success.title'),
      desc: i18n.t('notify:confirm_user_success.desc'),
    }),
  )
}

export const confirmUserWarning = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'warning',
      title: i18n.t('notify:confirm_user_warning.title'),
      desc: i18n.t('notify:confirm_user_warning.desc'),
    }),
  )
}

export const confirmUserError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'warning',
      title: i18n.t('notify:confirm_user_error.title'),
      desc: i18n.t('notify:confirm_user_error.desc'),
    }),
  )
}
export const confirmEmailSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:confirm_email_success.title'),
      desc: i18n.t('notify:confirm_email_success.desc'),
    }),
  )
}

export const confirmEmailWarning = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'warning',
      title: i18n.t('notify:confirm_email_warning.title'),
      desc: i18n.t('notify:confirm_email_warning.desc'),
    }),
  )
}

export const confirmEmailError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'warning',
      title: i18n.t('notify:confirm_email_error.title'),
      desc: i18n.t('notify:confirm_email_error.desc'),
    }),
  )
}

export const authError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:auth_error.title'),
      desc: i18n.t('notify:auth_error.desc'),
    }),
  )
}

export const updateUserSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:update_user_success.title'),
      desc: i18n.t('notify:update_user_success.desc'),
    }),
  )
}

export const resetPasswordError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:reset_password_error.title'),
      desc: i18n.t('notify:reset_password_error.desc'),
    }),
  )
}

export const resetPasswordSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:reset_password_success.title'),
      desc: i18n.t('notify:reset_password_success.desc'),
    }),
  )
}

export const recoveryPassworError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:recovery_password_error.title'),
      desc: i18n.t('notify:recovery_password_error.desc'),
    }),
  )
}

export const recoveryPasswordSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:recovery_password_success.title'),
      desc: i18n.t('notify:recovery_password_success.desc'),
    }),
  )
}

export const changeEmailSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:change_email_success.title'),
      desc: i18n.t('notify:change_email_success.desc'),
    }),
  )
}

export const changeEmailWarning = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'warning',
      title: i18n.t('notify:change_email_warning.title'),
      desc: i18n.t('notify:change_email_warning.desc'),
    }),
  )
}

export const changePasswordSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:change_password_success.title'),
      desc: i18n.t('notify:change_password_success.desc'),
    }),
  )
}

export const unacceptedDocumentsWarning = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'warning',
      title: i18n.t('notify:.title'),
      desc: i18n.t('notify:unaccepted_documents.desc'),
    }),
  )
}

export const addedPartToCardSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:add_to_cart_success.title'),
      desc: i18n.t('notify:add_to_cart_success.desc'),
    }),
  )
}

export const deleteMachineSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:delete_machine_success.title'),
      desc: i18n.t('notify:delete_machine_success.desc'),
    }),
  )
}

export const deleteMachineInUse = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:delete_machine_in_use.title'),
      desc: i18n.t('notify:delete_machine_in_use.desc'),
    }),
  )
}

export const assignQuoteSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:assign_quote_success.title'),
      desc: i18n.t('notify:assign_quote_success.desc'),
    }),
  )
}

export const releaseRequestSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:release_request_success.title'),
      desc: i18n.t('notify:release_request_success.desc'),
    }),
  )
}

export const unreleaseRequestSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:unrelease_request_success.title'),
      desc: i18n.t('notify:unrelease_request_success.desc'),
    }),
  )
}

export const addedPartToRequestSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:add_to_request_success.title'),
      desc: i18n.t('notify:add_to_request_success.desc'),
    }),
  )
}

export const assignMachineSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:assign_machine_success.title'),
      desc: i18n.t('notify:assign_machine_success.desc'),
    }),
  )
}

export const uploadImageIsBig = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:upload_image_is_big.title'),
      desc: i18n.t('notify:upload_image_is_big.desc'),
    }),
  )
}

export const uploadQuoteIsBig = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:upload_quote_is_big.title'),
      desc: i18n.t('notify:upload_quote_is_big.desc'),
    }),
  )
}

export const adminDeleteSuccess = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'success',
      title: i18n.t('notify:delete_admin_success.title'),
      desc: i18n.t('notify:delete_admin_success.desc'),
    }),
  )
}

export const assignErrorQuote = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:assign_error_failure.title'),
      desc: i18n.t('notify:assign_error_failure.desc'),
    }),
  )
}

export const slowInternetError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:slow_internet_failure.title'),
      desc: i18n.t('notify:slow_internet_failure.desc'),
    }),
  )
}

export const sendContactUsError = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'error',
      title: i18n.t('notify:something_error.title'),
      desc: i18n.t('notify:something_error.desc'),
    }),
  )
}

export const emailWarning = () => (dispatch) => {
  dispatch(
    showNotify({
      type: 'warning',
      title: i18n.t('notify:email_warning.title'),
      desc: i18n.t('notify:email_warning.desc'),
    }),
  )
}


export const serverError = ({ type, title, desc }) => (dispatch) => {
  dispatch(showNotify({ type, title, desc }))
}
