import i18n from 'i18next'
import { regexpConstants } from 'src/constants'

export default (values) => {
  const errors = {}

  if (!values.currentPassword) {
    errors.currentPassword = i18n.t('errors:fields.required_field')
  } else if (!regexpConstants.PASSWORD.test(values.currentPassword)) {
    errors.currentPassword = i18n.t('errors:fields.weak_password')
  }

  if (!values.newPassword) {
    errors.newPassword = i18n.t('errors:fields.required_field')
  } else if (!regexpConstants.PASSWORD.test(values.newPassword)) {
    errors.newPassword = i18n.t('errors:fields.weak_password')
  } else if (values.newPassword === values.currentPassword) {
    errors.newPassword = i18n.t('errors:fields.old_password')
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = i18n.t('errors:fields.required_field')
  } else if (values.confirmPassword !== values.newPassword) {
    errors.confirmPassword = i18n.t('errors:fields.confirm_password')
  }

  return errors
}
