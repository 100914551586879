import i18next from 'i18next'

import { storageConstants } from 'src/constants'
import { settingsSelectors } from 'src/selectors'
import { storage } from 'src/lib'

const setLanguage = (lng) => async (dispatch, getState) => {
  const defaultLang = settingsSelectors.getDefaultLanguage(getState())
  const language = lng || (await storage.getItem(storageConstants.LAST_LANGUAGE)) || defaultLang

  await i18next.changeLanguage(language)
  storage.setItem(storageConstants.LAST_LANGUAGE, language)
}

export default setLanguage
