import { createAction } from 'redux-actions'

const prefix = 'PARTS:'

export const loadPartsSuccess = createAction(prefix + 'LOAD_PARTS_SUCCESS')
export const loadPartsFailure = createAction(prefix + 'LOAD_PARTS_FAILURE')

export const loadPartSuccess = createAction(prefix + 'LOAD_PART_SUCCESS')
export const loadPartFailure = createAction(prefix + 'LOAD_PART_FAILURE')

export const updatePartSuccess = createAction(prefix + 'UPDATE_PART_SUCCESS')
export const updatePartFailure = createAction(prefix + 'UPDATE_PART_FAILURE')
