import { createAction } from 'redux-actions'

const prefix = 'REQUESTS:'

export const loadRequestsSuccess = createAction(prefix + 'LOAD_REQUESTS_SUCCESS')
export const loadRequestsFailure = createAction(prefix + 'LOAD_REQUESTS_FAILURE')

export const loadRequestStart = createAction(prefix + 'LOAD_REQUEST_START')
export const loadRequestSuccess = createAction(prefix + 'LOAD_REQUEST_SUCCESS')
export const loadRequestFailure = createAction(prefix + 'LOAD_REQUEST_FAILURE')

export const setRequestsSort = createAction(prefix + 'SET_REQUESTS_SORT')
export const setRequestsSearch = createAction(prefix + 'SET_REQUESTS_SEARCH')

export const cleanRequests = createAction(prefix + 'CLEAN_REQUESTS')
export const cleanRequest = createAction(prefix + 'CLEAN_REQUEST')
