import React, { memo, useMemo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { preparePartsOptions } from 'src/services'
import FullLoader from 'src/components/FullLoader'
import Counter from 'src/components/Counter'
import BaseButton from 'src/components/BaseButton'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'
import SelectWithForm from 'src/forms/SelectWithForm'
import CounterWithForm from 'src/forms/CounterWithForm'
import TextareaWithForm from 'src/forms/TextareaWithForm'

import Modal from '../Modal'

import validate from './validate'
import { useMappedState, useMappedActions } from './bindings'
import styles from './styles.module.scss'

const AddUserMachineModalRaw = (props) => {
  const { onClose } = props
  const { t, i18n } = useTranslation()
  const { models, typesOptions, requestId } = useMappedState()
  const lang = i18n.language
  const actions = useMappedActions()

  const initialValues = useMemo(() => {
    const values = { amount: 1, machineType: typesOptions[0].value }

    return values
  }, [typesOptions])

  const onClickSubmit = useCallback(() => {
    document.getElementById('part-form').dispatchEvent(new Event('submit', { cancelable: true }))
  }, [])

  const onSubmit = useCallback(
    async (values) => {
      const { partId, amount, hbPartNumber, managerComments, machineType, serialNumber } = values

      const payload = {
        requestId,
        partId,
        amount,
        hbPartNumber,
        managerComments,
        machineType,
        serialNumber,
      }

      const errors = await actions.addPart({ values: payload })

      if (errors) {
        return errors
      }

      onClose(true)
    },
    [actions, onClose, requestId],
  )

  return (
    <Modal title={t(`common:titles.popup_add_machine_and_part`)} onClose={onClose} dialogStyles={styles.dialog}>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.form}>
            <Form
              initialValues={initialValues}
              validate={validate}
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, values }) => {
                const { machineComponent, machineType } = values
                const componentTypesOptions = models[machineType].componentTypes.map((component) => {
                  return {
                    value: component,
                    label: component,
                  }
                })
                const parts = models[machineType].parts
                const partsOptions = preparePartsOptions(parts, lang)

                return (
                  <form onSubmit={handleSubmit} id="part-form" noValidate>
                    {submitting && <FullLoader hasText />}
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.model') + '*'}</span>
                      <SelectWithForm
                        name="machineType"
                        options={typesOptions}
                        defaultOption={typesOptions[0] && typesOptions[0].value}
                      />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.serialNumber') + '*'}</span>
                      <InputWithForm name="serialNumber" isSimple />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.component') + '*'}</span>
                      <SelectWithForm
                        name="machineComponent"
                        options={componentTypesOptions}
                        placeholder={t('machine:select_component')}
                        isSimple
                      />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.id_name') + '*'}</span>
                      <SelectWithForm
                        name="partId"
                        options={partsOptions[machineComponent] || []}
                        placeholder={t('machine:select_id')}
                        isSimple
                        hasSearch
                      />
                    </InputRow>
                    <InputRow className={styles.inputRow} isLarge>
                      <span className={styles.label}>{t('machine:info.h&b')}</span>
                      <InputWithForm name="hbPartNumber" isSimple />
                    </InputRow>
                    <InputRow>
                      <span className={styles.label}>{t('machine:info.comment')}</span>
                      <TextareaWithForm name="managerComments" isSimple maxLength={255} />
                    </InputRow>
                    <InputRow>
                      <Counter>
                        <CounterWithForm name="amount" />
                      </Counter>
                    </InputRow>
                  </form>
                )
              }}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <BaseButton type="button" text={t('common:buttons.save')} onClick={onClickSubmit} />
        </div>
      </div>
    </Modal>
  )
}

export const AddUserMachineModal = AddUserMachineModalRaw
export default memo(AddUserMachineModal)
