import { storage } from 'src/lib'
import { storageConstants } from 'src/constants'

const getAllMachines = async () => {
  const data = await storage.getItem(storageConstants.USER_MACHINES)

  if (data) {
    return JSON.parse(data).sort((a, b) => b.time - a.time)
  }
}

export default getAllMachines
