import { combineReducers } from '@reduxjs/toolkit'

import parts from './parts'
import total from './total'
import number from './number'
import status from './status'
import state from './state'
import quotationFile from './quotationFile'
import isLoading from './isLoading'
import userInfo from './userInfo'
import requestId from './requestId'
import isReleaseLoading from './isReleaseLoading'
import userMachines from './userMachines'

const reducer = combineReducers({
  parts,
  total,
  number,
  status,
  state,
  quotationFile,
  isLoading,
  userInfo,
  requestId,
  isReleaseLoading,
  userMachines,
})

export default reducer
