import { combineReducers } from '@reduxjs/toolkit'

import user from './user'
import modal from './modal'
import notifications from './notifications'
import models from './models'
import machines from './machines'
import cart from './cart'
import request from './request'
import requests from './requests'
import typeahead from './typeahead'
import settings from './settings'

const reducer = combineReducers({
  user,
  modal,
  notifications,
  models,
  machines,
  cart,
  request,
  requests,
  typeahead,
  settings,
})

export default reducer
