import { storage } from 'src/lib'
import { storageConstants } from 'src/constants'
import { prepareLocalCart } from 'src/services'

const setPart = async (part, partId) => {
  const localCart = await storage.getItem(storageConstants.GUEST_CART)

  if (localCart) {
    await storage.setItem(
      storageConstants.GUEST_CART,
      JSON.stringify(prepareLocalCart(JSON.parse(localCart), part, partId)),
    )
  } else {
    await storage.setItem(storageConstants.GUEST_CART, JSON.stringify([part]))
  }
}

export default setPart
