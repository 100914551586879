import { createAction } from 'redux-actions'

const prefix = 'AUTH:'

export const createUserSuccess = createAction(prefix + 'CREATE_USER_SUCCESS')
export const createUserFailure = createAction(prefix + 'CREATE_USER_FAILURE')

export const confirmUserSuccess = createAction(prefix + 'CONFIRM_USER_SUCCESS')
export const confirmUserFailure = createAction(prefix + 'CONFIRM_USER_FAILURE')

export const loginStart = createAction(prefix + 'LOGIN_START')
export const loginSuccess = createAction(prefix + 'LOGIN_SUCCESS')
export const loginFailure = createAction(prefix + 'LOGIN_FAILURE')

export const logoutSuccess = createAction(prefix + 'LOGOUT_SUCCESS')

export const resetPasswordSuccess = createAction(prefix + 'RESET_PASSWORD_SUCCESS')
export const resetPasswordFailure = createAction(prefix + 'RESET_PASSWORD_FAILURE')

export const recoveryPasswordSuccess = createAction(prefix + 'RECOVERY_PASSWORD_SUCCESS')
export const recoveryPasswordFailure = createAction(prefix + 'RECOVERY_PASSWORD_FAILURE')

export const authSuccess = createAction(prefix + 'AUTH_SUCCESS')
export const authFailure = createAction(prefix + 'AUTH_FAILURE')
