import { handleActions, combineActions } from 'redux-actions'

import { cartActions, authActions } from 'src/actions'

export const initialState = null

const reducerMap = {
  [cartActions.cartSubmitSuccess]: (state, { payload }) => {
    return payload.requestNumber
  },
  [combineActions(cartActions.loadCartSuccess, authActions.logoutSuccess, cartActions.resetCart)]: () => initialState,
}

export default handleActions(reducerMap, initialState)
