import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { ReactComponent as UserIcon } from 'src/assets/images/icons/user.svg'

import styles from './styles.module.scss'

const ProfileRaw = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.profile}>
      <NavLink className={styles.btn} to="/profile">
        <div className={styles.inner}>
          <span className={styles.icon}>
            <UserIcon />
          </span>
          <span className={styles.text}>{t('profile:profile')}</span>
        </div>
      </NavLink>
    </div>
  )
}

export const Profile = ProfileRaw
export default memo(Profile)
