import React, { useLayoutEffect, useState, useRef, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ReactComponent as EyeIcon } from 'src/assets/images/icons/eye.svg'

import styles from './styles.module.scss'

const InputRaw = (props) => {
  const {
    label,
    style,
    id,
    name,
    disabled,
    type,
    maxLength,
    hasError,
    isSimple,
    onChange,
    onFocus,
    onBlur,
    value,
    ...inputProps
  } = props
  const [isFilled, setIsFilled] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isShowPass, setIsShowPass] = useState(false)
  const inputRef = useRef(null)
  const isPassword = type === 'password'

  const onInputChange = useCallback(
    (event) => {
      const value = event.target.value
      !isSimple && setIsFilled(Boolean(value.length))
      onChange && onChange(value)
    },
    [onChange, isSimple],
  )

  const onInputFocus = useCallback(() => {
    !isSimple && setIsFocused(true)
    onFocus && onFocus()
  }, [onFocus, isSimple])

  const onInputBlur = useCallback(() => {
    !isSimple && setIsFocused(false)
    onBlur && onBlur()
  }, [onBlur, isSimple])

  const onClickEye = useCallback(() => {
    setIsShowPass(!isShowPass)
  }, [isShowPass])

  useLayoutEffect(() => {
    if (!isSimple) {
      const input = inputRef.current
      const hasValue = Boolean(input.value.length)
      setIsFilled(hasValue)
    }
  }, [isSimple])

  return (
    <div
      className={cn(styles.inputWrap, {
        [styles.inputWrapSimple]: isSimple,
      })}
    >
      <input
        {...inputProps}
        ref={inputRef}
        value={value}
        disabled={disabled}
        name={name}
        type={isShowPass ? 'text' : type}
        id={id || name}
        maxLength={maxLength}
        onChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        className={cn(styles.input, style, {
          [styles.inputError]: hasError,
          [styles.inputSimple]: isSimple,
          [styles.inputPass]: isPassword,
        })}
      />
      {!isSimple && label && (
        <label
          className={cn(styles.label, {
            [styles.labelTop]: isFilled || isFocused,
            [styles.labelFocus]: isFocused,
            [styles.labelError]: hasError,
            [styles.labelDisabled]: disabled,
          })}
          htmlFor={id || name}
        >
          {label}
        </label>
      )}
      {isPassword && (
        <button
          type="button"
          className={cn(styles.eye, {
            [styles.eyeActive]: isShowPass,
            [styles.eyeSimple]: isSimple,
          })}
          onClick={onClickEye}
        >
          <EyeIcon />
        </button>
      )}
    </div>
  )
}

InputRaw.defaultProps = {
  value: '',
  type: 'text',
  hasError: false,
  isSimple: false,
  disabled: false,
  maxLength: 255,
}

InputRaw.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  isSimple: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.string,
}

const Input = InputRaw
export default memo(Input)
