import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Input from '../Input'

import styles from './styles.module.scss'

const InputWithFormRaw = ({ name, ...props }) => {
  return (
    <Field name={name} format={(value = '') => value && value.toString().trim()} formatOnBlur>
      {({ input, meta }) => {
        const hasError = (meta.error || meta.submitError) && meta.touched
        return (
          <div className={styles.field}>
            <Input {...input} {...props} hasError={hasError} />
            {hasError && <div className={styles.errorText}>{meta.error || meta.submitError}</div>}
          </div>
        )
      }}
    </Field>
  )
}

InputWithFormRaw.propTypes = {
  name: PropTypes.string.isRequired,
}

const InputWithForm = InputWithFormRaw
export default memo(InputWithForm)
