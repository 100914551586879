import axios from 'axios'

import { files } from 'src/rest'
import { to } from 'src/lib'
import { filesActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'

const uploadImage = (data) => async (dispatch) => {
  const { value, error } = await to(files.uploadImage(data))

  if (value) {
    const { isSuccess, data } = value.data

    if (isSuccess) {
      dispatch(filesActions.uploadImageSuccess())
      return data
    } else {
      dispatch(filesActions.uploadImageSuccess())
    }
  }

  if (error) {
    if (!axios.isCancel(error)) {
      dispatch(notifyThunks.slowInternetError())
    }

    dispatch(filesActions.uploadImageSuccess())
  }
}

export default uploadImage
