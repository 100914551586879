import { FORM_ERROR } from 'final-form'
import i18n from 'i18next'

import { cart } from 'src/rest'
import { to } from 'src/lib'
import { cartActions } from 'src/actions'
import { notifyThunks, machinesThunks, cartThunks } from 'src/thunks'
import { userSelectors } from 'src/selectors'
import { errorsConstants } from 'src/constants'
import { prepareErrors } from 'src/services'

import addPartLocal from './addPartLocal'

const addPart = ({ values, part, machineType }) => async (dispatch, getState) => {
  const isLogged = userSelectors.getIsLogged(getState())
  const { serialNumberNew, ...otherValues } = values
  const nextValues = { ...otherValues, partId: part.id }
  const isNew = !!serialNumberNew

  if (isNew) {
    nextValues.serialNumber = serialNumberNew
  }

  if (isLogged) {
    const { value, error } = await to(cart.addPart(nextValues))

    if (value) {
      const { isSuccess } = value.data

      if (isSuccess) {
        dispatch(cartActions.addPartSuccess())
        dispatch(notifyThunks.addedPartToCardSuccess())

        if (isNew) {
          dispatch(machinesThunks.loadAllMachines())
        }

        dispatch(cartThunks.loadUserCart(true))
      } else {
        const errorParams = prepareErrors({ data: value.data })

        dispatch(notifyThunks.serverError(errorParams))
        dispatch(cartActions.addPartFailure())
      }
    }

    if (error) {
      const errorParams = prepareErrors({ error })
      dispatch(cartActions.addPartFailure())

      if (errorParams.errorCode === errorsConstants.DUPLICATE_SERIAL_NUMBER) {
        return { serialNumber: i18n.t('errors:fields.duplicate_serial_number') }
      }

      return { [FORM_ERROR]: i18n.t('errors:server.common') }
    }
  } else {
    if (isNew) {
      const { serialNumber, designation } = nextValues

      const machineValue = {
        serialNumber,
        model: nextValues.machineType || machineType,
        designation,
      }

      const isSuccess = await dispatch(machinesThunks.createMachine(machineValue))

      if (isSuccess) {
        await dispatch(machinesThunks.loadAllMachines())
      }
    }

    dispatch(addPartLocal({ values: nextValues, part, machineType }))
  }
}

export default addPart
