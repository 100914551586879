import { useMapState, useMapActions } from 'src/hooks'

import { notifyActions } from 'src/actions'
import { notifySelectors } from 'src/selectors'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      notifications: notifySelectors.getNotifications(state),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    hideNotify: notifyActions.hideNotify,
  })
}
