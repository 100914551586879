import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import BaseButton from 'src/components/BaseButton'
import Typography from 'src/components/Typography'

import Modal from '../Modal'

import styles from './styles.module.scss'

const ConfirmModalRaw = (props) => {
  const { t } = useTranslation()
  const { onClose, title, description, primaryText, secondaryText } = props

  const onClosePopup = useCallback(() => {
    onClose({ isAccepted: false })
  }, [onClose])

  const onClickAccept = useCallback(() => {
    onClose({ isAccepted: true })
  }, [onClose])

  return (
    <Modal title={title} onClose={onClosePopup} dialogStyles={styles.dialog}>
      <div className={styles.container}>
        <div className={styles.body}>
          <Typography>{description}</Typography>
        </div>
        <div className={styles.footer}>
          <div>
            <BaseButton
              type="button"
              text={primaryText || t('common:buttons.cancel')}
              onClick={onClosePopup}
              skin="border"
            />
          </div>
          <div>
            <BaseButton type="button" text={secondaryText || t('common:buttons.yes')} onClick={onClickAccept} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

ConfirmModalRaw.propTypes = {
  onClose: PropTypes.func,
  type: PropTypes.string,
}

export const ConfirmModal = ConfirmModalRaw
export default memo(ConfirmModal)
