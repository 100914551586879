import { machinesActions } from 'src/actions'
import { getAllMachines } from 'src/storage'

const loadAllMachinesLocal = () => async (dispatch) => {
  const items = await getAllMachines()

  if (items) {
    dispatch(machinesActions.loadAllMachinesSuccess({ items, totalItems: items.length }))
  }
}

export default loadAllMachinesLocal
