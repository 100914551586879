import { instance } from 'src/rest'
import { storage } from 'src/lib'
import { authActions } from 'src/actions'
import { storageConstants } from 'src/constants'
import { machinesThunks, cartThunks } from 'src/thunks'

export default ({ dispatch }) => (next) => (action) => {
  switch (action.type) {
    case authActions.loginStart().type: {
      const accessToken = action.payload

      instance.defaults.headers['Authorization'] = 'Bearer ' + accessToken
      storage.setItem(storageConstants.ACCESS_TOKEN, accessToken)

      next(action)
      break
    }
    case authActions.loginSuccess().type: {
      next(action)
      dispatch(cartThunks.loadUserCart(true, true))
      dispatch(machinesThunks.loadAllMachines())

      break
    }

    default: {
      next(action)
    }
  }
}
