import { promisify } from 'src/lib'
import { modalActions } from 'src/actions'

const showModal = (type, props) => (dispatch) => {
  dispatch(modalActions.showModal({ type, props }))

  return promisify.wrap(type)
}

export default showModal
