import { machine } from 'src/rest'
import { to } from 'src/lib'
import { machinesActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { errorsConstants } from 'src/constants'

const delMachine = (id) => async (dispatch) => {
  const { value, error } = await to(machine.delMachine(id))

  if (value) {
    const { isSuccess } = value.data
    if (isSuccess) {
      dispatch(machinesActions.delMachineSuccess())
      dispatch(notifyThunks.deleteMachineSuccess())
    } else {
      dispatch(machinesActions.delMachineFailure())
    }
  }

  if (error) {
    dispatch(machinesActions.delMachineFailure())
    const { data } = (error && error.response) || {}

    if (data && data.errorCode === errorsConstants.USER_MACHINE_IS_IN_USE) {
      dispatch(notifyThunks.deleteMachineInUse())
    } else {
      dispatch(notifyThunks.somethingError())
    }
  }
}

export default delMachine
