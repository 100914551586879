import { combineReducers } from '@reduxjs/toolkit'

import info from './info'
import isLogged from './isLogged'
import isLoggingIn from './isLoggingIn'

const reducer = combineReducers({
  info,
  isLogged,
  isLoggingIn,
})

export default reducer
