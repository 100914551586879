import { FORM_ERROR } from 'final-form'
import i18n from 'i18next'
import _omit from 'lodash/omit'

import { user } from 'src/rest'
import { to } from 'src/lib'
import { userActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'

const changePassword = (values) => async (dispatch) => {
  const nextValues = _omit(values, ['confirmPassword'])
  const { value, error } = await to(user.changePassword(nextValues))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(userActions.changePasswordSuccess())
      dispatch(notifyThunks.changePasswordSuccess())
    } else {
      dispatch(userActions.changePasswordFailure())
    }
  }

  if (error) {
    dispatch(userActions.changePasswordFailure())
    dispatch(notifyThunks.somethingError())

    return { [FORM_ERROR]: i18n.t('errors:server.common') }
  }
}

export default changePassword
