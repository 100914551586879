import { typeahead } from 'src/rest'
import { to } from 'src/lib'
import { typeaheadActions } from 'src/actions'
import { prepareTapeahead } from 'src/services'

const loadCompanies = () => async (dispatch) => {
  const { value, error } = await to(typeahead.loadCompanies())

  if (value) {
    const { data } = value
    dispatch(typeaheadActions.loadCompaniesSuccess(prepareTapeahead(data)))
  }

  if (error) {
    dispatch(typeaheadActions.loadCompaniesFailure())
  }
}

export default loadCompanies
