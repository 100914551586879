import React, { useLayoutEffect, useState, useRef, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.module.scss'

const TextareaRaw = (props) => {
  const {
    value,
    label,
    style,
    id,
    name,
    disabled,
    maxLength,
    hasError,
    isSimple,
    onChange,
    onFocus,
    onBlur,
    ...textareaProps
  } = props
  const [isFilled, setIsFilled] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const textareaRef = useRef(null)

  const onTextareaChange = useCallback(
    (event) => {
      const value = event.target.value
      !isSimple && setIsFilled(Boolean(value.length))
      onChange && onChange(value)
    },
    [onChange, isSimple],
  )

  const onTextareaFocus = useCallback(() => {
    !isSimple && setIsFocused(true)
    onFocus && onFocus()
  }, [onFocus, isSimple])

  const onTextareaBlur = useCallback(() => {
    !isSimple && setIsFocused(false)
    onBlur && onBlur()
  }, [onBlur, isSimple])

  useLayoutEffect(() => {
    if (!isSimple) {
      const textarea = textareaRef.current
      const hasValue = Boolean(textarea.value.length)
      setIsFilled(hasValue)
    }
  }, [isSimple])

  return (
    <div
      className={cn(styles.textareaWrap, {
        [styles.textareaWrapSimple]: isSimple,
      })}
    >
      <textarea
        {...textareaProps}
        ref={textareaRef}
        disabled={disabled}
        value={value}
        name={name}
        id={id || name}
        maxLength={maxLength}
        onChange={onTextareaChange}
        onFocus={onTextareaFocus}
        onBlur={onTextareaBlur}
        className={cn(styles.textarea, style, {
          [styles.textareaError]: hasError,
          [styles.textareaSimple]: isSimple,
        })}
      />
      {!isSimple && label && (
        <label
          className={cn(styles.label, {
            [styles.labelTop]: isFilled || isFocused,
            [styles.labelFocus]: isFocused,
            [styles.labelError]: hasError,
            [styles.labelDisabled]: disabled,
          })}
          htmlFor={id || name}
        >
          {label}
        </label>
      )}
    </div>
  )
}

TextareaRaw.defaultProps = {
  hasError: false,
  isSimple: false,
  disabled: false,
  maxLength: 255,
  value: '',
}

TextareaRaw.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  isSimple: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.string,
}

const Textarea = TextareaRaw
export default memo(Textarea)
