import i18n from 'i18next'

const prepareParts = (items) => {
  return items.reduce((acc, curr) => {
    if (curr) {
      const { id, name, picture, internalNumber, machineComponent, machineModel, description } = curr
      const lang = i18n.language

      acc[id] = {
        image: picture,
        internalNumber,
        name: name[lang],
        class: machineModel,
        component: machineComponent,
        description: description[lang],
      }
    }

    return acc
  }, {})
}

export default prepareParts
