import { handleActions } from 'redux-actions'

import { modalActions } from 'src/actions'

export const initialState = null

const reducerMap = {
  [modalActions.showModal]: (state, { payload }) => payload.props || initialState,
  [modalActions.hideModal]: () => initialState,
}

export default handleActions(reducerMap, initialState)
