import { handleActions, combineActions } from 'redux-actions'

import { authActions } from 'src/actions'

export const initialState = false

const reducerMap = {
  [authActions.loginStart]: () => true,
  [combineActions(authActions.loginSuccess, authActions.loginFailure)]: () => false,
}

export default handleActions(reducerMap, initialState)
