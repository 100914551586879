export const NOTIFY_TIMEOUT = 8000

export const DATE_FOMAT = 'MMM dd, yyyy'

export const DATA_TIME_FORMAT = `${DATE_FOMAT} hh:mm a`

export const ADMIN = 'Admin'

export const TERMS_AND_CONDITIONS = 'TermsAndConditions'
export const PRIVACY_POLICY = 'PrivacyPolicy'

export const RULES_TYPE = {
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
}

export const STATUSES = {
  DEMANDED: 'Demanded',
  PROCESSED: 'Processed',
}
