import { handleActions } from 'redux-actions'

import { authActions, userActions } from 'src/actions'

export const initialState = {
  companyAddress: '',
  companyName: '',
  companyPhone: '',
  email: '',
  firstName: '',
  id: '',
  language: '',
  lastName: '',
  phone: '',
  role: '',
}

const reducerMap = {
  [authActions.loginSuccess]: (state, { payload }) => payload,
  [userActions.updateUserSuccess]: (state, { payload }) => {
    return { ...state, ...payload }
  },
  [authActions.logoutSuccess]: () => initialState,
}

export default handleActions(reducerMap, initialState)
