import { combineReducers } from '@reduxjs/toolkit'

import parts from './parts'
import total from './total'
import requestId from './requestId'
import requestNumber from './requestNumber'
import status from './status'

const reducer = combineReducers({
  parts,
  total,
  requestId,
  requestNumber,
  status,
})

export default reducer
