import { useMapState } from 'src/hooks'
import { requestSelectors } from 'src/selectors'

export const useMappedState = () => {
  return useMapState((state) => {
    return {
      isReleaseLoading: requestSelectors.getIsReleaseLoading(state),
    }
  })
}
