import { createSelector } from 'reselect'

const getState = (state) => state.models

export const getTypes = (state) => getState(state).types
export const getModels = (state) => getState(state).models
export const getComponents = (state) => getState(state).components
export const getAdminComponents = (state) => getState(state).adminComponents
export const getModelByType = (state, type) => getModels(state)[type]
export const getModelDescription = (state, type) => getModelByType(state, type).description
export const getModelPicture = (state, type) => getModelByType(state, type).picture
export const getModelType = (state, type) => getModelByType(state, type).type
export const getComponentTypes = (state, type) => getModelByType(state, type).componentTypes
export const getComponentImages = (state, type) => getModelByType(state, type).componentImages
export const getComponentsByType = (state, type) => getModelByType(state, type).components
export const getParts = (state, type) => getModelByType(state, type).parts
export const getImageByComponent = (state, type, component) => getComponentImages(state, type)[component]
export const getNameComponent = (state, type, component) => getComponentsByType(state, type)[component]

export const getPartsByComponent = (state, type, component) => {
  const parts = getParts(state, type)
  return parts.filter((part) => part.machineComponent === component)
}

export const getTypesOptions = createSelector(getTypes, (types) => {
  return types.map((type) => {
    return {
      value: type,
      label: type,
    }
  })
})

export const getComponentsOptions = createSelector(getComponents, (components) => {
  return components.map((component) => {
    return {
      value: component,
      label: component,
      id: component,
    }
  })
})

export const getComponentTypesOptions = (state, type) => {
  const types = getComponentTypes(state, type)
  return types.map((type) => {
    return {
      value: type,
      label: type,
      id: type,
    }
  })
}
