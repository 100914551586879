import { useMapState, useMapActions } from 'src/hooks'
import { machinesSelectors } from 'src/selectors'
import { cartThunks } from 'src/thunks'

export const useMappedState = (type) => {
  return useMapState((state) => {
    return {
      machines: machinesSelectors.getItemsByType(state, type),
    }
  })
}

export const useMappedActions = () => {
  return useMapActions({
    addPart: cartThunks.addPart,
  })
}
