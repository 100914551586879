import React, { memo, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ReactComponent as CloseIcon } from 'src/assets/images/icons/popup_close.svg'
import { useOnClickOutside } from 'src/hooks'

import styles from './styles.module.scss'

const ModalRaw = (props) => {
  const { children, title, isClosable = true, dialogStyles, onClose } = props
  const dialogRef = useRef(null)

  const listenKeyboard = useCallback(
    (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        if (isClosable) onClose()
      }
    },
    [onClose, isClosable],
  )

  const onOverlayClick = useCallback(() => {
    if (isClosable) onClose()
  }, [onClose, isClosable])

  useOnClickOutside(dialogRef, () => onOverlayClick())

  const onCrossClick = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    document.body.classList.add('modal')
    window.addEventListener('keydown', listenKeyboard, true)

    return () => {
      document.body.classList.remove('modal')
      window.removeEventListener('keydown', listenKeyboard, true)
    }
  }, [listenKeyboard])

  return (
    <div className={styles.modal}>
      <div className={styles.overlay} />
      <div className={styles.content}>
        <div className={cn(styles.dialog, dialogStyles)} ref={dialogRef}>
          <button className={styles.close} onClick={onCrossClick} type="button">
            <CloseIcon />
          </button>
          {title && <div className={styles.title}>{title}</div>}
          {children}
        </div>
      </div>
    </div>
  )
}

ModalRaw.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  dialogStyles: PropTypes.string,
}

export const Modal = ModalRaw
export default memo(Modal)
