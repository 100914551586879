import { commonConstants } from 'src/constants'
import { prepareDateToTz } from 'src/services'

const prepareMachines = (items) => {
  return items.reduce((acc, curr) => {
    if (curr) {
      const { model, serialNumber, designation, addedOn, serialNumberLatestUpdate } = curr

      const formatAddedOn = prepareDateToTz(new Date(addedOn), commonConstants.DATE_FOMAT)

      acc[curr.id] = {
        serialNumber: {
          serialNumber,
          serialNumberLatestUpdate,
        },
        model,
        designation,
        addedOn: formatAddedOn,
      }
    }

    return acc
  }, {})
}

export default prepareMachines
