import { handleActions, combineActions } from 'redux-actions'

import { cartActions, authActions } from 'src/actions'

export const initialState = 0

const reducerMap = {
  [cartActions.loadCartSuccess]: (state, { payload }) => payload.totalItems,
  [combineActions(cartActions.cartSubmitSuccess, authActions.logoutSuccess, cartActions.resetCart)]: () => initialState,
}

export default handleActions(reducerMap, initialState)
