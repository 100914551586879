import { handleActions } from 'redux-actions'

import { requestsActions } from 'src/actions'

export const initialState = []

const reducerMap = {
  [requestsActions.loadRequestsSuccess]: (state, { payload }) => payload.items,
  [requestsActions.cleanRequests]: () => initialState,
}

export default handleActions(reducerMap, initialState)
