import React, { memo, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import FormLayout from 'src/forms/FormLayout'
import InputWithForm from 'src/forms/InputWithForm'
import SelectWithForm from 'src/forms/SelectWithForm'
import InputRow from 'src/forms/InputRow'

import Modal from '../Modal'

import { useMappedState, useMappedActions } from './binding'
import validate from './validate'
import styles from './styles.module.scss'

const CUMachineModalRaw = (props) => {
  const { onClose, id, isDisabledModel, serialNumber, ...values } = props
  const { t } = useTranslation()
  const { typesOptions } = useMappedState()
  const actions = useMappedActions()
  // eslint-disable-next-line
  const oldSerialNumber = useMemo(() => serialNumber, [])

  if (serialNumber) {
    if (typeof serialNumber === 'string') {
      values.serialNumber = serialNumber
    } else {
      values.serialNumber = serialNumber.serialNumber
    }
  }

  const initialValues = { model: typesOptions[0].value, ...values }

  const title = useMemo(() => {
    const prefix = id ? 'popup_edit_machine' : 'popup_add_machine'
    return t(`common:titles.${prefix}`)
  }, [id, t])

  const onSubmit = useCallback(
    async (values) => {
      const { isSuccess, data, ...errors } = id
        ? await actions.editMachine(values, id, oldSerialNumber)
        : await actions.createMachine(values)

      if (!isEmpty(errors)) {
        return errors
      }

      onClose(data)
    },
    [actions, onClose, id, oldSerialNumber],
  )

  return (
    <Modal title={title} onClose={onClose} dialogStyles={styles.dialog}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.confirm')}
            >
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.model') + '*'}</span>
                {isDisabledModel ? (
                  <InputWithForm name="model" isSimple disabled />
                ) : (
                  <SelectWithForm
                    name="model"
                    options={typesOptions}
                    defaultOption={typesOptions[0] && typesOptions[0].value}
                    isSimple
                  />
                )}
              </InputRow>
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.serialNumber') + '*'}</span>
                <InputWithForm name="serialNumber" isSimple />
              </InputRow>
              <InputRow className={styles.inputRow}>
                <span className={styles.label}>{t('machine:info.designation')}</span>
                <InputWithForm name="designation" isSimple maxLength={40} />
              </InputRow>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

CUMachineModalRaw.propTypes = {
  onClose: PropTypes.func,
  model: PropTypes.string,
  serialNumber: PropTypes.string,
  designation: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export const CUMachineModal = CUMachineModalRaw
export default memo(CUMachineModal)
