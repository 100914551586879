import { cartActions } from 'src/actions'
import { gerLocalCart, removeLocalCart } from 'src/storage'
import { prepareMachineTables } from 'src/services'

const loadUserCartLocal = () => async (dispatch) => {
  const cart = await gerLocalCart()

  if (cart) {
    if (cart.length) {
      const localCart = prepareMachineTables(cart)
      dispatch(cartActions.loadCartSuccess(localCart))
    } else {
      dispatch(cartActions.resetCart())
      removeLocalCart()
    }
  } else {
    dispatch(cartActions.resetCart())
  }
}

export default loadUserCartLocal
