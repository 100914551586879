import { manager } from 'src/rest'
import { to } from 'src/lib'
import { managerActions } from 'src/actions'
import { notifyThunks } from 'src/thunks'
import { prepareErrors } from 'src/services'

const addPart = ({ values }) => async (dispatch, getState) => {
  const { value, error } = await to(manager.addPart(values))

  if (value) {
    const { isSuccess } = value.data

    if (isSuccess) {
      dispatch(managerActions.addPartSuccess())
      dispatch(notifyThunks.addedPartToRequestSuccess())
    } else {
      const errorParams = prepareErrors({ data: value.data })

      dispatch(notifyThunks.serverError(errorParams))
      dispatch(managerActions.addPartFailure())
    }
  }

  if (error) {
    const errorParams = prepareErrors({ error })
    dispatch(managerActions.addPartFailure())
    dispatch(notifyThunks.serverError(errorParams))
  }
}

export default addPart
