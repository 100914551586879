import { commonConstants } from 'src/constants'
import { prepareDateToTz } from 'src/services'

const makeMachine = (machines) => {
  return machines.reduce((acc, curr, idx) => {
    if (idx <= 1) {
      acc.push({
        model: curr.machineModel,
        serialNumber: curr.serialNumber,
      })
    }

    return acc
  }, [])
}

const prepareRequests = (items, isAdmin) => {
  return items.reduce((acc, curr) => {
    if (curr) {
      const { number, status, processedOn, submittedOn, updatedOn, companyName, id } = curr

      const formatSubmittedOn = prepareDateToTz(new Date(submittedOn), commonConstants.DATA_TIME_FORMAT)
      const formatUpdatedOn = updatedOn ? prepareDateToTz(new Date(updatedOn), commonConstants.DATA_TIME_FORMAT) : '-'
      const formatProcessedOn = processedOn
        ? prepareDateToTz(new Date(processedOn), commonConstants.DATA_TIME_FORMAT)
        : '-'

      if (isAdmin) {
        acc[id] = {
          number,
          machine: {
            machines: makeMachine(curr.machines),
            isMoreThanTwo: curr.machines.length >= 2,
          },
          companyName,
          submittedOn: formatSubmittedOn,
          updatedOn: formatUpdatedOn,
          status,
        }
      } else {
        acc[id] = {
          number,
          machine: {
            machines: makeMachine(curr.machines),
            isMoreThanTwo: curr.machines.length >= 2,
          },
          submittedOn: formatSubmittedOn,
          status,
          processedOn: formatProcessedOn,
        }
      }
    }

    return acc
  }, {})
}

export default prepareRequests
