const prepareCartSyncData = (cart) => {
  const initPayload = {
    requests: [],
    notFoundRequests: [],
  }

  return cart.reduce((result, machine) => {
    const { items, designation } = machine

    items.forEach((part) => {
      const {
        id,
        machineType,
        machineSerialNumber,
        amount,
        userComments,
        notFoundDetailMachineType,
        notFoundDetailComponent,
        isNotFound,
      } = part

      if (!isNotFound) {
        const info = {
          partId: id,
          machineType: machineType,
          serialNumber: machineSerialNumber,
          designation: designation,
          amount,
          userComments: userComments,
        }
        result.requests.push(info)
      } else {
        const info = {
          machineType: machineType || notFoundDetailMachineType,
          machineComponent: notFoundDetailComponent,
          serialNumber: machineSerialNumber,
          designation: designation,
          amount,
          description: userComments,
        }
        result.notFoundRequests.push(info)
      }
    })

    return result
  }, initPayload)
}

export default prepareCartSyncData
