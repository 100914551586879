import React, { memo, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FormLayout from 'src/forms/FormLayout'
import InputRow from 'src/forms/InputRow'
import InputWithForm from 'src/forms/InputWithForm'

import Modal from '../Modal'

import { useMappedActions } from './binding'
import validate from './validate'
import styles from './styles.module.scss'

const RecoveryPassModalRaw = (props) => {
  const actions = useMappedActions()
  const { t } = useTranslation()
  const { token, onClose } = props

  const onSubmit = useCallback(
    async (values) => {
      const { password } = values

      const data = {
        resetToken: token,
        password,
      }
      const isChanged = await actions.recoveryPassword(data)

      if (isChanged) {
        onClose()
      }
    },
    [actions, token, onClose],
  )

  return (
    <Modal title={t('common:titles.popup_recovery')} onClose={onClose} dialogStyles={styles.dialog} isClosable={false}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <FormLayout
              onSubmit={handleSubmit}
              submitting={submitting}
              pristine={pristine}
              btnText={t('common:buttons.save_changes')}
            >
              <InputRow className={styles.inputRow} isLarge>
                <span className={styles.label}>{t('profile:info.password') + '*'}</span>
                <InputWithForm isSimple type="password" name="password" />
              </InputRow>
              <InputRow className={styles.inputRow} isLarge>
                <span className={styles.label}>{t('profile:info.confirm_password') + '*'}</span>
                <InputWithForm isSimple type="password" name="confirmPassword" />
              </InputRow>
            </FormLayout>
          )
        }}
      />
    </Modal>
  )
}

RecoveryPassModalRaw.propTypes = {
  onClose: PropTypes.func,
  token: PropTypes.string,
}

export const RecoveryPassModal = RecoveryPassModalRaw
export default memo(RecoveryPassModal)
