import { instance } from '../axiosInstance'

export const createUser = (data) => {
  return instance({
    method: 'post',
    url: `/users`,
    data,
  })
}

export const confirmUser = (uuid) => {
  return instance({
    method: 'get',
    url: `/users/email/${uuid}`,
    data: {},
  })
}

export const updateUser = (data, uuid) => {
  return instance({
    method: 'get',
    url: `/users/${uuid}`,
    data,
  })
}

export const userInfo = () => {
  return instance({
    method: 'get',
    url: `/users/me`,
    data: {},
  })
}

export const auth = (data) => {
  return instance({
    method: 'post',
    url: `/auth`,
    data,
  })
}

export const resetPassword = (data) => {
  return instance({
    method: 'post',
    url: `/passwords/reset/request`,
    data,
  })
}

export const recoveryPassword = (data) => {
  return instance({
    method: 'post',
    url: `/passwords/reset`,
    data,
  })
}
