import { combineReducers } from '@reduxjs/toolkit'

import types from './types'
import models from './models'
import components from './components'
import adminComponents from './adminComponents'

const reducer = combineReducers({
  types,
  models,
  components,
  adminComponents,
})

export default reducer
