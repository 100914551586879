import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as LogoImg } from 'src/assets/images/logo.svg'

import styles from './styles.module.scss'

const LogoRaw = () => {
  return (
    <div className={styles.logo}>
      <NavLink to="/">
        <LogoImg />
      </NavLink>
    </div>
  )
}

export const Logo = LogoRaw
export default memo(Logo)
