import i18n from 'i18next'

import { machinesSelectors } from 'src/selectors'
import { machinesActions } from 'src/actions'
import { setMachine } from 'src/storage'
import { cartThunks } from 'src/thunks'

const editMachineLocal = (values, id, oldSerialNumber) => async (dispatch, getState) => {
  const state = getState()
  const machines = machinesSelectors.getItems(state)
  const filteredMachines = machines.filter((machine) => machine.id !== id)
  const editableMachine = machines.find((machine) => machine.id === id)
  const nextEditableValues = { ...editableMachine, ...values }
  const hasMachine = machines.find((machine) => {
    return (
      machine.serialNumber === nextEditableValues.serialNumber &&
      machine.model === nextEditableValues.model &&
      machine.id !== nextEditableValues.id
    )
  })

  if (hasMachine) {
    dispatch(machinesActions.editMachineFailure())
    return { serialNumber: i18n.t('errors:fields.duplicate_serial_number') }
  } else {
    const paramForLocalCart = {
      existingSerialNumber: nextEditableValues.serialNumber,
      machineType: nextEditableValues.model,
      designation: nextEditableValues.designation,
      oldSerialNumber,
    }
    await setMachine(filteredMachines, nextEditableValues)
    dispatch(machinesActions.editMachineSuccess())
    dispatch(cartThunks.updateCartLocal({ type: 'editSN', param: paramForLocalCart }))
    return { isSuccess: true, data: nextEditableValues }
  }
}

export default editMachineLocal
