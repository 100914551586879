import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Header from 'src/indyComponents/Header'
import Footer from 'src/components/Footer'

import { useMappedState } from './bindings'

import styles from './styles.module.scss'

const DefaultLayoutRaw = ({ children }) => {
  const { isReleaseLoading } = useMappedState()
  return (
    <Fragment>
      <Header />
      <div
        className={cn(styles.main, {
          [styles.mainLoader]: isReleaseLoading,
        })}
      >
        {children}
      </div>
      <Footer />
    </Fragment>
  )
}

DefaultLayoutRaw.prototype = {
  children: PropTypes.node,
}

export const DefaultLayout = DefaultLayoutRaw
export default memo(DefaultLayout)
