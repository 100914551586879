import React, { memo } from 'react'

import DefaultLayout from 'src/components/DefaultLayout'
import ScrollToTop from 'src/components/ScrollToTop'
import ModalManager from 'src/modal'
import NotifyManager from 'src/notify'
import Router from 'src/router'

import routes from 'src/routes'

const AppRaw = () => {
  return (
    <DefaultLayout>
      <ScrollToTop />
      <Router routes={routes} />
      <ModalManager />
      <NotifyManager />
    </DefaultLayout>
  )
}

export const App = AppRaw
export default memo(App)
