import axios from 'axios'

import { instance } from 'src/rest'
import { appActions } from 'src/actions'
import { errorsConstants } from 'src/constants'
import { authThunks } from 'src/thunks'
import { prepareErrors } from 'src/services'

const SESSION_EXPIRED_ERRORS = [
  errorsConstants.INVALID_ACCESS_TOKEN,
  errorsConstants.TOKEN_EXPIRED,
  errorsConstants.USER_NOT_FOUND,
]

export default ({ dispatch }) => (next) => (action) => {
  if (action && action.type === appActions.appInitStart().type) {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!axios.isCancel(error) && error.request && error.request.status === 401) {
          const errorParams = prepareErrors({ error })

          if (SESSION_EXPIRED_ERRORS.includes(errorParams.errorCode)) {
            dispatch(authThunks.logout())
          }

          if (errorParams.errorCode === errorsConstants.UNACCEPTED_DOCUMENTS) {
            return dispatch(authThunks.userCondition(errorParams, error.config))
          }
        }

        return Promise.reject(error)
      },
    )
  }

  next(action)
}
