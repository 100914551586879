import React, { memo, useCallback, useMemo, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ReactComponent as CheckIcon } from 'src/assets/images/icons/check.svg'

import InputRow from 'src/forms/InputRow'
import Input from 'src/forms/Input'

import { useMappedState } from './bindings'
import styles from './styles.module.scss'

const LangTabsRaw = (props) => {
  const { data, translation, filedField, onChangeTabData } = props
  const { languages } = useMappedState()
  const { t, i18n } = useTranslation()
  const currLang = i18n.language
  const [selectedLang, setSelectedLang] = useState(currLang)
  const tabsRef = useRef(null)

  const onWheel = useCallback((e) => {
    e.preventDefault()
    const tabs = tabsRef.current
    tabs.scrollTo({
      top: 0,
      left: tabs.scrollLeft + e.deltaY,
      behaviour: 'smooth',
    })
  }, [])

  const onClickTab = useCallback((value) => {
    setSelectedLang(value)
  }, [])

  const onChangeInput = useCallback(
    (field, value) => {
      onChangeTabData(field, selectedLang, value)
    },
    [onChangeTabData, selectedLang],
  )

  const renderHeader = useMemo(() => {
    return (
      <div className={styles.header} ref={tabsRef} data-scroll-x="true">
        {languages.map((lang) => {
          const isFiled = !!data[filedField][lang]

          return (
            <div
              key={lang}
              onClick={() => onClickTab(lang)}
              className={cn(styles.tab, {
                [styles.active]: lang === selectedLang,
              })}
            >
              <span className={styles.tabText}>{t(`common:languages.${lang}`)}</span>
              {isFiled && (
                <span className={styles.tabIcon}>
                  <CheckIcon />
                </span>
              )}
            </div>
          )
        })}
      </div>
    )
  }, [onClickTab, selectedLang, t, data, filedField, languages])

  useLayoutEffect(() => {
    const tabs = tabsRef.current

    tabs.addEventListener('wheel', onWheel, { passive: false })
    return () => tabs.removeEventListener('wheel', onWheel, { passive: false })
  }, [onWheel])

  return (
    <div className={styles.tabs}>
      {renderHeader}
      <div className={styles.content}>
        {Object.keys(data).map((field) => {
          return (
            <InputRow key={field}>
              <span className={styles.label}>{t(`${translation}:info.${field}`)}</span>
              <Input
                isSimple
                name={field}
                value={data[field][selectedLang]}
                onChange={(value) => onChangeInput(field, value)}
              />
            </InputRow>
          )
        })}
      </div>
    </div>
  )
}

export const LangTabs = LangTabsRaw
export default memo(LangTabs)
