const prepareModels = (data) => {
  return data.reduce(
    (accum, model) => {
      accum.models[model.type] = model
      accum.types.push(model.type)

      model.componentTypes.forEach((item) => {
        accum.adminComponents.push({
          class: model.type,
          name: model.components[item],
          component: item,
        })

        if (!accum.components.includes(item)) {
          accum.components.push(item)
        }
      })

      return accum
    },
    { types: [], models: {}, components: [], adminComponents: [] },
  )
}

export default prepareModels
