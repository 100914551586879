import { cartActions } from 'src/actions'
import { notifyThunks, cartThunks } from 'src/thunks'

import { modelsSelectors } from 'src/selectors'
import { setPart } from 'src/storage'

const initText = {
  de: '',
  en: '',
  fr: '',
  pt: '',
  ru: '',
  sp: '',
}

const getComponentOrder = (machineComponent) => {
  switch (machineComponent) {
    case 'Body':
    case 'Top Deck': {
      return 1
    }
    case 'Middle Deck':
    case 'Mounting': {
      return 2
    }
    case 'Bottom Deck':
    case 'Shaft': {
      return 3
    }

    default: {
      return 100
    }
  }
}

const getPartType = (machineComponent, serialNumber) => {
  if (!machineComponent || !serialNumber) {
    return 'NotFound'
  }

  return 'Regular'
}

const getGroupType = (machineType) => {
  if (!machineType) {
    return 'NotFound'
  }

  return 'Regular'
}

const addPartLocal = ({ values, part = {}, machineType, isNotFound = false }) => async (dispatch, getState) => {
  const {
    amount,
    userComments,
    partId,
    serialNumber,
    designation,
    description,
    machineComponent: machineComponentNotFound,
    machineType: machineTypeNotFound,
  } = values
  const { machineComponent: machineComponentPart, picture, description: partDescription, name, internalNumber } = part
  let machineComponent = null
  const machineComponentName = machineComponentPart || machineComponentNotFound

  if ((machineComponentNotFound && machineTypeNotFound) || machineComponentPart) {
    machineComponent = modelsSelectors.getNameComponent(getState(), machineTypeNotFound, machineComponentName)
  }

  const comments = userComments || description
  const id = isNotFound ? `local${Date.now()}` : partId

  const localCartPart = {
    machineType: machineType || machineTypeNotFound,
    serialNumber: serialNumber || '',
    designation,
    groupType: getGroupType(machineType),
    items: [
      {
        id,
        type: getPartType(machineComponent, serialNumber),
        amount,
        userComments: comments || '',
        machineType: machineType || machineTypeNotFound,
        machineSerialNumber: serialNumber || '',
        machinePartPicture: picture,
        machineComponent: machineComponent || initText,
        machinePartName: name || initText,
        machinePartDescription: partDescription || initText,
        isNotFound,
        seriallessMachineModel: machineType,
        machineComponentName: machineComponentName,
        machineComponentOrder: getComponentOrder(machineComponentName),
        notFoundDetailComponent: machineComponentName,
        notFoundDetailMachineType: machineType,
        machinePartInternalNumber: internalNumber,
      },
    ],
  }

  await setPart(localCartPart, id)

  dispatch(cartActions.addPartSuccess())
  dispatch(notifyThunks.addedPartToCardSuccess())
  dispatch(cartThunks.loadUserCart(true))
}

export default addPartLocal
