import { handleActions } from 'redux-actions'

import { typeaheadActions } from 'src/actions'

export const initialState = []

const reducerMap = {
  [typeaheadActions.loadCompaniesSuccess]: (state, { payload }) => {
    return payload
  },
  [typeaheadActions.loadCompaniesFailure]: () => initialState,
}

export default handleActions(reducerMap, initialState)
